import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { usePrivy } from '@privy-io/react-auth';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { useEffect, useState } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { logula } from '~src/utils/debug/consola';

export const useHiraethObservation = (
  chainId: SupportedBlockverseChainId<'hiraeth'>,
  txnHashes: Evm.TxnHash[],
  {
    observable = true,
  }: {
    observable?: boolean;
  },
) => {
  const { user } = usePrivy();
  const queryClient = useQueryClient();

  const observeMutation = trpc.hiraeth.observable.observe.useMutation({
    onSuccess: () => {
      logula.withTag('hiraeth.observation').info('observeMutation.onSuccess');
      queryClient.invalidateQueries({
        queryKey: [getQueryKey(trpc.hiraeth.observable.states)],
      });
    },
    onError: (error) => {
      logula.withTag('hiraeth.observation').error('observeMutation.onError', error);
    },
  });

  const [hasObserved, setHasObserved] = useState(false);
  useEffect(() => {
    if (user && observable && !hasObserved) {
      logula.withTag('hiraeth.observation').info('observing', { chainId, txnHashes });
      observeMutation.mutate({ chainId, txnHashes });
      setHasObserved(true);
    }
  }, [user, observable, hasObserved]);

  const hiraethStatesRes = trpc.hiraeth.observable.states.useQuery(
    { chainId, txnHashes },
    {
      select: (res) => {
        return Object.fromEntries(
          Object.entries(res.states).map(([txnHash, state]) => [
            txnHash,
            state,
          ]),
        );
      },
    },
  );

  useEffect(() => {
    logula.withTag('hiraeth.observation').info('state', hiraethStatesRes.data);
  }, [hiraethStatesRes.data]);

  return {
    hiraethStatesRes,
  };
};
