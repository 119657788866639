import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { useCallback } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';
import type { HashTxnState } from '~src/server/trpc/routers/hash/txn';
import type { QueryStateOptions } from '~src/types/query';

export const useHashOwnerAndTokenId = (
  txnHash: Evm.TxnHash | undefined,
  initialData: HashTxnState | undefined = undefined,
  queryOptions: QueryStateOptions = QUERY_LIVENESS['current-page'],
) => {
  const preferredChainId =
    usePreferredChainId<SupportedBlockverseChainId<'hash'>>();

  const res = trpc.hash.txn.mintStates.useQuery(
    {
      txnHashes: [txnHash!],
    },
    {
      ...queryOptions,
      enabled: !!preferredChainId && !!txnHash,
      select: useCallback(
        (data: { states: Record<Evm.TxnHash, HashTxnState | null> }) =>
          data.states[txnHash!],
        [txnHash],
      ),
      initialData: {
        states:
          !!txnHash && !!initialData
            ? {
                [txnHash]: initialData ?? null,
              }
            : {},
      },
    },
  );

  return res;
};
