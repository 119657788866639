import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { statefulColorsVariant } from '~src/theme/variants/stateful';

export const PillSpacer = styled('div', {
  base: {
    height: 'pillHeight',
  },
});

export const pillBaseCss = css.raw({
  height: 'pillHeight',
  width: 'fit-content',
  flx: 'flexCenter',
  paddingY: 'zero',
  paddingX: 'innerBox',
  position: 'relative',
  borderRadius: 'pill',
  fontSize: 'legacyLabel',
  fontFamily: 'display',
  fontWeight: 'display.bold',
});

export const Pill = styled('div', {
  base: css.raw(pillBaseCss, {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'gray.200',
    color: 'gray.600',
  }),
});

export const secondaryPillCss = css.raw(pillBaseCss, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderColor: 'gray.200',
  color: 'gray.600',
});
export const SecondaryPill = styled('div', {
  base: secondaryPillCss,
});

export const SecondarySpanPill = styled('span', {
  base: css.raw(secondaryPillCss, {
    display: 'inline',
    height: 'auto',
    whiteSpace: 'nowrap',
    paddingY: 'halfIncrement',
    paddingX: 'increment',
  }),
});

export const AnchorPill = styled('a', {
  base: css.raw(secondaryPillCss, {
    hoverOverlay: 'darken',
    textDecoration: 'none',
  }),
});

export const darkenPillCss = css.raw(pillBaseCss, {
  border: 'default',
  background: 'darken',
  color: 'gray.600',
  borderColor: 'gray.200',
});

export const DarkenPill = styled('div', {
  base: darkenPillCss,
});

export const BrandPill = styled('div', {
  base: css.raw(pillBaseCss, {
    background: 'brand',
    color: 'offWhite',
  }),
});

export const StatefulPill = styled('div', {
  base: pillBaseCss,
  variants: {
    isLoading: {
      true: {
        position: 'relative',
        overflow: 'hidden',
        _after: {
          content: '""',
          abs: 'cover',
          animationDuration: '[1s]',
          animationIterationCount: '[infinite]',
          background: 'darken.075',
          animationName: 'breathe', //for some reason lightlyBreath is picked up
        },
      },
      false: {},
    },
    state: statefulColorsVariant,
  },
});

export const NumberPill = styled('div', {
  base: {
    height: 'pillHeight',
    width: 'pillHeight',
    aspectRatio: '1:1',
    background: 'gray.200',
    color: 'gray.600',
    borderRadius: 'pill',
    fontSize: 'button',
    fontFamily: 'display',
  },
});
