import { NULL_ADDRESS } from '@pob/shared';
import { useMemo } from 'react';
import { useIsMounted } from 'usehooks-ts';
import { trpc } from '~src/clients/trpc-client';
import { isSupportedChainForProjectForCheckout } from '~src/config/utils/isSupportedChainForProjectForCheckout';
import { QUERY_LIVENESS } from '~src/constants/query';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { serializeBlockverseObjId } from '~src/shared-apps/blockverse/utils/serialize';
import { useBatchMostUpToDateCheckoutItemStateMap } from '~src/shared-apps/checkout/hooks/useBatchMostUpToDateCheckoutItemState';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import type { QueryStateOptions } from '~src/types/query';

export const useBatchHiraethItemCheckoutState = (
  objIds?: BlockverseObjId<'hiraeth'>[],
  queryOptions: QueryStateOptions = QUERY_LIVENESS['current-page'],
): Record<string, CheckoutItemStateProps> => {
  const hiraethStatesRes = trpc.hiraeth.txn.mintStates.useQuery(
    {
      blockverseObjIds: objIds!,
    },
    {
      ...queryOptions,
      enabled: !!objIds && !!objIds.length,
      select: (data) => data.states,
    },
  );

  const isMounted = useIsMounted();

  const checkoutItemStateMap = useMemo((): Record<
    string,
    CheckoutItemStateProps
  > => {
    if (!objIds) {
      return {};
    }
    const map: Record<string, CheckoutItemStateProps> = {};
    for (const objId of objIds) {
      const serializedObjId = serializeBlockverseObjId(objId);
      const preferredChainId = objId[1];
      if (!isSupportedChainForProjectForCheckout('hiraeth', preferredChainId)) {
        map[serializedObjId] = { type: 'coming-soon' };
        continue;
      }
      if (
        !isMounted ||
        hiraethStatesRes.isPending ||
        !hiraethStatesRes.data ||
        !hiraethStatesRes.data[serializedObjId]
      ) {
        map[serializedObjId] = { type: 'loading' };
        continue;
      }
      const hiraethState = hiraethStatesRes.data[serializedObjId];
      if (!!hiraethState.tokenId) {
        map[serializedObjId] = {
          type: 'minted',
          tokenId: hiraethState.tokenId,
          owner: hiraethState.owner,
        };
        continue;
      }
      if (hiraethState.isClaimableFromHash) {
        map[serializedObjId] = {
          type: 'claim',
          claimantAddress: NULL_ADDRESS, // hiraethState.hashLegacyOwner,
        };
        continue;
      }
      map[serializedObjId] = {
        type: 'mintable',
      };
    }
    return map;
  }, [isMounted, hiraethStatesRes.data]);

  return useBatchMostUpToDateCheckoutItemStateMap(
    objIds ?? [],
    useMemo(
      () => checkoutItemStateMap as Record<string, CheckoutItemStateProps>,
      [checkoutItemStateMap],
    ),
  );
};
