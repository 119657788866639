// import { NeynarAuthButton, useNeynarContext } from '@neynar/react';
// import { FC } from 'react';
// import { DropdownContentActionButton, DropdownContentActionButtonWithIcon, DropdownLineSeparator, LayeredDropdownContentBox } from '../dropdown';
// import { useFarcasterAuthenticationContext } from '../../../providers/FarcasterAuthentication';
// import { InheritSpan } from '../../text';
// import { FarcasterIcon } from '../../icon/farcaster';
// import { match } from 'ts-pattern';
// import { ButtonRow } from '../../button';
// import { FarcasterProfilePicture } from '../../../apps/social/components/profile/farcaster';
// import { useBestFarcasterHandle } from '../../../hooks/farcaster/useBestFarcasterHandle';

import { Settings } from 'lucide-react';
import type { FC } from 'react';
import { match } from 'ts-pattern';
import { MinorButton } from '~src/components/button';
import {
  DeepDropdownItemGroup,
  DropdownItemButton,
  DropdownItemGroup,
} from '~src/components/dropdown';
import { FarcasterIcon } from '~src/components/icon/farcaster';
import { SimpleImg } from '~src/components/img';
import { Popover } from '~src/components/popover';
import { P } from '~src/components/text';
import { InheritSpan } from '~src/components/text/spans';
import { useBestExtAccountTypeFromPrivy } from '~src/hooks/privy/useBestExtAccountTypeFromPrivy';
import { usePrivyDialogs } from '~src/shared-apps/social/providers/PrivyDialogsProvider';
import { css } from '~src/styled-system/css';
import { Circle, Flex, styled } from '~src/styled-system/jsx';
import { useFarcasterUserFromPrivy } from '../../../../hooks/privy/useFarcasterUserFromPrivy';
import { useHeader } from '../../providers/HeaderProvider';

export const FarcasterConnectedPopoverTrigger: FC = () => {
  const farcasterUser = useFarcasterUserFromPrivy();
  return (
    <Popover.Trigger asChild>
      <MinorButton
        bg={'gray.100'}
        height={'navIsland'}
        px={'halfIncrement'}
        py={'halfIncrement'}
        flx={'flexRow'}
        position={'relative'}
      >
        <Circle size={'[32px]'} pos={'relative'} overflow={'hidden'}>
          <SimpleImg src={farcasterUser!.pfp ?? ''} />
        </Circle>
      </MinorButton>
    </Popover.Trigger>
  );
};

export const FarcasterDropdownHeaderState: FC = () => {
  const farcasterUser = useFarcasterUserFromPrivy();
  const isAuthenticated = farcasterUser !== undefined;
  return match(isAuthenticated)
    .with(false, () => null)
    .otherwise(() => <FarcasterConnectedHeaderState />);
};

const FarcasterConnectedHeaderState: FC = () => {
  const farcasterUser = useFarcasterUserFromPrivy();

  if (!farcasterUser) {
    return null;
  }
  return (
    <DeepDropdownItemGroup>
      <Flex gap={'increment'} align={'center'}>
        <Circle
          size={'pfp'}
          bg={'gray.100'}
          flx={'flexCenter'}
          pos={'relative'}
          overflow={'hidden'}
        >
          <SimpleImg src={farcasterUser.pfp ?? ''} />
        </Circle>
        <styled.div flexGrow={1}>
          <P
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            color={'gray.600'}
            textStyle={'p.display.caption'}
          >
            {farcasterUser.displayName}
          </P>
          <P
            mt={'quarterIncrement'}
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            color={'gray.400'}
            textStyle={'p.display.caption'}
            lineHeight={'[1.1]'}
          >
            @{farcasterUser.username}
          </P>
        </styled.div>
      </Flex>
    </DeepDropdownItemGroup>
  );
};

export const FarcasterDropdownState: FC = () => {
  const farcasterUser = useFarcasterUserFromPrivy();
  const isAuthenticated = farcasterUser !== undefined;
  return match(isAuthenticated)
    .with(true, () => null)
    .with(false, () => <FarcasterLogInState />)
    .exhaustive();
};

const FarcasterLogInState: FC = () => {
  const { open } = usePrivyDialogs();
  const { close: closeHeader } = useHeader();
  const bestUser = useBestExtAccountTypeFromPrivy();
  if (bestUser === 'universal-profile') {
    return null;
  }
  return (
    <DropdownItemGroup bg={'white'}>
      <DropdownItemButton
        flx={'flexRowEnds'}
        onClick={() => {
          open();
          closeHeader();
        }}
      >
        <InheritSpan>Link Farcaster</InheritSpan>
        <FarcasterIcon
          className={css({
            width: 'icon.fourteen',
            height: 'icon.fourteen',
            display: 'block',
          })}
        />
      </DropdownItemButton>
    </DropdownItemGroup>
  );
};

const FarcasterAuthenticatedState: FC = () => {
  const { open } = usePrivyDialogs();
  const { close: closeHeader } = useHeader();
  return (
    <DropdownItemGroup bg={'white'}>
      <DropdownItemButton
        flx={'flexRowEnds'}
        onClick={() => {
          open();
          closeHeader();
        }}
      >
        <InheritSpan>Settings</InheritSpan>
        <Settings
          className={css({
            width: 'icon.fourteen',
            height: 'icon.fourteen',
            display: 'block',
          })}
        />
      </DropdownItemButton>
    </DropdownItemGroup>
  );
};

// const FarcasterAuthenticatedState: FC = () => {
//   const { user, logoutUser } = useNeynarContext();
//   const handle = useBestFarcasterHandle(user!);
//   return     <>
//   <DropdownContentActionButtonWithIcon
//     // href={profileUrl ?? '#'}
//     target={'_blank'}
//     as="a"
//   >
//     <ButtonRow
//       $paddingType="increment"
//       style={{ fontFamily: 'inherit', fontSize: 'inherit' }}
//     >
//       <FarcasterProfilePicture profile={user!} size={22} />
//       <InheritSpan style={{ fontWeight: 700 }}>{handle}</InheritSpan>
//     </ButtonRow>
//     <FarcasterIconForDropdown />
//   </DropdownContentActionButtonWithIcon>
//   <DropdownLineSeparator $color={'darken'} />
//   {/* <DropdownContentActionButton onClick={() => openModal('switch-profile')}>
//     Switch Profile
//   </DropdownContentActionButton> */}
//   <DropdownContentActionButton onClick={logoutUser} >
//     Log Out
//   </DropdownContentActionButton>
// </>
// }
