import { AvailableChainId } from '@protocol/chains';
import { useSupportedByMapChainIdFactory } from '~src/hooks/network/useSupportedNetwork';
import { usePreferredChain } from '~src/providers/PreferredNetwork';
import {
  PROJECT_SUPPORTED_CART_CHAINS,
  SupportedCartProjectId,
  type SupportedCartChainId,
} from '../../../config/cart';

const useSupported = useSupportedByMapChainIdFactory(
  PROJECT_SUPPORTED_CART_CHAINS,
);

export const useSupportedCartChainId = <
  CartProject extends SupportedCartProjectId,
>(
  project?: CartProject,
  chainId?: AvailableChainId,
) => {
  return useSupported(project, chainId) as SupportedCartChainId<CartProject>;
};

export const usePreferredChainIdIfCartSupported = <
  E extends SupportedCartProjectId,
>(
  project: E,
) => {
  const preferredChain = usePreferredChain();
  return useSupportedCartChainId(project, preferredChain.id);
};
