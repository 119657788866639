import { FC } from 'react';

export const LeftArrowIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5304 5.28301C11.9264 5.66799 11.9353 6.30109 11.5503 6.69708L7.36693 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H7.36693L11.5503 17.3029C11.9353 17.6989 11.9264 18.332 11.5304 18.717C11.1344 19.102 10.5013 19.0931 10.1163 18.6971L4.283 12.6971C3.90567 12.309 3.90567 11.691 4.283 11.3029L10.1163 5.30292C10.5013 4.90694 11.1344 4.89802 11.5304 5.28301Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const UpRightArrowIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45805 7.08082C7.45804 6.52853 7.90575 6.08081 8.45803 6.0808L16.98 6.08068C17.2452 6.08068 17.4996 6.18603 17.6871 6.37357C17.8747 6.56111 17.98 6.81546 17.98 7.08068V15.4815C17.98 16.0338 17.5323 16.4815 16.98 16.4815C16.4278 16.4815 15.98 16.0338 15.98 15.4815L15.98 9.49489L7.78765 17.6873C7.39713 18.0778 6.76396 18.0778 6.37344 17.6873C5.98291 17.2968 5.98291 16.6636 6.37344 16.2731L14.5658 8.08072L8.45806 8.0808C7.90577 8.08081 7.45805 7.6331 7.45805 7.08082Z"
        fill="currentColor"
      />
    </svg>
  );
};
