import { useMemo } from 'react';
import { getAddress } from 'viem/utils';
import { useAccount, useEnsName } from 'wagmi';
import { some } from '../../utils/lodash';

const POB_STUDIO_WALLETS = ['0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4'];

const SUBDOMAINS = ['pob.eth', 'pobstudio.eth'];

export const usePobWallet = () => {
  const { address } = useAccount();
  const name = useEnsName({
    address,
    chainId: 1,
  });
  return useMemo(() => {
    if (!address) {
      return false;
    }

    if (
      !!name.data &&
      some(SUBDOMAINS, (subdomain) => name.data!.includes(subdomain))
    ) {
      return true;
    }

    if (POB_STUDIO_WALLETS.includes(getAddress(address))) {
      return true;
    }
    return false;
  }, [address, name]);
};
