import { TooltipTrigger } from '@ark-ui/react';
import { Flame } from 'lucide-react';
import { useRouter } from 'next/router';
import { FC, useCallback, useMemo } from 'react';
import { baseButtonCss, minorButtonCss } from '~src/components/button';
import { GlobeSearchIcon } from '~src/components/icon/search';
import { HighlightSpan, InheritSpan } from '~src/components/text/spans';
import {
  Tooltip,
  TooltipBox,
  TooltipP,
  tooltipTimingProps,
} from '~src/components/tooltip';
import { css } from '~src/styled-system/css';
import { Flex, styled } from '~src/styled-system/jsx';
import { useMounted } from '../../../../hooks/ui/useMounted';
import { getIsMac } from '../../../../utils/misc/os';
import { useCmdContext } from '../../../cmd/providers/Cmd';
import { useLucky } from '../../hooks/useLucky';

export const HeaderSearchStatus: FC = ({}) => {
  // const [searchTextIndex, setSearchTextIndex] = useState(0);

  // useInterval(() => {
  //   setSearchTextIndex((t) => (t + 1) % SEARCH_TEXT.length);
  // }, 5000);

  const { open, isOpen, close } = useCmdContext();

  const getLuckyHref = useLucky();
  const router = useRouter();

  const handleShuffle = useCallback(() => {
    router.push(getLuckyHref() as any);
  }, [getLuckyHref, router]);

  const mounted = useMounted();
  const isMac = useMemo(() => mounted && getIsMac(), [mounted]);
  return (
    <Flex gap={'increment'}>
      <SearchButtonsContainer>
        <SearchButton onClick={() => (isOpen ? close() : open())}>
          <GlobeSearchIcon
            className={css({
              width: 'icon.eighteen',
              height: 'icon.eighteen',
              display: 'block',
            })}
          />
          <InheritSpan hideBelow={'sm'}>
            Type <HighlightSpan>{isMac ? '⌘' : 'ctrl'}K</HighlightSpan> to
            search
          </InheritSpan>
        </SearchButton>
        <Tooltip.Root
          positioning={{
            placement: 'bottom',
          }}
          {...tooltipTimingProps.default}
        >
          <TooltipTrigger asChild>
            <LuckyButton onClick={handleShuffle} hideBelow={'sm'}>
              <Flame size={16} strokeWidth={2.5} />
            </LuckyButton>
          </TooltipTrigger>
          <Tooltip.Positioner>
            <Tooltip.Content>
              <TooltipBox>
                <TooltipP>I'm feeling lucky</TooltipP>
              </TooltipBox>
            </Tooltip.Content>
          </Tooltip.Positioner>
        </Tooltip.Root>
      </SearchButtonsContainer>
    </Flex>
  );
};

const SearchButtonsContainer = styled('div', {
  base: {
    flx: 'flexRow',
    border: '1px solid',
    borderColor: 'gray.200',
    alignItems: 'stretch',
    rounded: 'squaredButton',
    gap: 'halfIncrement',
    padding: 'halfIncrement',
    bg: 'darken.025',
    height: 'navIsland',
  },
});

const searchButtonBase = css.raw(minorButtonCss, {
  hoverOverlay: 'darken',
  py: 'zero',
});

const LuckyButton = styled('button', {
  base: css.raw(baseButtonCss, {
    rounded: 'squaredButton',
    flx: 'flexCenter',
    p: 'zero',
    width: `[calc({sizes.navIsland} - {spacing.halfIncrement} * 2 - 2px)]`,
    bg: 'gray.200',
    color: 'gray.600',
    tran: 'default',
    // transitionProperty: 'color',
    // _hover: {
    //   color: 'gray.600',
    // },
  }),
});

const SearchButton = styled('button', {
  base: css.raw(searchButtonBase, {
    flx: 'flexRow',
    gap: 'increment',
    fontWeight: 'display.normal',
    color: 'gray.600',
    rounded: 'innerOfSquaredButton',
    pl: {
      base: 'innerBox',
      sm: 'increment',
    },
    pr: {
      base: 'innerBox',
      sm: 'box',
    },
  }),
});

// const SearchStatusLabel = styled(DisplayMedP)`
//   color: ${({ theme }) => theme.gray.400};
//   font-size: 12px;
//   transform: translateY(1px);
//   font-weight: 500;
//   transition: ${DEFAULT_TRANSITION_STYLES('color')};
//   height: 12px;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     display: none;
//   }
// `;

// const SearchStatusButton = styled(BaseButton)`
//   position: relative;
//   height: 38px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   overflow: hidden;
//   gap: ${({ theme }) => theme.padding.increment}px;
//   padding: 0 ${({ theme }) => theme.padding.box}px;
//   padding-right: ${({ theme }) => theme.padding.innerBox + 2}px;
//   border-radius: ${({ theme }) => theme.pillBorderRadius}px;
//   /* background-color: ${({ theme }) => theme.white}; */
//   ${SOFT_HOVER_STYLES}
//   > svg {
//     * {
//       transition: ${DEFAULT_TRANSITION_STYLES('fill')};
//       fill: ${({ theme }) => theme.gray.400};
//     }
//     width: 18px;
//     height: 18px;
//   }
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     width: 38px;
//     padding: 0;
//   }
//   &:hover {
//     > svg {
//       * {
//         fill: ${({ theme }) => theme.gray.600};
//         /* stroke: ${({ theme }) => theme.black}; */
//       }
//     }
//     ${SearchStatusLabel} {
//       color: ${({ theme }) => theme.gray.600};
//     }
//   }
// `;

// const SearchStatusIconButton = styled(SearchStatusButton)`
//   padding: 0;
//   width: 38px;
// `;
