import { css } from '~src/styled-system/css';

export type StatefulColorType = 'neutral' | 'warning' | 'success' | 'error';

export const statefulColorsVariant = {
  neutral: css.raw({
    background: 'gray.100',
    color: 'gray.600',
  }),
  warning: css.raw({
    background: 'warning',
    color: 'warning.strong',
  }),
  success: css.raw({
    background: 'success',
    color: 'success.strong',
  }),
  error: css.raw({
    background: 'error',
    color: 'error.strong',
  }),
} as const satisfies Record<StatefulColorType, any>;

export const statefulStrongBackgroundVariant = {
  neutral: css.raw({
    background: 'gray.600',
  }),
  warning: css.raw({
    background: 'warning.strong',
  }),
  success: css.raw({
    background: 'success.strong',
  }),
  error: css.raw({
    background: 'error.strong',
  }),
} as const satisfies Record<StatefulColorType, any>;

export const statefulElementVariant = {
  neutral: css.raw({
    background: 'gray.200',
    color: 'gray.700',
  }),
  warning: css.raw({
    background: 'warning.background',
    color: 'warning.strong',
  }),
  success: css.raw({
    background: 'success.background',
    color: 'success.strong',
  }),
  error: css.raw({
    background: 'error.background',
    color: 'error.strong',
  }),
} as const satisfies Record<StatefulColorType, any>;
