import type { ExtAccount } from '@pob/shared';
import { match } from 'ts-pattern';
import { resolveURL } from 'ufo';
import { EFP_URL } from '~src/constants';

export const getSocialGraphUrl = (extAccount: ExtAccount) => {
  return match(extAccount)
    .with({ userType: 'wallet' }, (e) => resolveURL(EFP_URL, e.address))
    .with({ userType: 'farcaster' }, () => null)
    .with({ userType: 'universal-profile' }, () => null)
    .exhaustive();
};
