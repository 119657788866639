import {
  NULL_BYTES,
  type ChainRuntimeTag,
  type Evm,
  type HexString,
  type HexStringWithFixedSize,
} from '@pob/shared';
import type { Tagged, ValueOf } from 'type-fest';
import {
  Account,
  TypedDataDomain,
  WalletClient,
  concatHex,
  hashTypedData,
  pad,
  toHex,
} from 'viem';

export const NO_SIGNATURES_NEEDED_SALT = NULL_BYTES;

export enum SetMetadataType {
  SStore = 0,
  SStore2 = 1,
}

export interface SetMetadata {
  metadataType: SetMetadataType;
  key: MetadataKey;
  salt: Evm.Word;
  author: Evm.Address;
  value: HexString;
}

export type MetadataKey<H extends HexString = HexString> = ChainRuntimeTag<
  Tagged<HexStringWithFixedSize<31, H>, 'metadata-key'>,
  'evm'
>;

export const getSetMetadataKeyFromString = (key: string) => {
  return pad(toHex(key), {
    dir: 'right',
    size: 31,
  }) as MetadataKey;
};

export const SET_METADATA_KEYS = {
  description: getSetMetadataKeyFromString('description'),
  name: getSetMetadataKeyFromString('name'),
} as const;

export const SET_METADATA_KEYS_REVERSE_MAP = Object.fromEntries(
  Object.entries(SET_METADATA_KEYS).map(([key, value]) => [value, key]),
) as Record<ValueOf<typeof SET_METADATA_KEYS>, keyof typeof SET_METADATA_KEYS>;

export const SET_METADATA_SIGNING_NAME = 'Hiraeth Metadata';
export const SET_METADATA_SIGNING_VERSION = '1';
export const SET_METADATA_SIGNING_VERSION_1_1 = '1.1';

export const SET_METADATA_TYPED_DATA_DEFAULTS = {
  types: {
    SetMetadata: [
      { name: 'txnHash', type: 'bytes32' },
      { name: 'metadataKey', type: 'bytes32' },
      { name: 'salt', type: 'bytes32' },
      { name: 'author', type: 'address' },
      { name: 'value', type: 'bytes' },
    ],
  },
  primaryType: 'SetMetadata',
} as const;

export interface SignedSetMetadata extends SetMetadata {
  signature: Evm.Signature;
}

export const getMetadataKey = (
  metadataType: SetMetadataType,
  key: MetadataKey,
) => {
  return concatHex([
    pad(key, { dir: 'right', size: 31 }),
    pad(toHex(metadataType), { size: 1 }),
  ]);
};

export const getSetMetadataDigestFactory =
  (domain: TypedDataDomain) =>
  (txnHash: Evm.TxnHash, setMetadata: SetMetadata) => {
    return hashTypedData({
      domain,
      ...SET_METADATA_TYPED_DATA_DEFAULTS,
      message: {
        txnHash,
        metadataKey: getMetadataKey(setMetadata.metadataType, setMetadata.key),
        salt: setMetadata.salt,
        author: setMetadata.author,
        value: setMetadata.value,
      },
    });
  };

export const signSetMetadataFactory =
  (domain: TypedDataDomain) =>
  (
    walletClient: WalletClient,
    account: Account,
    txnHash: Evm.TxnHash,
    setMetadata: SetMetadata,
  ) => {
    return walletClient.signTypedData({
      account: account,
      domain,
      ...SET_METADATA_TYPED_DATA_DEFAULTS,
      message: {
        txnHash,
        metadataKey: getMetadataKey(setMetadata.metadataType, setMetadata.key),
        salt: setMetadata.salt,
        author: setMetadata.author,
        value: setMetadata.value,
      },
    });
  };
