import { css } from '~src/styled-system/css';

export const shimmerTextLightenCss = css.raw({
  '--shiny-width': '100px',
  'animation': '[shiny-text 10s cubic-bezier(0, 0, 0.2, 1) infinite]',
  'backgroundClip': 'text',
  'backgroundRepeat': 'no-repeat',
  'backgroundPosition': '0 0',
  'backgroundSize': 'var(--shiny-width) 100%',
  'transitionDuration': '0.15s',
  'transitionProperty':
    'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter',
  'transitionTimingFunction': '[cubic-bezier(0, 0, 0.2, 1)]',
  'backgroundImage':
    'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0))',
  // "@media (prefers-color-scheme: dark)": {
  //   backgroundImage: "linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent)"
  // }
});

export const shimmerTextLighten = css(shimmerTextLightenCss);
