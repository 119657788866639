import { FC } from 'react';
import { Box } from '~src/styled-system/jsx';

export const FarcasterIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="429"
    height="429"
    viewBox="0 0 429 429"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.3846 24H346.692V405H307.308V230.477H306.921C302.569 182.243 261.974 144.445 212.538 144.445C163.103 144.445 122.508 182.243 118.156 230.477H117.769V405H78.3846V24Z"
      fill="currentColor"
    />
    <path
      d="M7 78.0774L23 132.155H36.5385V350.923C29.7411 350.923 24.2308 356.425 24.2308 363.213V377.961H21.7692C14.9719 377.961 9.46154 383.464 9.46154 390.252V405H147.308V390.252C147.308 383.464 141.797 377.961 135 377.961H132.538V363.213C132.538 356.425 127.028 350.923 120.231 350.923H105.462V78.0774H7Z"
      fill="currentColor"
    />
    <path
      d="M309.769 350.923C302.972 350.923 297.462 356.425 297.462 363.213V377.961H295C288.203 377.961 282.692 383.464 282.692 390.252V405H420.538V390.252C420.538 383.464 415.028 377.961 408.231 377.961H405.769V363.213C405.769 356.425 400.259 350.923 393.462 350.923V132.155H407L423 78.0774H324.538V350.923H309.769Z"
      fill="currentColor"
    />
  </svg>
);

export const ColoredFarcasterIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <Box color={'farcaster'}>
    <FarcasterIcon className={className} />
  </Box>
);
