import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import type { SystemStyleObject } from '~src/styled-system/types';
import type { Breakpoints } from '~src/theme/breakpoints';

export const widthVariants = {
  xl: css.raw({
    margin: 'auto',
    maxWidth: {
      base: '100%',
      xl: 'xl',
    },
  }),
  lg: css.raw({
    margin: 'auto',
    maxWidth: {
      base: '100%',
      md: 'md',
      lg: 'lg',
    },
  }),
  md: css.raw({
    margin: 'auto',
    maxWidth: {
      base: '100%',
      md: 'md',
    },
  }),
  sm: css.raw({
    margin: 'auto',
    maxWidth: {
      base: '100%',
      sm: 'sm',
    },
  }),
  xs: css.raw({
    margin: 'auto',
    maxWidth: {
      base: '100%',
      xs: 'xs',
    },
  }),
} as const satisfies Record<
  Exclude<Breakpoints, 'cmdDialog'>,
  SystemStyleObject
>;

export const Xl = styled('div', {
  base: css.raw(widthVariants.xl),
  variants: {
    padMobile: {
      true: {
        paddingX: {
          base: 'innerBox',
          xl: 'zero',
        },
      },
      false: {},
    },
  },
});

export const Lg = styled('div', {
  base: css.raw(widthVariants.lg),
  variants: {
    padMobile: {
      true: {
        paddingX: {
          base: 'innerBox',
          md: 'zero',
        },
      },
      false: {},
    },
  },
});

export const Md = styled('div', {
  base: css.raw(widthVariants.md),
  variants: {
    padMobile: {
      true: {
        paddingX: {
          base: 'innerBox',
          md: 'zero',
        },
      },
      false: {},
    },
  },
});

export const Sm = styled('div', {
  base: css.raw(widthVariants.sm),
  variants: {
    padMobile: {
      true: {
        paddingX: {
          base: 'innerBox',
          sm: 'zero',
        },
      },
      false: {},
    },
  },
});

export const Xs = styled('div', {
  base: css.raw(widthVariants.xs),
  variants: {
    padMobile: {
      true: {
        paddingX: {
          base: 'innerBox',
          xs: 'zero',
        },
      },
      false: {},
    },
  },
});
