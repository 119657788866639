import { FC, ReactNode } from 'react';
import { Header } from '~src/shared-apps/header/main';
import { cva } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { RecipeVariantProps } from '~src/styled-system/types';
import { StickyProvider } from '../sticky/StickyProvider';

import dynamic from 'next/dynamic';
import { Footer } from '../footer';
import type { ColoredLogoTheme } from '../icon/logo';
import * as Width from './width';

const LazySwitchNetworkDialog = dynamic(
  () => import('../network').then((mod) => mod.SwitchNetworkDialog),
  { ssr: false },
);

export const DefaultPageLayout: FC<{
  children: ReactNode;
  showSwitchNetwork?: boolean;
  logoTheme?: ColoredLogoTheme;
}> = ({ children, showSwitchNetwork = true, logoTheme }) => {
  return (
    <>
      <StickyProvider>
        <Header logoTheme={logoTheme} />
        {children}
        <Footer />
        {showSwitchNetwork && <LazySwitchNetworkDialog />}
      </StickyProvider>
    </>
  );
};

const { widthVariants, ...Container } = Width;

export const widthContainerRecipe = cva({
  base: {
    margin: 'auto',
  },
  variants: {
    width: widthVariants, //satisfies Record<Breakpoints, { maxWidth: any }>,
    padMobile: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      padMobile: true,
      width: 'xl',
      css: {
        paddingX: {
          base: 'innerBox',
          xl: 'zero',
        },
      },
    },
    {
      padMobile: true,
      width: 'lg',
      css: {
        paddingX: {
          base: 'innerBox',
          lg: 'zero',
        },
      },
    },
    {
      padMobile: true,
      width: 'md',
      css: {
        paddingX: {
          base: 'innerBox',
          md: 'zero',
        },
      },
    },
    {
      padMobile: true,
      width: 'sm',
      css: {
        paddingX: {
          base: 'innerBox',
          sm: 'zero',
        },
      },
    },
    {
      padMobile: true,
      width: 'xs',
      css: {
        paddingX: {
          base: 'innerBox',
          xs: 'zero',
        },
      },
    },
  ],
});

export { Container };

export const WidthContainer = styled('div', widthContainerRecipe);

export type WidthContainerSize = NonNullable<
  NonNullable<RecipeVariantProps<typeof widthContainerRecipe>>['width']
>;

export const PageWidthContainer = styled(WidthContainer, {
  base: {
    bg: 'white',
    rounded: 'box',
    mx: 'auto',
    pb: 'largestBox',
    pt: 'twoExLargestBox',
  },
});

export * as Spacer from './spacer';

// export const PageContainer: FC<{ children: ReactNode }> = ({ children }) => (
//   <WidthContainer
//     {...pageContainerBaseCss}
//     width="md"
//     padMobile
//   >
//     {children}
//   </WidthContainer>
// );
