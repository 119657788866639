import type { FC } from 'react';
import { P, match } from 'ts-pattern';
import type { CartItem } from '~src/shared-apps/cart/stores';
import type { CheckoutItem } from '~src/shared-apps/checkout/types';
import { HiraethCartItem } from './hiraeth/item';

export type CartItemRowProps = {
  item: CartItem;
  checkoutItem: CheckoutItem | null;
};

export const DropdownCartItemRow: FC<CartItemRowProps> = ({
  item,
  checkoutItem,
}) => {
  return match(item)
    .with(
      {
        objId: ['hiraeth', P.number, P.string],
      },
      () => {
        return (
          <HiraethCartItem.DropdownRow
            item={item}
            checkoutItem={checkoutItem}
          />
        );
      },
    )
    .exhaustive();
};
