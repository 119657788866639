// import { NeynarAuthButton, useNeynarContext } from '@neynar/react';
// import { FC } from 'react';
// import { DropdownContentActionButton, DropdownContentActionButtonWithIcon, DropdownLineSeparator, LayeredDropdownContentBox } from '../dropdown';
// import { useFarcasterAuthenticationContext } from '../../../providers/FarcasterAuthentication';
// import { InheritSpan } from '../../text';
// import { FarcasterIcon } from '../../icon/farcaster';
// import { match } from 'ts-pattern';
// import { ButtonRow } from '../../button';
// import { FarcasterProfilePicture } from '../../../apps/social/components/profile/farcaster';
// import { useBestFarcasterHandle } from '../../../hooks/farcaster/useBestFarcasterHandle';

import { getWeb2UrlForWeb3Uri } from '@pob/web3-fetch';
import type { FC } from 'react';
import { MinorButton } from '~src/components/button';
import {
  CompactDropdownItemA,
  DropdownItemGroup,
  DropdownLineSeparator,
} from '~src/components/dropdown';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import { SimpleImg } from '~src/components/img';
import { Popover } from '~src/components/popover';
import { P } from '~src/components/text';
import { getUniversalProfileUrl } from '~src/external/universal/utils/urls';
import { useUniversalUserFromPrivy } from '~src/hooks/privy/useUniversalUserFromPrivy';
import { useUniversalUserDisplayName } from '~src/hooks/user/useBestUserDisplayName';
import { SOCIAL_PlATFORM_ICONS } from '~src/shared-apps/social/components/icons/social';
import { css } from '~src/styled-system/css';
import { Box, Circle, Flex } from '~src/styled-system/jsx';

export const UniversalConnectedPopoverTrigger: FC = () => {
  const universalUserRes = useUniversalUserFromPrivy();
  return (
    <Popover.Trigger asChild>
      <MinorButton
        bg={'gray.100'}
        height={'navIsland'}
        px={'halfIncrement'}
        py={'halfIncrement'}
        flx={'flexRow'}
        position={'relative'}
      >
        <Circle
          size={'[32px]'}
          pos={'relative'}
          overflow={'hidden'}
          bg={'darken.05'}
        >
          {universalUserRes?.pfp ? (
            <SimpleImg src={getWeb2UrlForWeb3Uri(universalUserRes.pfp)} />
          ) : null}
        </Circle>
      </MinorButton>
    </Popover.Trigger>
  );
};

const UniversalProfileIcon = SOCIAL_PlATFORM_ICONS['universal-profile'];

export const UniversalDropdownHeaderState: FC = () => {
  const universalUser = useUniversalUserFromPrivy();

  const displayName = useUniversalUserDisplayName(universalUser ?? undefined);

  if (!universalUser) {
    return null;
  }
  return (
    <DropdownItemGroup>
      <Flex
        px={'increment'}
        py={'increment'}
        gap={'increment'}
        align={'center'}
      >
        <Circle
          size={'pfp'}
          bg={'gray.100'}
          flx={'flexCenter'}
          pos={'relative'}
          overflow={'hidden'}
        >
          {universalUser.pfp ? (
            <SimpleImg src={getWeb2UrlForWeb3Uri(universalUser.pfp)} />
          ) : null}
        </Circle>
        <Box flexGrow={1}>
          <P
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            lineHeight={'[1.1]'}
            color={'gray.600'}
            textStyle={'p.display.caption'}
          >
            {displayName}
          </P>
          {/* <P
            mt={'quarterIncrement'}
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            color={'gray.400'}
            textStyle={'p.display.caption'}
            lineHeight={'[1.1]'}
          >
            @{farcasterUser.username}
          </P> */}
        </Box>
        <Box opacity={'70'}>
          <UniversalProfileIcon
            className={css({
              width: 'icon.fourteen',
              height: 'icon.fourteen',
            })}
          />
        </Box>
      </Flex>
      <DropdownLineSeparator bg={'darken.03'} />
      <CompactDropdownItemA
        href={getUniversalProfileUrl(universalUser.address)}
        target={'_blank'}
        flx={'flexRowEnds'}
        // bg={{
        //   base: 'gray.100',
        //   _hover: 'gray.200',
        // }}
      >
        Profile
        <UpRightArrowIcon
          className={css({
            display: 'block',
            color: 'gray.400',
            width: 'icon.fourteen',
            height: 'icon.fourteen',
          })}
        />
      </CompactDropdownItemA>
    </DropdownItemGroup>
  );
};

// export const FarcasterDropdownState: FC = () => {
//   const farcasterUser = useFarcasterUserFromPrivy();
//   const isAuthenticated = farcasterUser !== undefined;
//   return match(isAuthenticated)
//     .with(true, () => null)
//     .with(false, () => <FarcasterLogInState />)
//     .exhaustive();
// };

// const FarcasterLogInState: FC = () => {
//   const { open } = usePrivyDialogs();
//   const { close: closeHeader } = useHeader();
//   return (
//     <DropdownItemGroup bg={'white'}>
//       <DropdownItemButton
//         flx={'flexRowEnds'}
//         onClick={() => {
//           open();
//           closeHeader();
//         }}
//       >
//         <InheritSpan>Link Farcaster</InheritSpan>
//         <FarcasterIcon
//           className={css({
//             width: 'icon.fourteen',
//             height: 'icon.fourteen',
//             display: 'block',
//           })}
//         />
//       </DropdownItemButton>
//     </DropdownItemGroup>
//   );
// };

// const FarcasterAuthenticatedState: FC = () => {
//   const { open } = usePrivyDialogs();
//   const { close: closeHeader } = useHeader();
//   return (
//     <DropdownItemGroup bg={'white'}>
//       <DropdownItemButton
//         flx={'flexRowEnds'}
//         onClick={() => {
//           open();
//           closeHeader();
//         }}
//       >
//         <InheritSpan>Settings</InheritSpan>
//         <Settings
//           className={css({
//             width: 'icon.fourteen',
//             height: 'icon.fourteen',
//             display: 'block',
//           })}
//         />
//       </DropdownItemButton>
//     </DropdownItemGroup>
//   );
// };
