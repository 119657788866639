import { NULL_ADDRESS, type Evm } from '@pob/shared';
import { getUnixTime, Interval } from 'date-fns';
import { toSeconds } from 'duration-fns';
import { parseEther } from 'viem/utils';
import {
  DutchAuctionMintingConfig,
  DutchAuctionMintingConfigStruct,
  FlatPriceMintingConfig,
  MintingGroup,
  MintingGroupStruct,
  MintingMetadata,
} from '../types';

// export const getDutchAuctionConfig = (
//   startPriceInEth: string,
//   endPriceInEth: string,
//   duration: Duration,
//   dropInterval: Duration,
//   isActive: boolean = true,
// ): DutchAuctionMintingConfig => {
//   const startPrice = parseEther(startPriceInEth);
//   const endPrice = parseEther(endPriceInEth);
//   return {
//     isActive,
//     startPrice,
//     endPrice,
//     duration,
//     dropInterval,
//     dropPerStep:
//       (startPrice - endPrice) /
//       BigInt(toSeconds(duration) / toSeconds(dropInterval)),
//   };
// };

export const getFlatPriceConfig = (
  flatPriceInEth: string,
  isActive: boolean = true,
): FlatPriceMintingConfig => {
  const flatPrice = parseEther(flatPriceInEth);
  return {
    isActive,
    flatPrice,
  };
};

export const convertDutchAuctionConfigForProtocol = (
  config: DutchAuctionMintingConfig,
): DutchAuctionMintingConfigStruct => {
  return {
    ...config,
    duration: toSeconds(config.duration),
    dropInterval: toSeconds(config.dropInterval),
  };
};

// export const getDutchAuctionPrice = (
//   group: MintingGroup,
//   config: DutchAuctionMintingConfig,
//   timestamp: Date | number,
// ) => {
//   const timestampNumber = getUnixTime(timestamp);
//   const activeFromTimestampNumber = getUnixTime(group.activeFrom);
//   const durationInSeconds = toSeconds(config.duration);
//   const dropInterval = toSeconds(config.dropInterval);
//   if (timestampNumber < activeFromTimestampNumber) {
//     return config.startPrice;
//   }
//   if (timestampNumber - activeFromTimestampNumber <= durationInSeconds) {
//     return config.endPrice;
//   }
//   const steps = (timestampNumber - activeFromTimestampNumber) / dropInterval;
//   return config.startPrice - BigInt(steps) * config.dropPerStep;
// };

// export const getNextDutchAuctionIntervalStartTime = (
//   group: MintingGroup,
//   config: DutchAuctionMintingConfig,
//   timestamp: Date | number,
//   numNextIntervals: number = 1,
// ) => {
//   const timestampNumber = getUnixTime(timestamp);
//   const activeFromTimestampNumber = getUnixTime(group.activeFrom);
//   const durationInSeconds = toSeconds(config.duration);
//   const dropInterval = toSeconds(config.dropInterval);
//   if (timestampNumber < activeFromTimestampNumber) {
//     return group.activeFrom;
//   }
//   const steps =
//     (timestampNumber - activeFromTimestampNumber) / dropInterval +
//     numNextIntervals;
//   const nextStartTime = activeFromTimestampNumber + steps * dropInterval;
//   if (nextStartTime - activeFromTimestampNumber >= durationInSeconds) {
//     return getEndingTimestampForDutchAuction(group, config);
//   }
//   return toDate(nextStartTime * 1000);
// };

// export const getEndingTimestampForDutchAuction = (
//   group: MintingGroup,
//   config: DutchAuctionMintingConfig,
// ) => {
//   const durationInSeconds = toSeconds(config.duration);
//   return toDate(getUnixTime(group.activeFrom) + durationInSeconds * 1000);
// };

export const getMintingGroupConfig = (
  supply: bigint,
  mintingRange: Interval,
  isTransferrable: boolean = false,
  isImmutable: boolean = false,
): MintingGroup => {
  return {
    isTransferrable,
    supply,
    numMinted: 0n,
    activeFrom: mintingRange.start,
    endAt: mintingRange.end,
    isImmutable,
  };
};

export const convertMintingGroupForProtocol = (
  group: MintingGroup,
  mintingAuthority?: Evm.Address,
): MintingGroupStruct => {
  return {
    ...group,
    activeFromTimestamp: getUnixTime(group.activeFrom),
    endAtTimestamp: getUnixTime(group.endAt),
    mintingAuthority: mintingAuthority ?? NULL_ADDRESS,
  };
};

export const convertMintingMetadataToSortedGroupsForProtocol = (
  metadata: MintingMetadata[],
  mintingAuthority: (Evm.Address | undefined)[],
) => {
  return sortedGroupsForProtocol(metadata).map((m, i) => {
    return convertMintingGroupForProtocol(m.group, mintingAuthority[i]);
  });
};

export const sortedGroupsForProtocol = (metadata: MintingMetadata[]) => {
  return metadata.sort((m1, m2) => m1.id - m2.id);
};
