import { Duration, Interval, addSeconds } from 'date-fns';
import { toSeconds } from 'duration-fns';
export const getIntervalFromActiveFromAndDuration = (
  activeFrom: Date | number,
  duration: Duration,
) => {
  return {
    start: activeFrom,
    end: addSeconds(activeFrom, toSeconds(duration)),
  } as Interval;
};

export const getIntervalFromActiveFromToIndefinite = (
  activeFrom: Date | number,
) => {
  return {
    start: activeFrom,
    end: addSeconds(activeFrom, toSeconds({ years: 10000 })), // pretty much indefinite for 10000 years
  } as Interval;
};
