import type { BlockverseProjectId } from '@pob/blockverse';
import { useMemo } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type { BlockverseContext } from '../types/context';
import { useAddBlockverseContext } from './useAddBlockverseContext';

export const useAddSingleBlockverseContext = <E extends BlockverseProjectId>(
  objId?: BlockverseObjId<E>,
  context?: BlockverseContext,
) => {
  useAddBlockverseContext(
    useMemo(() => {
      if (!context) {
        return [];
      }
      if (!objId) {
        return [];
      }
      return [{ context, objId }];
    }, [context, objId]),
  );
};
