import destr from 'destr';
import { atob } from 'isomorphic-base64';
import { ofetch } from 'ofetch';
import { match, P } from 'ts-pattern';
import { QueryObject, resolveURL, withQuery } from 'ufo';

export type Web3Resource = 'ipfs' | 'arweave' | 'https' | 'data' | 'data-image';

export const IPFS_REGEX =
  /(Qm[1-9A-HJ-NP-Za-km-z]{44,}|b[A-Za-z2-7]{58,}|B[A-Z2-7]{58,}|z[1-9A-HJ-NP-Za-km-z]{48,}|F[0-9A-F]{50,})(\/(\d|\w|\.)+)*/;

export const containsIpfsHash = (uri?: string) =>
  !!uri ? IPFS_REGEX.test(uri) : false;

export const cleanIpfsURIToHash = (uri: string) => {
  const matchResults = uri.match(IPFS_REGEX);
  if (matchResults === null) {
    throw new Error('uri does not contain a ipfs cid');
  }
  return matchResults[0];
};

export const IPFS_HEADER = 'ipfs://';
export const ARWEAVE_HEADER = 'arweave://';
export const HTTPS_HEADER = 'https://';
export const HTTP_HEADER = 'http://';
export const VERCEL_API_HEADER = '/api';

export const DATA_HEADER_REGEX =
  /data:application\/json(;charset=utf-8|;charset=UTF-8|;base64)?,?/;
export const DATA_BASE_64_HEADER_REGEX =
  /data:application\/json(;charset=utf-8|;charset=UTF-8)?;base64,?/;

export const DATA_IMAGE_REGEX =
  /data:image\/(png|jpeg|gif|bmp|webp|svg\+xml);base64,?/;

export const extractCidFromIpfsUrl = (url: `ipfs://${string}`) => {
  const cid = url.slice(IPFS_HEADER.length).split('/')[0];
  return cid;
};

export type Gateway = (
  cid: string,
  path: string,
  apiKey?: string,
) => {
  url: string;
  headers: Record<string, string>;
};

export type GatewayRequest = ReturnType<Gateway>;

const PRIVATE_GATEWAY_INDEX = 0;
const PUBLIC_GATEWAY_INDEX = 1;

export const IPFS_GATEWAYS: Gateway[] = [
  // (cid: string, path: string, apiKey?: string) => ({
  //   url: `https://ipfs.pob.studio/ipfs/${cid}/${path}?pinataGatewayToken=${apiKey}`,
  //   headers: {},
  // }),
  (cid: string, path: string, apiKey?: string) => ({
    url: `https://crimson-advanced-mockingbird-290.mypinata.cloud/ipfs/${cid}/${path}?pinataGatewayToken=${apiKey}`,
    headers: {},
  }),
  (cid: string, path: string, apiKey?: string) => ({
    url: `https://ipfs.io/ipfs/${cid}/${path}`,
    headers: {},
  }),
];

export const ARWEAVE_GATEWAYS: Gateway[] = [
  (cid: string, path: string) => ({
    url: `https://akord.net/${cid}/${path}`,
    headers: {},
  }),
  (cid: string, path: string) => ({
    url: `https://arweave.net/${cid}/${path}`,
    headers: {},
  }),
];

const getIpfsApiKeyFromEnv = () => {
  return (
    process.env.NEXT_PUBLIC_PINATA_ACCESS_KEY ?? process.env.PINATA_ACCESS_KEY
  );
};

export const fetchFromIpfs = async <T>(
  cidAndPath: string,
  options?: Partial<FetchWeb3Options>,
): Promise<T | undefined> => {
  if (!cidAndPath.startsWith(IPFS_HEADER)) {
    return undefined;
  }
  const splitPath = cidAndPath.slice(IPFS_HEADER.length).split('/');
  const cid = splitPath[0];
  const path = splitPath.slice(1).join('/');
  for (let i = 0; i < IPFS_GATEWAYS.length; i++) {
    if (options?.preferPublicIpfsGateway && i === PRIVATE_GATEWAY_INDEX) {
      continue;
    }
    const apiKey = options?.ipfsGatewayApiKey ?? getIpfsApiKeyFromEnv();
    if (!apiKey) {
      console.warn('No api key found for ipfs gateway');
    }
    const gateway = IPFS_GATEWAYS[i];
    const { url, headers } = gateway(cid, path, apiKey);
    try {
      return await ofetch(url, { retry: 2, headers });
    } catch (e: any) {
      console.log('e', e);
      console.log(`${url} failed with ${e.message}, trying next fallback...`);
    }
  }
  throw new Error(`Could not fetch from any ipfs gateway`);
};

export const fetchFromArweave = async <T>(
  cidAndPath: string,
  options?: Partial<FetchWeb3Options>,
): Promise<T | undefined> => {
  if (!cidAndPath.startsWith(ARWEAVE_HEADER)) {
    return undefined;
  }
  const splitPath = cidAndPath.slice(ARWEAVE_HEADER.length).split('/');
  const cid = splitPath[0];
  const path = splitPath.slice(1).join('/');
  for (let i = 0; i < IPFS_GATEWAYS.length; i++) {
    if (options?.preferPublicIpfsGateway && i === PRIVATE_GATEWAY_INDEX) {
      continue;
    }
    const gateway = IPFS_GATEWAYS[i];
    const { url, headers } = gateway(cid, path);
    try {
      return await ofetch(url, { retry: 2, headers });
    } catch (e: any) {
      console.log(`${url} failed with ${e.message}, trying next fallback...`);
    }
  }
  throw new Error(`Could not fetch from any ipfs gateway`);
};

export const fetchFromHttps = async <T>(
  url: string,
  options?: Partial<FetchWeb3Options>,
): Promise<T | undefined> => {
  if (
    !(
      url.startsWith(VERCEL_API_HEADER) ||
      url.startsWith(HTTPS_HEADER) ||
      url.startsWith(HTTP_HEADER)
    )
  ) {
    return undefined;
  }
  return await ofetch(withQuery(url, options?.queryObject ?? {}), {
    headers: options?.headers,
    retry: 2,
  });
};

export const fetchFromDataImage = async <T>(
  url: string,
): Promise<{
  buffer: Uint8Array;
  mimeType: string;
} | undefined> => {
  if (!DATA_IMAGE_REGEX.test(url)) {
    return undefined;
  }
  // slice url to remove data header
  const dataUrl = DATA_IMAGE_REGEX.test(url)
    ? url.replace(DATA_IMAGE_REGEX, '')
    : url;

  const rawImage = atob(dataUrl);
  // return as a Uint8Array
  const buffer = new Uint8Array(rawImage.length);
  for (let i = 0; i < rawImage.length; i++) {
    buffer[i] = rawImage.charCodeAt(i);
  }
  const mimeType = url.split(':')[1].split(';')[0];

  return {
    buffer,
    mimeType,
  };
};

export const fetchFromData = async <T>(url: string): Promise<T | undefined> => {
  if (!DATA_HEADER_REGEX.test(url)) {
    return undefined;
  }
  // slice url to remove data header
  const dataUrl = decodeURIComponent(DATA_HEADER_REGEX.test(url)
    ? url.replace(DATA_HEADER_REGEX, '')
    : url);

  if (DATA_BASE_64_HEADER_REGEX.test(url)) {
    const rawJSONStr = atob(dataUrl);
    return destr(rawJSONStr, { strict: true });
  }
  return destr(dataUrl, { strict: true });
};

export const getWeb3ResourceType = (url: string): Web3Resource | undefined => {
  if (url.startsWith(IPFS_HEADER)) {
    return 'ipfs';
  }
  if (url.startsWith(ARWEAVE_HEADER)) {
    return 'arweave';
  }
  if (url.startsWith(VERCEL_API_HEADER)) {
    return 'https';
  }
  if (url.startsWith(HTTPS_HEADER)) {
    return 'https';
  }
  if (url.startsWith(HTTP_HEADER)) {
    return 'https';
  }
  if (DATA_HEADER_REGEX.test(url)) {
    return 'data';
  }
  if (DATA_IMAGE_REGEX.test(url)) {
    return 'data-image';
  }
  return undefined;
};

export type FetchWeb3Options = {
  queryObject: QueryObject;
  ipfsGatewayApiKey?: string;
  preferPublicIpfsGateway?: boolean;
  headers?: Record<string, string>;
};

export const fetchWeb3 = async <T>(
  url: string,
  options?: Partial<FetchWeb3Options>,
): Promise<T | undefined> => {
  const resourceType = getWeb3ResourceType(url);
  return match(resourceType)
    .with('ipfs', () => fetchFromIpfs<T>(url, options))
    .with('arweave', () => fetchFromArweave<T>(url, options))
    .with('https', () => fetchFromHttps<T>(url, options))
    .with('data', () => fetchFromData<T>(url))
    .with('data-image', () => fetchFromData<T>(url))
    .with(P.nullish, () => undefined)
    .exhaustive();
};

export const getRequestForWeb3Uri = (
  uri: string,
  options?: Partial<FetchWeb3Options>,
): GatewayRequest => {
  const resourceType = getWeb3ResourceType(uri);

  if (resourceType === 'ipfs') {
    const splitPath = uri.slice(IPFS_HEADER.length).split('/');
    const cid = splitPath[0];
    const path = splitPath.slice(1).join('/');
    const apiKey = options?.ipfsGatewayApiKey ?? getIpfsApiKeyFromEnv();
    if (!apiKey) {
      console.warn('No api key found for ipfs gateway');
    }
    // console.log('ipfs gateway', options?.preferPublicIpfsGateway, IPFS_GATEWAYS[
    //   options?.preferPublicIpfsGateway
    //     ? PUBLIC_GATEWAY_INDEX
    //     : PRIVATE_GATEWAY_INDEX
    // ](cid, path, apiKey));
    return IPFS_GATEWAYS[
      options?.preferPublicIpfsGateway
        ? PUBLIC_GATEWAY_INDEX
        : PRIVATE_GATEWAY_INDEX
    ](cid, path, apiKey);
  }

  if (resourceType === 'arweave') {
    const splitPath = uri.slice(ARWEAVE_HEADER.length).split('/');
    const cid = splitPath[0];
    const path = splitPath.slice(1).join('/');
    return ARWEAVE_GATEWAYS[
      options?.preferPublicIpfsGateway
        ? PUBLIC_GATEWAY_INDEX
        : PRIVATE_GATEWAY_INDEX
    ](cid, path);
  }

  if (resourceType === 'https') {
    return {
      url: withQuery(uri, options?.queryObject ?? {}),
      headers: {},
    };
  }

  return {
    url: uri,
    headers: {},
  };
};

export const getWeb2UrlForWeb3Uri = (
  uri: string,
  options?: Partial<FetchWeb3Options>,
) => {
  return getRequestForWeb3Uri(uri, options).url;
};

export const getUrlWithPath = (hashOrUri: string, path: string | number) => {
  return resolveURL(hashOrUri, path.toString());
};
