import type { SupportedCheckoutChainId } from '../checkout';
import {
  PROJECT_SUPPORTED_CHECKOUT_CHAINS,
  SupportedCheckoutProjectId,
} from '../checkout';

export const isSupportedChainForProjectForCheckout = <
  TProjectId extends SupportedCheckoutProjectId,
>(
  projectId: TProjectId,
  chainId: any,
): chainId is SupportedCheckoutChainId<TProjectId> => {
  return !!(PROJECT_SUPPORTED_CHECKOUT_CHAINS[projectId] as any)[chainId];
};
