import { FC } from 'react';

export const LogoIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 23C50.67 23 34.5425 36.7113 30.8127 54.9386C30.2798 57.5425 30 60.2386 30 63V105.5V106.833C30 113.001 34.9995 118 41.1667 118H48V117.28C48 102.766 59.766 91 74.2802 91C74.6777 91 75 90.6777 75 90.2802V88.8506C75 88.74 75.0006 88.6296 75.0018 88.5193C73.3832 88.8347 71.7109 89 70 89C55.6406 89 44 77.3594 44 63C44 48.6406 55.6406 37 70 37C83.4733 37 94.553 47.2483 95.8691 60.3748C98.7037 59.4816 101.721 59 104.851 59H109.802C107.796 38.7867 90.7414 23 70 23ZM48 132H56.5H62V117.28C62 110.498 67.498 105 74.2802 105C82.4097 105 89 98.4097 89 90.2802V88.8506C89 80.0966 96.0966 73 104.851 73H110V106.833C110 113.001 105.001 118 98.8333 118H87V132H98.8333C112.732 132 124 120.733 124 106.833V73V66V63C124 33.1766 99.8234 9 70 9C40.1766 9 16 33.1766 16 63V105.5V106.833C16 120.732 27.2675 132 41.1667 132H48ZM82 63C82 69.6274 76.6274 75 70 75C63.3726 75 58 69.6274 58 63C58 56.3726 63.3726 51 70 51C76.6274 51 82 56.3726 82 63Z"
        fill={'currentColor'}
      />
    </svg>
  );
};

interface ColoredLogoColorTheme {
  background: `#${string}`;
  head: `#${string}`;
  body: `#${string}`;
}

const COLOR_THEMES = {
  lukso: {
    background: '#FFEDF3',
    head: '#FE005B',
    body: '#FFB5D0',
  },
  default: {
    background: '#BFD8DA',
    head: '#EF7845',
    body: '#E4DFBC',
  },
} satisfies Record<string, ColoredLogoColorTheme>;

export type ColoredLogoTheme = keyof typeof COLOR_THEMES;

export const ColoredLogoIcon: FC<{
  className?: string;
  theme?: ColoredLogoTheme;
}> = ({ className, theme = 'default' }) => {
  const { background, head, body } = COLOR_THEMES[theme];
  return (
    <svg
      className={className}
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 62V105.833C23 115.867 31.1335 124 41.1667 124H56.5H98.8333C108.867 124 117 115.867 117 105.833V62C117 36.0426 95.9574 15 70 15C44.0426 15 23 36.0426 23 62Z"
        fill={body}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 22C47.9086 22 30 39.9086 30 62V105.833C30 112.001 34.9995 117 41.1667 117H98.8333C105.001 117 110 112.001 110 105.833V62C110 39.9086 92.0914 22 70 22ZM16 62C16 32.1766 40.1766 8 70 8C99.8234 8 124 32.1766 124 62V105.833C124 119.732 112.732 131 98.8333 131H41.1667C27.2675 131 16 119.733 16 105.833V62Z"
        fill={body}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 62H119.876C118.574 35.8235 96.9377 15 70.438 15C43.9382 15 22.3021 35.8235 21 62V117H54V102H77V78H54V62Z"
        fill={background}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8127 53.9386C34.5425 35.7113 50.67 22 70 22C90.7414 22 107.796 37.7867 109.802 58H104.851C88.3646 58 75 71.3646 75 87.8506V89.2802C75 89.6777 74.6777 90 74.2802 90C59.766 90 48 101.766 48 116.28V117H41.1667C34.9995 117 30 112.001 30 105.833V104.5V62C30 59.2386 30.2798 56.5425 30.8127 53.9386ZM56.5 131H48H41.1667C27.2675 131 16 119.732 16 105.833V104.5V62C16 32.1766 40.1766 8 70 8C99.8234 8 124 32.1766 124 62V72V105.833C124 119.733 112.732 131 98.8333 131H87V117H98.8333C105.001 117 110 112.001 110 105.833V72H104.851C96.0966 72 89 79.0966 89 87.8506V89.2802C89 97.4097 82.4097 104 74.2802 104C67.498 104 62 109.498 62 116.28V131H56.5Z"
        fill="black"
      />
      <path
        d="M96 62C96 76.3594 84.3594 88 70 88C55.6406 88 44 76.3594 44 62C44 47.6406 55.6406 36 70 36C84.3594 36 96 47.6406 96 62Z"
        fill={head}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 74C76.6274 74 82 68.6274 82 62C82 55.3726 76.6274 50 70 50C63.3726 50 58 55.3726 58 62C58 68.6274 63.3726 74 70 74ZM70 88C84.3594 88 96 76.3594 96 62C96 47.6406 84.3594 36 70 36C55.6406 36 44 47.6406 44 62C44 76.3594 55.6406 88 70 88Z"
        fill="black"
      />
    </svg>
  );
};
