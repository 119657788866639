import { Portal } from '@ark-ui/react';
import type { FC } from 'react';
import {
  DropdownItemA,
  DropdownItemGroup,
  DropdownItemGroupGrid,
  DropdownLabel,
} from '~src/components/dropdown';
import { DiscordIcon } from '~src/components/icon/discord';
import { FarcasterIcon } from '~src/components/icon/farcaster';
import { MenuIcon } from '~src/components/icon/menu';
import { TwitterIcon } from '~src/components/icon/twitter';
import { YoutubeIcon } from '~src/components/icon/youtube';
import { Popover } from '~src/components/popover';
import {
  DISCORD_LINK,
  NEWSLETTER_LINK,
  TWITTER_LINK,
  WARPCAST_LINK,
  YOUTUBE_LINK,
} from '~src/constants';
import { css } from '~src/styled-system/css';
import { padding } from '~src/theme/tokens/padding';
import { route } from '~src/utils/routes';
import {
  SocialDropdownActionItem,
  SocialDropdownItemGroup,
  TopNavIslandButton,
  socialDropdownActionItemIcon,
} from '.';

export const TopMobileMenuIsland: FC = () => {
  return (
    <Popover.Root
      lazyMount
      unmountOnExit
      portalled
      positioning={{
        placement: 'bottom-start',
        offset: { mainAxis: padding.box },
      }}
    >
      <Popover.Trigger asChild>
        <TopNavIslandButton padding={'zero'} hideFrom={'sm'}>
          <MenuIcon
            className={css({
              height: 'icon.sixteen',
              width: 'icon.sixteen',
              display: 'block',
            })}
          />
        </TopNavIslandButton>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content>
            <DropdownItemGroupGrid
              minWidth={'[270px]'}
              gridTemplateColumns={'2'}
              gridColumnGap={'zero'}
            >
              <DropdownItemGroup roundedRight={'zero'}>
                <DropdownLabel>Projects</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/hiraeth',
                  })}
                  key="hiraeth"
                >
                  Hiraeth
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/exeo',
                  })}
                  key="exeo"
                >
                  Exeo
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup roundedLeft={'zero'}>
                <DropdownLabel>Studio</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/about',
                  })}
                  key="about"
                >
                  About
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/vision',
                  })}
                  key="vision"
                >
                  Vision
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/work',
                  })}
                  key="works"
                >
                  Works
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup roundedRight={'zero'}>
                <DropdownLabel>Learn</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/collector-std',
                  })}
                  key="collector"
                >
                  Collector Std.
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/learn/contract-art',
                  })}
                  key="contract-art"
                >
                  Contract Art
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup roundedLeft={'zero'}>
                <DropdownLabel>Resources</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/learn/metadata-cheat-sheet',
                  })}
                  key="nft-metadata"
                >
                  NFT Metadata
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/contracts',
                  })}
                  key="contracts"
                >
                  Contracts
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup gridColumn={'[span 2]'}>
                <DropdownLabel>Outreach</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/preserve',
                  })}
                  key="preserve"
                >
                  Preserve Initiative
                </DropdownItemA>
                <DropdownItemA
                  target={'_blank'}
                  href={NEWSLETTER_LINK}
                  key="newsletter"
                >
                  Newsletter
                </DropdownItemA>
              </DropdownItemGroup>
              <SocialDropdownItemGroup gridColumn={'[span 2]'} flx={'flexRow'}>
                <SocialDropdownActionItem
                  href={TWITTER_LINK}
                  key={'twitter'}
                  target={'_blank'}
                >
                  <TwitterIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'warpcast'}
                  href={WARPCAST_LINK}
                  target={'_blank'}
                >
                  <FarcasterIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'youtube'}
                  href={YOUTUBE_LINK}
                  target={'_blank'}
                >
                  <YoutubeIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'discord'}
                  href={DISCORD_LINK}
                  target={'_blank'}
                >
                  <DiscordIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
              </SocialDropdownItemGroup>
            </DropdownItemGroupGrid>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};
