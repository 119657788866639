import { trpc } from '~src/clients/trpc-client';
import { useBestWalletForPrivyUser } from '../wallet/useWalletFromPrivy';

export const useEnsUserFromPrivy = () => {
  const bestWallet = useBestWalletForPrivyUser();
  const ensUserRes = trpc.user.ens.byAddress.useQuery(
    {
      address: bestWallet?.address!,
    },
    {
      enabled: !!bestWallet?.address,
    },
  );
  return ensUserRes;
};
