import { cva } from '~src/styled-system/css';
import type { ProjectState } from '~src/types/project/metadata';

const projectStateVariants = {
  'alpha': {
    bg: 'turquoise',
    color: 'turquoise.text',
  },
  'beta': {
    bg: 'lavender',
    color: 'lavender.text',
  },
  'live': {
    bg: 'mint',
    color: 'mint.text',
  },
  'finished': {
    bg: 'coffee',
    color: 'coffee.text',
  },
  'pre-alpha': {
    bg: 'gray.100',
    color: 'gray.600',
  },
  'archived': {
    bg: 'gray.400',
    color: 'white',
  },
  'soon': {
    bg: 'gray.200',
    color: 'gray.600',
  },
} as const satisfies Record<ProjectState, { bg: string; color: string }>;

export const projectStateRecipe = cva({
  base: {},
  variants: {
    state: projectStateVariants,
  },
});
