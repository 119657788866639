import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';

export const navRowCss = css.raw({
  maxWidth: {
    base: '100%',
    xl: 'xl',
  },
  mx: 'auto',
  px: 'innerBox',
});

export const NavRow = styled('div', {
  base: navRowCss,
});

export const NavRowSpacer = styled('div', {
  base: {
    width: 'quarterIncrement',
    height: '[1rem]',
    ml: 'innerBox',
    mr: 'innerBox',
    background: 'darken.075',
    display: {
      base: 'none',
      sm: 'block',
    },
  },
});

export const NavRowLeftContainer = styled('div', {
  base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    // '@media': {
    //   [mediaQueryConditions.upToSmall['@media']]: {
    //     gap: tp.padding.innerBox,
    //     width: 'fit-content',
    //     flexDirection: 'row-reverse',
    //   },
    // },
  },
});

export const NavRowRightContainer = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 'increment',
  },
});

export const NavContainer = styled('div', {
  base: {
    bg: 'background',
  },
});

// export const NavSpacer = div`
//   height: ${NAV_HEIGHT}px;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToExtraSmall}px) {
//     height: ${MOBILE_NAV_HEIGHT}px;
//   }
// `;
