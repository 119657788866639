import { Portal } from '@ark-ui/react';
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { FC } from 'react';
import { Spinner } from 'react-activity';
import { match } from 'ts-pattern';
import { MinorButton } from '~src/components/button';
import { DropdownItemGroupGrid } from '~src/components/dropdown';
import { Popover } from '~src/components/popover';
import { Tooltip, tooltipTimingProps } from '~src/components/tooltip';
import { useBestExtAccountTypeFromPrivy } from '~src/hooks/privy/useBestExtAccountTypeFromPrivy';
import { colors } from '~src/theme/tokens/colors';
import { useHeader } from '../../providers/HeaderProvider';
import {
  FarcasterConnectedPopoverTrigger,
  FarcasterDropdownHeaderState,
  FarcasterDropdownState,
} from './farcaster';
import { MoreDropdownState } from './more';
import { ConnectedTooltipState } from './tooltip';
import {
  UniversalConnectedPopoverTrigger,
  UniversalDropdownHeaderState,
} from './universal';
import {
  Web3ConnectedPopoverTrigger,
  Web3DropdownHeaderState,
  Web3DropdownState,
} from './web3';

export const HeaderUserStatus: FC = () => {
  const { ready, authenticated, user } = usePrivy();
  const { wallets } = useWallets();

  // console.log('status', ready, authenticated, wallets, user);

  if (!ready) {
    return <ConnectingState />;
  }

  if (!authenticated) {
    return <ConnectState />;
  }

  return <ConnectedState key={'connected-state-status'} />;
  // if (status === 'connected') {
  //   return <ConnectedState key={'connected-state-status'} />;
  // }

  // if (status === 'disconnected') {
  //   return <ConnectState />;
  // }
  // if (status === 'connecting' || status === 'reconnecting') {
  //   return <ConnectingState />;
  // }

  return null;
};

const ConnectingState: FC = () => {
  return (
    <MinorButton
      height={'navIsland'}
      px={'box'}
      btnType={'quaternary'}
      rounded={'squaredButton'}
      flx={'flexCenter'}
      disabled
      width={'[98px]'}
    >
      <Spinner size={12} color={colors['gray.800']} />
    </MinorButton>
  );
};

const ConnectedState: FC = ({}) => {
  const { isOpen, setIsOpen } = useHeader();
  const bestUserLinkedAccountType = useBestExtAccountTypeFromPrivy();
  return (
    <Tooltip.Root
      positioning={{
        placement: 'bottom-end',
      }}
      {...tooltipTimingProps.default}
    >
      <Tooltip.Trigger asChild>
        <div>
          <Popover.Root
            lazyMount
            unmountOnExit
            open={isOpen}
            onOpenChange={({ open }) => setIsOpen(open)}
            positioning={{
              placement: 'bottom-end',
            }}
            portalled
          >
            {match(bestUserLinkedAccountType)
              .with('wallet', () => <Web3ConnectedPopoverTrigger />)
              .with('farcaster', () => <FarcasterConnectedPopoverTrigger />)
              .with('universal-profile', () => (
                 <UniversalConnectedPopoverTrigger />
              ))
              .otherwise(() => null)}
            <Portal>
              {' '}
              <Popover.Positioner>
                <Popover.Content
                  style={{
                    width: '240px',
                  }}
                >
                  <DropdownItemGroupGrid>
                    <FarcasterDropdownHeaderState />
                    <UniversalDropdownHeaderState />
                    <Web3DropdownHeaderState />
                    <FarcasterDropdownState />
                    <Web3DropdownState />
                    <MoreDropdownState />
                  </DropdownItemGroupGrid>
                </Popover.Content>
              </Popover.Positioner>
            </Portal>
          </Popover.Root>
        </div>
      </Tooltip.Trigger>
      <Tooltip.Positioner>
        <Tooltip.Content>
          <ConnectedTooltipState />
        </Tooltip.Content>
      </Tooltip.Positioner>
    </Tooltip.Root>
  );
};

const ConnectState: FC<{ handleOnConnectClick?: () => void }> = ({
  handleOnConnectClick,
}) => {
  // const { openConnectModal } = useConnectModal();
  const { login, ready } = usePrivy();
  const { logout } = usePrivy();
  // const { connect, connectors } = useConnect();
  // const coinbaseWallet = useMemo(() => {
  //   return connectors.find((c) => c.type === 'coinbaseWallet');
  // }, [connectors]);
  return (
    <>
      {/* <MinorConnectButton
        $buttonStyle="quaternary-solid"
        onClick={async () => {
          if (!!handleOnConnectClick) {
            handleOnConnectClick();
          }
          if (!coinbaseWallet) {
            return;
          }
          connect({ connector: coinbaseWallet });
        }}
      >
        Create Wallet
      </MinorConnectButton> */}
      <MinorButton
        height={'navIsland'}
        px={'box'}
        rounded={'squaredButton'}
        btnType={'secondary'}
        disabled={!ready}
        onClick={() => {
          if (!!handleOnConnectClick) {
            handleOnConnectClick();
          }
          login({});
        }}
      >
        Connect
      </MinorButton>
    </>
  );
};
