import { useMemo } from 'react';
import { match } from 'ts-pattern';
import { lukso } from 'viem/chains';
import type { ColoredLogoTheme } from '~src/components/icon/logo';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';

export const useColoredLogoTheme = (): ColoredLogoTheme => {
  const preferredChainId = usePreferredChainId();
  return useMemo(
    () =>
      match(preferredChainId)
        .with(lukso.id, () => 'lukso' as const)
        .otherwise(() => 'default' as const),
    [preferredChainId],
  );
};
