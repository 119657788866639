import { hiraethAbi, hiraethLuksoAbi } from '@hiraeth/protocol';
import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { ConditionalPick, Simplify } from 'type-fest';
import type { Abi } from 'viem';
import {
  base,
  baseSepolia,
  lukso,
  mainnet,
  optimism,
  optimismSepolia,
} from 'viem/chains';

export type HiraethConfig = {
  numBlocksForRandom: number;
  isFramesMintingSupported: boolean;
  tokenAbi: Abi;
};

const DEFAULT_HIRAETH_CONFIG = {
  numBlocksForRandom: 1,
  isFramesMintingSupported: true,
  tokenAbi: hiraethAbi,
} satisfies HiraethConfig;

export const HIRAETH_CONFIG = {
  [mainnet.id]: DEFAULT_HIRAETH_CONFIG,
  [base.id]: DEFAULT_HIRAETH_CONFIG,
  [baseSepolia.id]: DEFAULT_HIRAETH_CONFIG,
  [optimism.id]: DEFAULT_HIRAETH_CONFIG,
  [optimismSepolia.id]: DEFAULT_HIRAETH_CONFIG,
  [lukso.id]: {
    numBlocksForRandom: 14,
    isFramesMintingSupported: false,
    tokenAbi: hiraethLuksoAbi,
  },
} as const satisfies Record<
  SupportedBlockverseChainId<'hiraeth'>,
  HiraethConfig
>;

export type HiraethTokenAbi =
  (typeof HIRAETH_CONFIG)[SupportedBlockverseChainId<'hiraeth'>]['tokenAbi'];

export type HiraethChainIdsUnsupportedByFrames = keyof ConditionalPick<
  typeof HIRAETH_CONFIG,
  {
    isFramesMintingSupported: false;
  }
>;

export type HiraethChainIdsSupportedByFrames = Simplify<
  keyof ConditionalPick<
    typeof HIRAETH_CONFIG,
    {
      isFramesMintingSupported: true;
    } | null
  >
>;
