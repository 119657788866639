import type { FC } from 'react';
import type { CartItemRowProps } from '~src/shared-apps/cart/component/item';
import {
  BaseCartItemRow,
  DropdownBaseCartItemRow,
} from '~src/shared-apps/cart/component/item/base';
import { useHiraethCheckoutRowItemDetailProps } from '~src/shared-apps/checkout/components/item/hiraeth/item';
import { getChainNetwork } from '~src/utils/chain';
import { route } from '~src/utils/routes';

const HiraethDropdownCartItemRow: FC<CartItemRowProps> = ({ item }) => {
  const rowItemDetailProps = useHiraethCheckoutRowItemDetailProps(item);
  return (
    <DropdownBaseCartItemRow
      href={route({
        pathname: '/hiraeth/[chainNetwork]/[txnHash]',
        query: {
          chainNetwork: getChainNetwork(item.objId[1]),
          txnHash: item.objId[2],
        },
      })}
      {...rowItemDetailProps}
      // state={item.state}
    />
  );
};

const HiraethCartItemRow: FC<CartItemRowProps> = ({ item, checkoutItem }) => {
  const rowItemDetailProps = useHiraethCheckoutRowItemDetailProps(item);
  return (
    <BaseCartItemRow
      href={route({
        pathname: '/hiraeth/[chainNetwork]/[txnHash]',
        query: {
          chainNetwork: getChainNetwork(item.objId[1]),
          txnHash: item.objId[2],
        },
      })}
      {...rowItemDetailProps}
      cartItem={item}
      checkoutItem={checkoutItem}
    />
  );
};

export const HiraethCartItem = {
  DropdownRow: HiraethDropdownCartItemRow,
  Row: HiraethCartItemRow,
};
