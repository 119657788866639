import { baseSpanCss } from '~src/components/text/spans';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';

export const CheckoutPriceUnit = styled('span', {
  base: css.raw(baseSpanCss, {
    fontSize: 'p.display.caption',
    opacity: '30',
  }),
});
export const CheckoutRowStatusBadge = styled('div', {
  base: {
    borderRadius: 'innerOfSquaredButton',
    padding: 'threeQuartersIncrement',
    width: 'fit-content',
    textAlign: 'right',
    fontSize: 'p.display.caption',
  },
});
export const CheckoutRowValue = styled('p', {
  base: {
    fontSize: 'p.display',
    lineHeight: 'singleLine',
    fontWeight: 'display.bold',
  },
});
