import { FC } from 'react';
import {
  DropdownItemA,
  DropdownItemGroup,
  DropdownItemGroupGrid,
  DropdownLabel,
  dropdownItemCss,
  dropdownItemGroupCss,
} from '~src/components/dropdown';
import { Italic, Strong, WeaklyLightenSpan } from '~src/components/text/spans';

import { Portal } from '@ark-ui/react';
import { Pen } from 'lucide-react';
import { DiscordIcon } from '~src/components/icon/discord';
import { FarcasterIcon } from '~src/components/icon/farcaster';
import { TwitterIcon } from '~src/components/icon/twitter';
import { YoutubeIcon } from '~src/components/icon/youtube';
import { Popover } from '~src/components/popover';
import { P } from '~src/components/text';
import {
  DISCORD_LINK,
  NEWSLETTER_LINK,
  TWITTER_LINK,
  WARPCAST_LINK,
  YOUTUBE_LINK,
} from '~src/constants';
import { css } from '~src/styled-system/css';
import { HStack, styled } from '~src/styled-system/jsx';
import { padding } from '~src/theme/tokens/padding';
import { route } from '~src/utils/routes';
import { navIslandButtonBaseCss } from '../island';
import { TopMobileMenuIsland } from './mobile';

// export const TopRowCenter: FC<{
//   openFeatures?: () => void;
//   isFeaturesOpen?: boolean;
// }> = ({ openFeatures, isFeaturesOpen }) => {
//   const [actionIndex, setActionIndex] = useState(0);

//   useInterval(() => {
//     setActionIndex((t) => (t + 1) % ACTIONS.length);
//   }, 5000);

//   return (
//     <AnimatePresence initial={false} mode={'wait'}>
//       <TopRowCenterContainer
//         as={motion.div}
//         key={`top-row-center-${actionIndex}`}
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.3 } }}
//         exit={{ opacity: 0, transition: { duration: 0.3 } }}
//       >
//         {
//         match(ACTIONS[actionIndex])
//           .with('onchain-summer', () => (
//             <TopRowButton
//               onClick={() => {
//                 openFeatures?.();
//               }}
//             >
//               Experience your most notable history
//               <ChevronDownIcon />
//             </TopRowButton>
//           ))
//           .with('claim-hiraeth', () => (
//             <TopRowButton
//               onClick={() => {
//                 openFeatures?.();
//               }}
//             >
//               Claim your Hiraeth
//               <ChevronDownIcon />
//             </TopRowButton>
//           ))
//           .with('search-blockverse', () => (
//             <TopRowButton
//               onClick={() => {
//                 openFeatures?.();
//               }}
//             >
//               Search the Blockverse
//               <ChevronDownIcon />
//             </TopRowButton>
//           ))
//           .exhaustive()}
//       </TopRowCenterContainer>
//     </AnimatePresence>
//   );
// };

// const TopRowButton = styled(BaseButton)`
//   color: ${(p) => p.theme.white};
//   padding: 6px 10px 6px 14px;
//   font-size: 12px;
//   border-radius: ${(p) => p.theme.pillBorderRadius}px;
//   ${LIGHTEN_HOVER_STYLES};
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   align-items: center;

//   > svg {
//     width: 18px;
//     height: 18px;
//     margin-left: 4px;
//     * {
//       fill: ${(p) => p.theme.white};
//     }
//   }
// `;

// const TopRowCenterContainer = styled(FlexCenterColumn)`
//   ${ABSOLUTE_COVER_STYLES};
//   margin: auto;
//   height: fit-content;
//   width: fit-content;
// `;

export const TopStudioIsland: FC = () => {
  return (
    <Popover.Root
      lazyMount
      unmountOnExit
      portalled
      positioning={{
        placement: 'bottom-start',
        offset: { mainAxis: padding.box },
      }}
    >
      <Popover.Trigger asChild>
        <TopNavIslandButton hideBelow={'sm'}>Studio</TopNavIslandButton>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content>
            <DropdownItemGroupGrid
              minWidth={'[200px]'}
              gridTemplateColumns={'2'}
              gridColumnGap={'zero'}
            >
              <DropdownItemGroup roundedRight={'zero'}>
                <DropdownLabel>Studio</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/about',
                  })}
                  key={'about'}
                >
                  About
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/vision',
                  })}
                  key={'vision'}
                >
                  Vision
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/work',
                  })}
                  key={'works'}
                >
                  Works
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup roundedLeft={'zero'}>
                <DropdownLabel>Learn</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/collector-std',
                  })}
                  key={'collector'}
                >
                  Collector Std.
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/learn/contract-art',
                  })}
                  key={'contract-art'}
                >
                  Contract Art
                </DropdownItemA>
              </DropdownItemGroup>
              <DropdownItemGroup gridColumn={'[span 2]'}>
                <DropdownLabel>Resources</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/learn/metadata-cheat-sheet',
                  })}
                  key={'nft-metadata'}
                >
                  NFT Metadata
                </DropdownItemA>
                <DropdownItemA
                  href={route({
                    pathname: '/contracts',
                  })}
                  key={'contracts'}
                >
                  Contracts
                </DropdownItemA>
              </DropdownItemGroup>
            </DropdownItemGroupGrid>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};

export const TopCommunityIsland: FC = () => {
  return (
    <Popover.Root
      lazyMount
      unmountOnExit
      portalled
      positioning={{
        placement: 'bottom-start',
        offset: { mainAxis: padding.box },
      }}
    >
      <Popover.Trigger asChild>
        <TopNavIslandButton hideBelow={'sm'}>Community</TopNavIslandButton>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content>
            <DropdownItemGroupGrid style={{ minWidth: 160 }}>
              <DropdownItemGroup>
                <DropdownLabel>Outreach</DropdownLabel>
                <DropdownItemA
                  href={route({
                    pathname: '/preserve',
                  })}
                  key={'preserve'}
                >
                  Preserve Initiative
                </DropdownItemA>
                <DropdownItemA
                  target={'_blank'}
                  href={NEWSLETTER_LINK}
                  key={'newsletter'}
                >
                  Newsletter
                </DropdownItemA>
              </DropdownItemGroup>
              <SocialDropdownItemGroup>
                <SocialDropdownActionItem
                  href={TWITTER_LINK}
                  key={'twitter'}
                  target={'_blank'}
                >
                  <TwitterIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'warpcast'}
                  href={WARPCAST_LINK}
                  target={'_blank'}
                >
                  <FarcasterIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'youtube'}
                  href={YOUTUBE_LINK}
                  target={'_blank'}
                >
                  <YoutubeIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
                <SocialDropdownActionItem
                  key={'discord'}
                  href={DISCORD_LINK}
                  target={'_blank'}
                >
                  <DiscordIcon className={socialDropdownActionItemIcon} />
                </SocialDropdownActionItem>
              </SocialDropdownItemGroup>
            </DropdownItemGroupGrid>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};

export const TopHeaderLeft: FC = () => {
  return (
    <HStack gap={'largeBox'}>
      <TopMobileMenuIsland />
      <TopStudioIsland />
      <TopCommunityIsland />
      {/* <TopMobileMenuIsland />
       */}
    </HStack>
  );
};

export const TopHeaderRight: FC = () => {
  return (
    <TopHeaderRightContainer hideBelow={'sm'}>
      <Pen size={16} className={css({ color: 'white/60' })} />
      <P textStyle={'p.display.caption'} color={'white'}>
        <Strong>Crafting</Strong>{' '}
        <WeaklyLightenSpan opacity={'80'}>
          immersive NFTs since <Italic>2020</Italic>
        </WeaklyLightenSpan>
      </P>
    </TopHeaderRightContainer>
  );
};

export const TopNavIslandButton = styled('button', {
  base: css.raw(navIslandButtonBaseCss, {
    color: 'white',
    py: 'increment',
    px: 'zero',
    tran: 'default',
    transitionProperty: ['transform', 'color'],
    _hover: {
      transform: 'scale(1.05)',
      color: 'semiTransparentLighten',
    },
    _expanded: {
      transform: 'scale(1)',
      color: 'semiTransparentLighten',
    },
  }),
});

// const ChainImgContainer = styled('div', {
//   base: {
//     height: 'box',
//     width: 'box',
//     rounded: 'pill',
//     overflow: 'hidden',
//     pos: 'relative',
//   },
// });

export const SocialDropdownItemGroup = styled('div', {
  base: css.raw(dropdownItemGroupCss, {
    display: 'grid',
    gridTemplateColumns: '4',
  }),
});

export const SocialDropdownActionItem = styled('a', {
  base: css.raw(dropdownItemCss, {
    flx: 'flexCenter',
    width: '[unset]',
  }),
});

export const socialDropdownActionItemIcon = css({
  width: 'icon.twelve',
  height: 'icon.twelve',
  display: 'block',
});

const TopHeaderRightContainer = styled('div', {
  base: {
    gap: 'increment',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});
