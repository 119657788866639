import { useWallets, type Wallet } from '@privy-io/react-auth';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import { isEqAddress } from '~src/utils/bytes/isEqAddress';
import { useBestWalletForPrivyUser } from './useWalletFromPrivy';

export const useIsActiveWalletBestWalletForPrivyUser = () => {
  const wallet = useBestWalletForPrivyUser();
  return useIsWalletActive(wallet);
};

export const useIsWalletActive = (wallet: Wallet | undefined) => {
  const { address } = useAccount();
  return useMemo(
    () => {
      if (!wallet) {
        return undefined;
      }
      if (!address) {
        return undefined;
      }
      return isEqAddress(wallet.address, address);
    },
    [wallet?.address, address],
  );
};

/**
 * Check if a wallet is activable (i.e the current dapp can simply activate it (no extension prompt etc))
 * @param wallet
 * @returns
 */
export const useIsWalletActivable = (wallet: Wallet | undefined) => {
  const { wallets } = useWallets();
  return useMemo(() => {
    return wallets?.some((w) => isEqAddress(w.address, wallet?.address));
  }, [wallets, wallet]);
};
