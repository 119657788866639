import {
  BlockverseProjectId,
  supportedBlockverseChainZod,
  type SupportedBlockverseChainId,
} from '@pob/blockverse';
import type { ChainRuntime, Evm } from '@pob/shared';
import { evmTxnHashZod } from '@pob/shared';
import type { AvailableChainId, LookUpByChainId } from '@protocol/chains';
import { z } from 'zod';

export type BlockverseMultiProjectAndChainMap<T> = {
  [project in BlockverseProjectId]: LookUpByChainId<T, ChainRuntime, true>;
};

export type BlockverseMultiProjectMap<T> = {
  [project in BlockverseProjectId]: T;
};

export type BlockverseProjectDomain<
  E extends BlockverseProjectId = BlockverseProjectId,
> = [E, AvailableChainId];

export type BaseBlockverseObjId<
  E extends BlockverseProjectId = BlockverseProjectId,
  AdditiveParams extends any[] = any[],
> = [...BlockverseProjectDomain<E>, ...AdditiveParams];

export type BlockverseObjIdGetter<
  E extends BlockverseProjectId,
  AdditiveParams extends any[] = any[],
> = (
  chainId: AvailableChainId,
  ...params: AdditiveParams
) => BaseBlockverseObjId<E, AdditiveParams>;

export const BLOCKVERSE_OBJ_ID_GETTERS = {
  hiraeth: (chainId: AvailableChainId, txnHash: Evm.TxnHash) => [
    'hiraeth',
    chainId as SupportedBlockverseChainId<'hiraeth'>,
    txnHash,
  ],
  hash: null,
  exeo: null,
} as const satisfies {
  [project in BlockverseProjectId]: BlockverseObjIdGetter<project> | null;
};

export const blockverseObjIdProjectZod = {
  hiraeth: z.tuple([
    z.literal('hiraeth'),
    supportedBlockverseChainZod.shape.hiraeth,
    evmTxnHashZod,
  ]),
  exeo: z.never(),
  hash: z.never(),
} as const satisfies {
  [project in BlockverseProjectId]: (typeof BLOCKVERSE_OBJ_ID_GETTERS)[project] extends null
    ? z.ZodNever
    : z.ZodSchema;
};

// TODO: update this to be a union of all schemas
export const blockverseObjIdZod = blockverseObjIdProjectZod.hiraeth;

export type BlockverseObjId<
  E extends BlockverseProjectId = BlockverseProjectId,
> = (typeof BLOCKVERSE_OBJ_ID_GETTERS)[E] extends null
  ? never
  : ReturnType<NonNullable<(typeof BLOCKVERSE_OBJ_ID_GETTERS)[E]>>;

export type BlockverseObjIdGetterParams<E extends BlockverseProjectId> =
  (typeof BLOCKVERSE_OBJ_ID_GETTERS)[E] extends null
    ? never
    : Parameters<NonNullable<(typeof BLOCKVERSE_OBJ_ID_GETTERS)[E]>>;
