import type { Evm } from '@pob/shared';
import { useMemo } from 'react';
import { base, mainnet } from 'viem/chains';
import { useEnsName, useReadContract } from 'wagmi';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import { getUniversalUserDisplayName } from '~src/external/universal/utils/user';
import { ADDRESS_REGEX } from '~src/utils/regex';
import { L2ResolverAbi } from '../../constants/abis/L2ResolverAbi';
import { getShortenedAddress } from '../../utils/bytes/shorten';
import {
  RESOLVER_ADDRESSES_BY_CHAIN_ID,
  convertReverseNodeToBytes,
} from '../../utils/name/getName';

export const useBestDisplayName = (address: Evm.Address | undefined) => {
  // const userEnsName = useEnsName({
  //   address,
  //   chainId: mainnet.id,
  // });
  // const userBaseName = useBaseName(address);

  const ensUserRes = trpc.user.ens.byAddress.useQuery(
    {
      address: address!,
    },
    { ...QUERY_LIVENESS.constant, enabled: !!address },
  );

  const universalHandleRes = useUniversalHandle(address);
  // always prefer ens
  // if (!!userBaseName.data) {
  //   return userBaseName.data;
  // }
  // if (userEnsName.data) {
  //   return userEnsName.data;
  // }
  if (universalHandleRes.data) {
    return universalHandleRes.data;
  }
  if (ensUserRes.data?.ensName) {
    return ensUserRes.data.ensName;
  }
  return getShortenedAddress(address ?? '');
};

// const useBaseName = (address: Evm.Address | undefined) => {
//   const addressReverseNode = useMemo(
//     () => (!!address ? convertReverseNodeToBytes(address, base.id) : undefined),
//     [address],
//   );
//   const resolverRes = useReadContract({
//     chainId: base.id,
//     address: RESOLVER_ADDRESSES_BY_CHAIN_ID[base.id],
//     abi: L2ResolverAbi,
//     functionName: 'name',
//     args: [addressReverseNode ?? '0x00'],
//     query: {
//       enabled: !!address,
//     },
//   });
//   return resolverRes;
// };

const useUniversalHandle = (address: Evm.Address | undefined) => {
  const universalHandleRes = trpc.user.universal.byAddress.useQuery(
    {
      address: address!,
    },
    {
      enabled: !!address && ADDRESS_REGEX.test(address),
      select: (data) => (!!data ? getUniversalUserDisplayName(data) : null),
      ...QUERY_LIVENESS.constant,
    },
  );
  return universalHandleRes;
};
