import { useMemo } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import { getUniversalUserDisplayName } from '~src/external/universal/utils/user';
import type { User } from '~src/shared-apps/social/types/user';
import { useBestExtAccountTypeForUser } from './useBestExtAccountTypeForUser';
import { useBestDisplayName } from '../address/useBestDisplayName';

export const useBestUserDisplayName = (user?: User) => {
  const extAccountType = useBestExtAccountTypeForUser(user);

  const ensName = useBestDisplayName(
    extAccountType === 'wallet'
      ? user?.linkedWalletAccounts?.[0]?.address
      : undefined,
  );

  return useMemo(() => {
    if (
      extAccountType === 'universal-profile' &&
      user?.linkedUniversalProfileAccounts?.[0]?.name
    ) {
      return `@${user.linkedUniversalProfileAccounts[0]?.name}`;
    }
    if (extAccountType === 'farcaster') {
      return `@${user?.linkedFarcasterAccount?.username}`;
    }
    if (extAccountType === 'wallet') {
      return user?.linkedWalletAccounts?.[0]?.ensName ?? ensName;
    }
    return undefined;
  }, [extAccountType, user, ensName]);
};

export const useUniversalUserDisplayName = (metadata?: {
  address: string;
  name: string;
}) => {
  if (!metadata) {
    return undefined;
  }
  return getUniversalUserDisplayName(metadata);
};
