import { AvailableChainId } from '@protocol/chains';
import { ProjectId } from '@protocol/deployments';
import type { ValueOf } from 'type-fest';
import { base, baseSepolia, lukso, mainnet, optimism } from 'viem/chains';

// Checkout chains refer to the cart system

export type CartProjectConfig = {
  isAddingToCartAllowed: boolean;
};

export const CART_PROJECT_CONFIG = {
  // hash: {
  //   isAddingToCartAllowed: false,
  // },
  hiraeth: {
    isAddingToCartAllowed: true,
  },
} as const satisfies {
  [project in ProjectId]?: CartProjectConfig;
};

export type SupportedCartProjectId = keyof typeof CART_PROJECT_CONFIG;

export const PROJECT_SUPPORTED_CART_CHAINS = {
  // evenOdd: null,
  // merge: null,
  // piano: null,
  // london: null,
  // hash: null,
  // exeo: null,
  // example: null,
  // exampleWithIpfs: null,
  // dead: null,
  hiraeth: {
    [base.id]: base.id,
    [baseSepolia.id]: baseSepolia.id,
    [mainnet.id]: mainnet.id,
    [optimism.id]: optimism.id,
    [lukso.id]: lukso.id,
  },
} as const satisfies Record<
  SupportedCartProjectId,
  Record<number, AvailableChainId> | null
>;

export type SupportedCartChainId<TProjectId extends SupportedCartProjectId> =
  ValueOf<(typeof PROJECT_SUPPORTED_CART_CHAINS)[TProjectId]>;

export type GlobalSupportedCartChainId = ValueOf<
  (typeof PROJECT_SUPPORTED_CART_CHAINS)[keyof typeof PROJECT_SUPPORTED_CART_CHAINS]
>;
