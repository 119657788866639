// import type { SupportedCheckoutProjectId } from '../configs/support';

import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type { SupportedCheckoutProjectId } from '../../../config/checkout';

export type TransientCheckoutItemStateQueryKey<
  P extends SupportedCheckoutProjectId,
> = ['checkout-state', BlockverseObjId<P>];

// export type EphemeralCheckoutItemStateQueryKeyGetter = (
//   chainId: AvailableChainId,
//   id: string,
// ) => EphemeralCheckoutItemStateQueryKey;

// type UnknownArrayOrTuple = readonly [...unknown[]];
// type QueryKeyTail<TArray extends UnknownArrayOrTuple> =
//   TArray extends readonly [unknown, ...infer Tail] ? Tail : [];

// /**
//  * Ephemeral checkout states are state changes done locally and via queryClient
//  * we inform consumers of checkout state to have more up to date information.
//  */
// export const ITEM_EPHEMERAL_CHECKOUT_STATE_QUERY_KEY_GETTERS = {
//   hiraeth: (chainId: AvailableChainId, txnHash: string) => [
//     'hiraeth',
//     chainId,
//     txnHash,
//   ],
// } as const satisfies {
//   [project in SupportedCheckoutProjectId]: EphemeralCheckoutItemStateQueryKeyGetter | null;
// };

// export type EphemeralCheckoutItemQueryKeyAdditiveParams<
//   E extends SupportedCheckoutProjectId,
// > = (typeof ITEM_EPHEMERAL_CHECKOUT_STATE_QUERY_KEY_GETTERS)[E] extends null
//   ? never
//   : QueryKeyTail<
//       Parameters<
//         NonNullable<(typeof ITEM_EPHEMERAL_CHECKOUT_STATE_QUERY_KEY_GETTERS)[E]>
//       >
//     >;

export const getTransientCheckoutItemStateQueryKey = <
  P extends SupportedCheckoutProjectId,
>(
  objId: BlockverseObjId<P>,
): TransientCheckoutItemStateQueryKey<P> => {
  return ['checkout-state', objId] as const;
};
