import { Portal } from '@ark-ui/react';
import { ChevronRight } from 'lucide-react';
import type { StaticImageData } from 'next/image';
import { FC, type ReactNode } from 'react';
import HiraethImg from '~public//imgs/hiraeth/icon.png';
import ExeoImg from '~public//imgs/terrene/icon.png';
import { minorButtonCss } from '~src/components/button';
import {
  DropdownItemGroup,
  DropdownItemGroupGrid,
  DropdownLabel,
  DropdownLineSeparator,
  dropdownItemCss,
} from '~src/components/dropdown';
import { Img } from '~src/components/img';
import { Popover } from '~src/components/popover';
import { P } from '~src/components/text';
import { Pill } from '~src/components/text/pill';
import { InheritSpan, Italic, SuperStrong } from '~src/components/text/spans';
import { PROJECT_METADATA } from '~src/meta/project/metadata';
import { css } from '~src/styled-system/css';
import { HStack, VStack, styled } from '~src/styled-system/jsx';
import { projectStateRecipe } from '~src/theme/variants/project';
import type { ProjectState } from '~src/types/project/metadata';
import { route } from '~src/utils/routes';
import { deSluggify } from '~src/utils/strings/deSluggify';
import { AvailableChains } from '../blockverse/chains';
// const ExploreButton: FC = () => {
//   return (
//     <TooltipWrapper
//       delay={50}
//       closeDelay={100}
//       $openDirection="left"
//       tooltip={
//         <DisplayMedP
//           style={{ lineHeight: '1rem', whiteSpace: 'nowrap' }}
//           color={'gray.600'}
//         >
//           Coming Soon
//         </DisplayMedP>
//       }
//     >
//       {(ref, triggerProps) => (
//         <NavAnchorIslandButton
//           ref={ref}
//           style={{ opacity: 0.5 }}
//           {...triggerProps}
//         >
//           Explore
//         </NavAnchorIslandButton>
//       )}
//     </TooltipWrapper>
//   );
// };

export const BlockverseDropdown: FC = () => {
  return (
    <Popover.Root
      lazyMount
      unmountOnExit
      positioning={{
        placement: 'bottom-start',
      }}
      portalled
    >
      <Popover.Trigger asChild>
        <NavIslandButton bg={'darken.025'} color={'gray.800'}>
          <InheritSpan display={'block'} nudge={'y'} y={'nudge'}>
            Blockverse
          </InheritSpan>
        </NavIslandButton>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content style={{ minWidth: 300 }}>
            <DropdownItemGroupGrid>
              <DropdownItemGroup>
                <DropdownLabel
                  px={'[calc({spacing.halfIncrement} + {spacing.box})]'}
                  fontSize={'p.display.caption'}
                  color={'gray.600'}
                >
                  Projects
                </DropdownLabel>
                <ProjectDropdownItem
                  family={'Terrene'}
                  name={'Hiraeth'}
                  href={route({
                    pathname: '/hiraeth',
                  })}
                  imgSrc={HiraethImg}
                  projectState={PROJECT_METADATA.hiraeth.state}
                  description={
                    <>
                      <SuperStrong>1 of 1 NFTs</SuperStrong> generated <br />{' '}
                      from transactions.
                    </>
                  }
                />
                <DropdownLineSeparator />
                <ProjectDropdownItem
                  imgSrc={ExeoImg}
                  family={'Terrene'}
                  name={'Exeo'}
                  href={route({
                    pathname: '/exeo',
                  })}
                  // imgSrc={HiraethImg}
                  projectState={PROJECT_METADATA.exeo.state}
                  description={
                    <>
                      <SuperStrong>Dynamic NFTs</SuperStrong> generated <br />{' '}
                      from NFT owners.
                    </>
                  }
                />
              </DropdownItemGroup>
            </DropdownItemGroupGrid>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};

const ProjectDropdownItem: FC<{
  family?: string;
  name: string;
  href: string;
  imgSrc: string | StaticImageData;
  description: ReactNode;
  projectState?: ProjectState;
  pillText?: string;
}> = ({ name, href, imgSrc, family, projectState, description, pillText }) => {
  return (
    <ProjectDropdownItemA href={href} key="hiraeth">
      <HStack gap={'innerBox'}>
        <ProjectDropdownImgContainer>
          <Img src={imgSrc} alt={`Image of ${name}`} fill />
        </ProjectDropdownImgContainer>
        <VStack gap={'halfIncrement'} alignItems={'flex-start'}>
          <P
            fontSize={'h4-5'}
            lineHeight={'singleLine'}
            fontWeight={'display.bold'}
            color={'black'}
            textStyle={'p.display.title'}
          >
            <Italic color={'gray.600'}>{family}</Italic> {name}
          </P>
          <P lineHeight={'[1.2]'} fontSize={'p.display.caption'}>
            {description}
          </P>
        </VStack>
      </HStack>
      <HStack gap={'halfIncrement'}>
        <Pill className={projectStateRecipe({ state: projectState })}>
          {pillText ?? (!!projectState ? deSluggify(projectState) : undefined)}
        </Pill>
        <ChevronRight size={16} />
      </HStack>
    </ProjectDropdownItemA>
  );
};

export const MainNavIsland: FC = ({}) => {
  return (
    <MainNavIslandContainer>
      <BlockverseDropdown key="blockverse" />
      <HStack
        // bg={'darken'}
        // px={'increment'}
        // py={'increment'}
        // pl={'innerBox'}
        ml={'increment'}
        // rounded={'pill'}
      >
        <AvailableChains />
      </HStack>
    </MainNavIslandContainer>
  );
};

export const navIslandButtonBaseCss = css.raw(minorButtonCss, {
  fontWeight: 'display.normal',
  py: '[7.5px]',
  px: '[14px]',
  color: 'gray.600',
  background: 'transparent',
  tran: '200ms',
  transitionProperty: '[color, background]',
  rounded: 'squaredButton',
  _expanded: {
    color: 'black',
    opacity: '100',
    background: 'darken',
  },
});

export const NavIslandButton = styled('button', {
  base: css.raw(navIslandButtonBaseCss, {
    hoverOverlay: 'darken',
    _hover: {
      color: 'black',
    },
  }),
});

const MainNavIslandContainer = styled('div', {
  base: {
    flx: 'flexRow',
    gap: 'increment',
    // padding: 'halfIncrement',
    hideBelow: 'sm',
  },
});

const ProjectDropdownItemA = styled('a', {
  base: css.raw(dropdownItemCss, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 'innerBox',
    px: 'box',
    py: 'innerBox',
    pr: 'increment',
    _hover: {
      color: 'gray.600',
    },
  }),
});

const ProjectDropdownImgContainer = styled('div', {
  base: {
    height: '[42px]',
    width: '[42px]',
    imageRendering: 'pixelated',
    aspectRatio: '1:1',
    overflow: 'hidden',
    rounded: 'innerOfInnerBox',
    shadow: 'soft',
    position: 'relative',
  },
});
