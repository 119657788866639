/**
 * Values constants
 */

export const CONTEXT_DEFAULT_FUNCTION = () => {
  throw new Error('Context not set.');
};

export const CONTEXT_DEFAULT_OBJ: any = {
  YOU_SHOULD_NOT_BE_SEEING_THIS: 'YOU_SHOULD_NOT_BE_SEEING_THIS',
};
