import { useMemo } from 'react';
import type { User } from '~src/shared-apps/social/types/user';

export const useBestExtAccountTypeForUser = (user?: User) => {
  return useMemo(() => {
    if (!user) {
      return undefined;
    }

    if (
      !!user.linkedUniversalProfileAccounts &&
      user.linkedUniversalProfileAccounts.length > 0
    ) {
      return 'universal-profile';
    }

    if (!!user.linkedFarcasterAccount) {
      return 'farcaster';
    }

    const containsWallet = user.linkedWalletAccounts.length > 0;
    if (containsWallet) {
      return 'wallet';
    }

    return null;
  }, [user]);
};
