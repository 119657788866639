import { NULL_ADDRESS } from '@pob/shared';
import type { AvailableChainId } from '@protocol/chains';
import { FC, useMemo } from 'react';
import { match } from 'ts-pattern';
import { lukso } from 'viem/chains';
import { useAccount } from 'wagmi';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { SPANS_BY_CHAIN_ID } from '~src/components/chains/spans';
import { ImgContainer } from '~src/components/img';
import {
  TooltipBox,
  TooltipBoxGrid,
  TooltipLineSeparator,
  TooltipP,
} from '~src/components/tooltip';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import { useBestExtAccountTypeFromPrivy } from '~src/hooks/privy/useBestExtAccountTypeFromPrivy';
import { useFarcasterUserFromPrivy } from '~src/hooks/privy/useFarcasterUserFromPrivy';
import { useUniversalUserFromPrivy } from '~src/hooks/privy/useUniversalUserFromPrivy';
import { useUniversalUserDisplayName } from '~src/hooks/user/useBestUserDisplayName';
import { useBestWalletForPrivyUser } from '~src/hooks/wallet/useWalletFromPrivy';
import { Flex } from '~src/styled-system/jsx';
import { getShortenedAddress } from '~src/utils/bytes/shorten';

export const ConnectedTooltipState: FC = ({}) => {
  const { chain: chainFromWagmi, connector } = useAccount();

  const wallet = useBestWalletForPrivyUser();
  const displayName = useBestDisplayName(wallet?.address);

  const shouldHideDisplayName = useMemo(() => {
    return displayName === getShortenedAddress(wallet?.address ?? '');
  }, [displayName, wallet?.address]);

  const farcasterUser = useFarcasterUserFromPrivy();

  const bestUserLinkedAccountType = useBestExtAccountTypeFromPrivy();

  // a bit hacky for lukso wallet but this will get us a chain
  const chain = useMemo(() => {
    if (connector?.id === 'cloud.universalprofile') {
      return lukso;
    }
    return chainFromWagmi;
  }, [chainFromWagmi, connector]);

  return (
    <TooltipBoxGrid>
      <TooltipBox>
        {match(bestUserLinkedAccountType)
          .with('wallet', () => (
            <>
              {!shouldHideDisplayName && (
                <TooltipP
                  textAlign={'right'}
                  mb={'halfIncrement'}
                  textCutoff={'oneLineInFlex'}
                  lineHeight={'[1.1]'}
                >
                  {displayName}
                </TooltipP>
              )}
              <TooltipP
                textAlign={'right'}
                color={'gray.400'}
                lineHeight={'[1.1]'}
              >
                {getShortenedAddress(wallet?.address ?? NULL_ADDRESS)}
              </TooltipP>
            </>
          ))
          .with('universal-profile', () => <UniversalConnectedTooltipState />)
          .with('farcaster', () => (
            <TooltipP
              textAlign={'right'}
              mb={'halfIncrement'}
              textCutoff={'oneLineInFlex'}
            >
              @{farcasterUser?.username}
            </TooltipP>
          ))
          .otherwise(() => null)}
        {!!chain && (
          <>
            <TooltipLineSeparator />
            <Flex justify={'flex-end'} align={'center'} gap={'halfIncrement'}>
              <ImgContainer
                rounded={'pill'}
                position={'relative'}
                height={'innerBox'}
                width={'innerBox'}
                shadow={'none'}
              >
                {ICONS_BY_CHAIN_IDS[chain!.id as AvailableChainId]}
              </ImgContainer>
              <TooltipP textAlign={'right'} textCutoff={'oneLineInFlex'}>
                {SPANS_BY_CHAIN_ID[chain!.id as AvailableChainId]}
              </TooltipP>
            </Flex>
          </>
        )}
        {/** @todo: remove this once we have a custom icon for the Privy Wallet */}
        {/* {!!connector && (
          <Flex
            justify={'flex-end'}
            align={'center'}
            gap={'halfIncrement'}
            mt={'threeQuartersIncrement'}
          >
            {connector.icon && (
              <ImgContainer
                overflow={'[unset]'}
                position={'relative'}
                height={'innerBox'}
                width={'innerBox'}
                shadow={'none'}
              >
                <SimpleImg src={connector?.icon} alt={connector?.name} />
              </ImgContainer>
            )}
            <TooltipP textAlign={'right'} textCutoff={'oneLineInFlex'}>
              {connector?.name}
            </TooltipP>
          </Flex>
        )} */}
      </TooltipBox>
    </TooltipBoxGrid>
  );
};

const UniversalConnectedTooltipState: FC = () => {
  const universalUser = useUniversalUserFromPrivy();
  const displayName = useUniversalUserDisplayName(universalUser ?? undefined);
  if (!universalUser) {
    return null;
  }
  return (
    <TooltipP textAlign={'right'} textCutoff={'oneLineInFlex'}>
      {displayName}
    </TooltipP>
  );
};
