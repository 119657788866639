import { HiraethMetadata } from '@hiraeth/art';
import type { Evm } from '@pob/shared';
import { NULL_BYTES } from '@pob/shared';
import { type QueryObserverResult } from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';
import superjson from 'superjson';
import { trpc } from '~src/clients/trpc-client';
import { CONTEXT_DEFAULT_OBJ } from '~src/constants/defaults';
import { QUERY_LIVENESS } from '~src/constants/query';
import { usePobWallet } from '~src/hooks/address/useAdmin';
import { useGetFetch } from '~src/hooks/fetch/useGetFetch';
import { useBestWalletForPrivyUser } from '~src/hooks/wallet/useWalletFromPrivy';
import type { RouterOutputs } from '~src/server/trpc/routers';
import {
  BLOCKVERSE_OBJ_ID_GETTERS,
  type BlockverseObjId,
} from '~src/shared-apps/blockverse/types';
import { usePreferredChainIdIfCartSupported } from '~src/shared-apps/cart/hooks/useSupportedCartChainId';
import { useBlockverseContextsFromObject } from '~src/shared-apps/context/hooks/useBlockverseContextsFromObject';
import { type BlockverseContextWithId } from '~src/shared-apps/context/types';
import type { User } from '~src/shared-apps/social/types/user';
import type { UseTRPCQueryReturn } from '~src/types/common';
import { isEqAddress } from '~src/utils/bytes/isEqAddress';
import { getChainNetwork } from '~src/utils/chain';
import { route } from '~src/utils/routes';
import { useHiraethBestTitle } from '../hooks/useHiraethBestTitle';
import { useHiraethItemCheckoutState } from '../hooks/useHiraethItemCheckoutState';
import { useHiraethObservation } from '../hooks/useHiraethObservation';
import { isChainLegacyWithHash } from '../utils/isChainLegacyWithHash';
import { EmptyHiraethHashProvider, HiraethHashProvider } from './HiraethHash';

type HiraethContextType = {
  txnHash: Evm.TxnHash;

  owner: Evm.Address | null | undefined;
  tokenId: bigint | null | undefined;
  isViewerOwnerOrAdmin: boolean;

  title: string;
  metadata: HiraethMetadata | undefined;
  contexts: BlockverseContextWithId[] | null;

  metadataRes: QueryObserverResult<HiraethMetadata>;
  contentMetadataRes: UseTRPCQueryReturn<
    RouterOutputs['hiraeth']['txn']['metadata']
  >;
  // setting to unknown to avoid using the data here
  hiraethStatesRes: UseTRPCQueryReturn<unknown>;
  observer: User | undefined;
  observedAt: Date | undefined;

  blockverseObjId: BlockverseObjId<'hiraeth'>;
};

export const HiraethContext = createContext<HiraethContextType>({
  txnHash: NULL_BYTES as Evm.TxnHash,
  owner: undefined,
  isViewerOwnerOrAdmin: false,
  metadata: undefined,
  contexts: null,
  title: '',
  tokenId: undefined,
  metadataRes: CONTEXT_DEFAULT_OBJ,
  contentMetadataRes: CONTEXT_DEFAULT_OBJ,
  hiraethStatesRes: CONTEXT_DEFAULT_OBJ,
  observer: undefined,
  observedAt: undefined,
  blockverseObjId: CONTEXT_DEFAULT_OBJ,
});

export type HiraethProviderProps = {
  txnHash: Evm.TxnHash;
  observable?: boolean;
  // shouldDisableMetadataRefresh?: boolean;
};

export const useHiraeth = () => {
  const context = useContext(HiraethContext);
  if (!context) {
    throw new Error('useHiraeth must be used within a HiraethProvider');
  }
  return context;
};

export const HiraethProvider = ({
  txnHash,
  children,
  observable = false,
  // metadata: providedMetadata,
  // shouldDisableMetadataRefresh,
  // contexts,
}: HiraethProviderProps & { children: React.ReactNode }) => {
  const chainId = usePreferredChainIdIfCartSupported('hiraeth')!;

  const { hiraethStatesRes } = useHiraethObservation(
    chainId,
    useMemo(() => [txnHash], [txnHash]),
    {
      observable,
    },
  );

  const [observer, observedAt] = useMemo(() => {
    if (!hiraethStatesRes.data) {
      return [undefined, undefined];
    }
    if (!hiraethStatesRes.data[txnHash]) {
      return [undefined, undefined];
    }
    return [
      hiraethStatesRes.data[txnHash].observingUser,
      hiraethStatesRes.data[txnHash].observedAt,
    ];
  }, [hiraethStatesRes, txnHash]);

  const metadataRes = useGetFetch<{}, HiraethMetadata, any>(
    useMemo(() => {
      return route({
        pathname: '/api/hiraeth/[chainNetwork]/[txnHash]/metadata',
        query: {
          chainNetwork: getChainNetwork(chainId),
          txnHash,
        },
      });
    }, [chainId, txnHash]),
    {
      // ...CONSTANT_QUERY_LIVENESS,
      shouldUsePersister: false,
      queryParams: {},
      enabled: !!txnHash && !!chainId,
      select: (res) => {
        return superjson.deserialize<HiraethMetadata>(res);
      },
      retry: (failureCount: number, error: unknown) => {
        return (
          ((error as any).status ?? (error as any).statusCode) !== 404 &&
          failureCount < 3
        );
      },
    },
  );

  const blockverseObjId = BLOCKVERSE_OBJ_ID_GETTERS.hiraeth(chainId, txnHash);

  const contentMetadataRes = trpc.hiraeth.txn.metadata.useQuery(
    {
      blockverseObjId,
    },
    {
      ...QUERY_LIVENESS['current-session'],
    },
  );

  const checkoutState = useHiraethItemCheckoutState(blockverseObjId);

  const owner = useMemo(() => {
    // console.log('checkoutState', checkoutState);
    if (checkoutState?.type === 'minted') {
      return checkoutState.owner;
    }
    return undefined;
  }, [checkoutState]);

  const isPobWallet = usePobWallet();
  const wallet = useBestWalletForPrivyUser();

  const isViewerOwnerOrAdmin = useMemo(
    () => isPobWallet || isEqAddress(owner, wallet?.address),
    [owner, wallet?.address],
  );

  const tokenId = useMemo(() => {
    if (checkoutState?.type === 'minted') {
      return checkoutState.tokenId;
    }
    return undefined;
  }, [checkoutState]);

  const contexts = useBlockverseContextsFromObject('hiraeth', chainId, txnHash);

  const title = useHiraethBestTitle(txnHash);

  const state = useMemo(() => {
    return {
      txnHash,

      owner,
      tokenId,

      title,
      metadata: metadataRes.data,
      contexts: contexts,
      isViewerOwnerOrAdmin,

      metadataRes,
      contentMetadataRes,
      hiraethStatesRes,
      observer,
      observedAt,

      blockverseObjId,
    };
  }, [
    owner,
    txnHash,
    metadataRes,
    title,
    contexts,
    tokenId,
    isViewerOwnerOrAdmin,
    hiraethStatesRes,
    observer,
    observedAt,
    blockverseObjId,
    contentMetadataRes,
  ]);

  const HashOrEmptyProvider = isChainLegacyWithHash(chainId)
    ? HiraethHashProvider
    : EmptyHiraethHashProvider;

  return (
    <HiraethContext.Provider value={state}>
      <HashOrEmptyProvider txnHash={txnHash}>{children}</HashOrEmptyProvider>
    </HiraethContext.Provider>
  );
};
