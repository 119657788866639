import { FC } from 'react';
import UniversalProfileLogo from '~public/imgs/soical/up.png';
import { Box } from '~src/styled-system/jsx';
import { BaseLoadingImg } from '../img';

export const UniversalProfileIcon: FC<{ className?: string }> = ({
  className,
}) => (
  <Box pos={'relative'} className={className}>
    <BaseLoadingImg
      src={UniversalProfileLogo}
      alt="Universal Profile Logo"
      fill
    />
  </Box>
);
