import { usePrivy, useWallets } from '@privy-io/react-auth';
import { LogOut, Settings } from 'lucide-react';
import { FC } from 'react';
import {
  DropdownBottomLabel,
  DropdownItemButton,
  DropdownItemGroup,
} from '~src/components/dropdown';
import { css } from '~src/styled-system/css';
import { useHeader } from '../../providers/HeaderProvider';

import { BaseButton } from '~src/components/button';
import { InheritSpan } from '~src/components/text/spans';
import { useIsActiveWalletBestWalletForPrivyUser } from '~src/hooks/wallet/useIsWalletActive';
import { usePrivyDialogs } from '~src/shared-apps/social/providers/PrivyDialogsProvider';
import { capitalize } from '~src/utils/lodash';
export const MoreDropdownState: FC = () => {
  const { isOpen, setIsOpen } = useHeader();
  const { logout } = usePrivy();
  const { wallets } = useWallets();
  const isLinked = useIsActiveWalletBestWalletForPrivyUser();
  const { open } = usePrivyDialogs();
  return (
    <DropdownItemGroup bg={'white-pane'}>
      {/* <DropdownLineSeparator/> */}
      <UserAuthenticatedSettingsState />
      {/* <DropdownItemButton
                        flx={'flexRowEnds'}
                        onClick={openChainModal}
                      >
                        Switch
                        <Flex
                          justify={'flex-end'}
                          align={'center'}
                          gap={'increment'}
                        >
                          <ImgContainer
                            rounded={'pill'}
                            position={'relative'}
                            height={'box'}
                            width={'box'}
                            shadow={'none'}
                          >
                            {
                              ICONS_BY_CHAIN_NETWORKS[
                                chain!.id as AvailableChainId
                              ]
                            }
                          </ImgContainer>
                          {
                            SPANS_BY_CHAIN_NETWORKS[
                              chain!.id as AvailableChainId
                            ]
                          }
                        </Flex>
                      </DropdownItemButton> */}
      <DropdownItemButton
        key={'disconnect'}
        flx={'flexRowEnds'}
        onClick={() => {
          logout();
          setIsOpen(false);
        }}
        color={'error.strong'}
      >
        Log Out
        <LogOut
          className={css({
            width: 'icon.fourteen',
            height: 'icon.fourteen',
            display: 'block',
            opacity: '60',
          })}
        />
      </DropdownItemButton>
      {isLinked === false && (
        <DropdownBottomLabel fontWeight={'display.normal'}>
          Connected {capitalize(wallets[0]?.walletClientType ?? '')} wallet is
          different to the current in-session user.{' '}
          <BaseButton
            fontSize={'[inherit]'}
            color={'brand'}
            fontWeight={'display.bold'}
            onClick={() => {
              open();
              setIsOpen(false);
            }}
          >
            Fix Now
          </BaseButton>
        </DropdownBottomLabel>
      )}
    </DropdownItemGroup>
  );
};

const UserAuthenticatedSettingsState: FC = () => {
  const { open } = usePrivyDialogs();
  const { close: closeHeader } = useHeader();
  return (
    <DropdownItemButton
      flx={'flexRowEnds'}
      onClick={() => {
        open();
        closeHeader();
      }}
    >
      <InheritSpan>Settings</InheritSpan>
      <Settings
        className={css({
          width: 'icon.fourteen',
          height: 'icon.fourteen',
          display: 'block',
          color: 'gray.400',
        })}
      />
    </DropdownItemButton>
  );
};
