import Link from 'next/link';
import React, { FC } from 'react';
import { baseNavAnchorCss } from '~src/components/anchor';
import {
  ColoredLogoIcon,
  type ColoredLogoTheme,
} from '~src/components/icon/logo';
import { NavigationLoadingProgressBar } from '~src/components/loading';
import { H5 } from '~src/components/text';
import { Italic } from '~src/components/text/spans';
import { Tooltip, tooltipTimingProps } from '~src/components/tooltip';
import { HeaderCartStatus } from '~src/shared-apps/cart/component/dropdown';
import { css } from '~src/styled-system/css';
import { ContentSpacer, styled } from '~src/styled-system/jsx';
import {
  useIsSticky,
  useStickyRef,
} from '../../../components/sticky/StickyProvider';
import { useMounted } from '../../../hooks/ui/useMounted';
import {
  NavRowLeftContainer,
  NavRowRightContainer,
  NavRowSpacer,
  navRowCss,
} from '../common';
import { useColoredLogoTheme } from './hooks/useColoredLogoTheme';
import { MainNavIsland } from './island';
import { HeaderSearchStatus } from './search';
import { TopHeaderLeft, TopHeaderRight } from './top';
import { HeaderUserStatus } from './user';

export const Header: React.FC<{
  className?: string;
  logoTheme?: ColoredLogoTheme;
}> = ({ className, logoTheme }) => {
  // const [isFeaturesOpened, setIsFeaturesOpen] = useState(false);
  const ref = useStickyRef();
  const isSticky = useIsSticky();
  const theme = useColoredLogoTheme();
  return (
    <>
      <NavigationLoadingProgressBar
        color={'background'}
        height={2}
        startPosition={0}
        stopDelayMs={1000}
      />
      <TopNavContainer>
        <TopHeaderWell>
          <TopHeaderNavRow>
            <TopHeaderLeft />
            <TopHeaderRight />
          </TopHeaderNavRow>
        </TopHeaderWell>
      </TopNavContainer>
      <MainNavContainer
        bg={'background'}
        ref={ref}
        data-sticky={isSticky}
        className={className}
      >
        {/* <HeaderWell>
          <SmallTopHeaderContainer $isMobilePadded>
            <TopIsland />
            <TopRowCenter
            isFeaturesOpen={isFeaturesOpened}
              openFeatures={() => setIsFeaturesOpen(
                (s) => !s,
              )}
            />
            <TopRowRight />
          </SmallTopHeaderContainer>
        </HeaderWell>*/}
        <ContentSpacer size={'box'} />
        <MainNavRow>
          <NavRowLeftContainer>
            <Tooltip.Root
              positioning={{ placement: 'bottom-start' }}
              {...tooltipTimingProps.slowCloseAndOpen}
            >
              <Tooltip.Trigger>
                <Link href={'/'} passHref legacyBehavior>
                  <LogoNavAnchor>
                    <ColoredLogoIcon
                      theme={logoTheme ?? theme}
                      className={logoNavAnchorSvgClass}
                    />
                    {/* <LogoTextSpan>
                  Pob Studio
                </LogoTextSpan> */}
                  </LogoNavAnchor>
                </Link>
              </Tooltip.Trigger>
              <Tooltip.Positioner>
                <Tooltip.Content>
                  <Tooltip.Box p={'innerBox'}>
                    <H5>Pob Studio</H5>
                    <Tooltip.P
                      textAlign={'left'}
                      fontSize={'p.display.caption'}
                    >
                      It's pronounced Pob, like <Italic>Bob</Italic>.
                    </Tooltip.P>
                  </Tooltip.Box>
                </Tooltip.Content>
              </Tooltip.Positioner>
            </Tooltip.Root>
            <NavRowSpacer />
            <MainNavIsland />
          </NavRowLeftContainer>
          <NavRowRight />
        </MainNavRow>

        <ContentSpacer size={'box'} />
      </MainNavContainer>
    </>
  );
};

const NavRowRight: FC = () => {
  const isMounted = useMounted();
  if (!isMounted) {
    return null;
  }
  return (
    <NavRowRightContainer>
      <HeaderSearchStatus />
      <HeaderCartStatus />
      <HeaderUserStatus />
    </NavRowRightContainer>
  );
};

const TopHeaderWell = styled('div', {
  base: {
    bg: 'gray.900',
  },
});

const TopHeaderNavRow = styled('div', {
  base: css.raw(navRowCss, {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '2',
    py: {
      sm: 'increment',
      base: 'innerBox',
    },
  }),
});

const TopNavContainer = styled('div', {
  base: {
    zIndex: 'nav',
    position: 'relative',
  },
});

const MainNavContainer = styled('div', {
  base: {
    'zIndex': 'nav',
    'position': 'sticky',
    'top': 'zero',
    'transitionType': 'default',
    'transitionProperty': 'border-bottom-color, background',
    'borderBottomWidth': 'nudge',
    'borderBottomStyle': 'solid',
    'borderBottomColor': 'transparent',
    '&[data-sticky="true"]': {
      borderBottomColor: 'darken.075',
    },
  },
});

const MainNavRow = styled('div', {
  base: css.raw(navRowCss, {
    position: 'relative',
    flx: 'flexRowEnds',
  }),
});

const LogoNavAnchor = styled('a', {
  base: css.raw(baseNavAnchorCss, {
    fontSize: '[16px]',
    lineHeight: 'singleLine',
    position: 'relative',
    color: 'black',
    flx: 'flexRow',
  }),
});

const logoNavAnchorSvgClass = css({
  display: 'block',
  width: 'navLogo',
  height: 'navLogo',
});
