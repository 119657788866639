import { parseUnits } from 'viem/utils';

export const prettifyTokenValue = (
  value?: bigint | null,
  numAfterDecimals: number = 3,
  decimals: number = 18,
  defaultValue: string = '-',
) => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  const wholeUnit = 10n ** BigInt(decimals);
  const wholeValue = value / wholeUnit;
  const remainder = value % wholeUnit;
  const decimalUnit = parseUnits(
    (1 / 10 ** numAfterDecimals).toString(),
    decimals,
  );
  let decimalValue = remainder / decimalUnit;
  // calculate rounding up
  const remainderOfDecimalValue = remainder - decimalValue * decimalUnit;
  if (remainderOfDecimalValue >= decimalUnit / 2n) {
    decimalValue = decimalValue + 1n;
  }
  if (wholeValue === 0n && decimalValue === 0n) {
    return '0';
  }
  if (decimalValue === 0n) {
    return wholeValue.toString();
  }

  const paddedDecimalValue = decimalValue
    .toString()
    .padStart(numAfterDecimals, '0')
    .replace(/0+$/, '');

  return `${wholeValue.toString()}.${paddedDecimalValue}`;
};

export const prettifyNormalizedValueAsPercentage = (
  value?: number | null,
  defaultValue: string = '-',
) => {
  const valueBigInt = BigInt(Math.floor((value ?? 0) * 10 ** 18));
  return prettifyTokenValue(valueBigInt, 1, 16, defaultValue);
};
