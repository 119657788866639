import { NULL_ADDRESS } from '@pob/shared';
import { BookImage, GiftIcon, Map, Wallet } from 'lucide-react';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import { match } from 'ts-pattern';
import { MinorButton } from '~src/components/button';
import { CrestImage } from '~src/components/crest';
import {
  DeepDropdownItemGroup,
  DropdownItemA,
  DropdownItemButton,
  DropdownItemGroup,
  DropdownLineSeparator,
} from '~src/components/dropdown';
import { SimpleImg } from '~src/components/img';
import { Popover } from '~src/components/popover';
import { DisplayP, P } from '~src/components/text';
import { InheritSpan, WeaklyLightenSpan } from '~src/components/text/spans';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import { useBestExtAccountTypeFromPrivy } from '~src/hooks/privy/useBestExtAccountTypeFromPrivy';
import { useEnsUserFromPrivy } from '~src/hooks/privy/useEnsUserFromPrivy';
import { useUserSocialStatsFromExtAccount } from '~src/hooks/user/useUserSocialStatsFromExtAccount';
import { useBestWalletForPrivyUser } from '~src/hooks/wallet/useWalletFromPrivy';
import { useHiraethCrestUri } from '~src/pages/hiraeth/txn/hooks/useHiraethCrest';
import { useBlockverseContext } from '~src/shared-apps/blockverse/providers/Blockverse';
import { usePrivyDialogs } from '~src/shared-apps/social/providers/PrivyDialogsProvider';
import { css } from '~src/styled-system/css';
import { Circle, Flex, styled } from '~src/styled-system/jsx';
import { getShortenedAddress } from '~src/utils/bytes/shorten';
import { useHeader } from '../../providers/HeaderProvider';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import { BaseA } from '~src/components/anchor';
import { getSocialGraphUrl } from '~src/utils/urls/social';
export const Web3ConnectedPopoverTrigger: FC = () => {
  const wallet = useBestWalletForPrivyUser();
  const crestUri = useHiraethCrestUri(wallet?.address);
  const ensUser = useEnsUserFromPrivy();
  const isEnsUserAvailable = !!ensUser.data?.ensName;
  return (
    <Popover.Trigger asChild>
      <MinorButton
        bg={'gray.100'}
        height={'navIsland'}
        px={isEnsUserAvailable ? 'halfIncrement' : 'box'}
        py={isEnsUserAvailable ? 'halfIncrement' : undefined}
        flx={'flexRow'}
        position={'relative'}
        rounded={isEnsUserAvailable ? 'pill' : 'squaredButton'}
      >
        {isEnsUserAvailable ? (
          <Circle size={'[32px]'} pos={'relative'} overflow={'hidden'}>
            <SimpleImg src={ensUser.data?.pfp ?? ''} />
          </Circle>
        ) : (
          <CrestImage height={'[20px]'} opacity={'80'} src={crestUri} />
        )}
      </MinorButton>
    </Popover.Trigger>
  );
};

export const Web3DropdownHeaderState: FC = () => {
  const extAccountType = useBestExtAccountTypeFromPrivy();
  return match(extAccountType)
    .with('wallet', () => <Web3ConnectedHeaderState />)
    .otherwise(() => null);
};

const Web3ConnectedHeaderState: FC = () => {
  const wallet = useBestWalletForPrivyUser();
  const displayName = useBestDisplayName(wallet?.address);
  const crestUri = useHiraethCrestUri(wallet?.address);

  const shouldHideDisplayName = useMemo(() => {
    return displayName === getShortenedAddress(wallet?.address!);
  }, [displayName, wallet?.address]);

  const socialStats = useUserSocialStatsFromExtAccount({
    address: wallet?.address!,
    userType: 'wallet',
  });

  return (
    <DeepDropdownItemGroup gap={'zero'}>
      <Flex gap={'increment'} align={'center'}>
        <DropdownCrestWell>
          <CrestImage src={crestUri} height={'[24px]'} width={'[unset]'} />
        </DropdownCrestWell>
        <styled.div flexGrow={1}>
          {!shouldHideDisplayName && (
            <P
              cutoff={'oneLineInFlex'}
              fontWeight={'display.normal'}
              color={'gray.600'}
              textStyle={'p.display.caption'}
            >
              {!!displayName ? displayName : 'No Address'}
            </P>
          )}
          <P
            mt={'quarterIncrement'}
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            color={'gray.400'}
            textStyle={'p.display.caption'}
          >
            {getShortenedAddress(wallet?.address ?? NULL_ADDRESS)}
          </P>
        </styled.div>
      </Flex>
      <DropdownLineSeparator bg={'darken.03'} mx={'-box'} my={'innerBox'} />
      <Flex flx={'flexRowEnds'} alignItems={'center'}>
        <Flex gap={'increment'} alignItems={'center'}>
          <DisplayP.Caption color={'gray.500'} lineHeight={'1'}>
            {socialStats?.followersCount.toLocaleString()}{' '}
            <WeaklyLightenSpan>Followers</WeaklyLightenSpan>
          </DisplayP.Caption>
          <DisplayP.Caption color={'gray.500'} lineHeight={'1'}>
            {socialStats?.followingCount.toLocaleString()}{' '}
            <WeaklyLightenSpan>Following</WeaklyLightenSpan>
          </DisplayP.Caption>
        </Flex>
        <BaseA
          mr={'-quarterIncrement'}
          target={'_blank'}
          href={getSocialGraphUrl({
            userType: 'wallet',
            address: wallet?.address!,
          })!}
          // href={{
          //   pathname: '/address/[address]',
          //   query: { address: wallet?.address! },
          // }}
        >
          <UpRightArrowIcon className={css({
            width: 'icon.sixteen',
            height: 'icon.sixteen',
            display: 'block',
            color: 'gray.400',
          })}
          />
        </BaseA>
      </Flex>
    </DeepDropdownItemGroup>
  );
};

const DropdownCrestWell = styled('div', {
  base: {
    // background: 'gray.100',
    // padding: 'threeQuartersIncrement',
    borderRadius: 'innerOfInnerBox',
  },
});

export const Web3DropdownState: FC = () => {
  const wallet = useBestWalletForPrivyUser();
  return match(wallet?.address)
    .with(undefined, () => <WalletLogInState />)
    .otherwise(() => <Web3ConnectedState />);
};

const Web3ConnectedState: FC = () => {
  const { isClaimable } = useBlockverseContext();
  const wallet = useBestWalletForPrivyUser();
  const displayName = useBestDisplayName(wallet?.address);

  return (
    <DropdownItemGroup bg={'white'}>
      {/* <Flex px={'innerBox'} py={'increment'} gap={'increment'} align={'center'}>
        <DropdownCrestWell>
          <CrestImage src={crestUri} height={'[24px]'} width={'[unset]'} />
        </DropdownCrestWell>
        <styled.div flexGrow={1}>
          {!shouldHideDisplayName && (
            <P
              cutoff={'oneLineInFlex'}
              fontWeight={'display.normal'}
              color={'gray.600'}
              textStyle={'p.display.caption'}
            >
              {!!displayName ? displayName : 'No Address'}
            </P>
          )}
          <P
            mt={'quarterIncrement'}
            cutoff={'oneLineInFlex'}
            fontWeight={'display.normal'}
            color={'gray.400'}
            textStyle={'p.display.caption'}
          >
            {getShortenedAddress(wallet?.address ?? NULL_ADDRESS)}
          </P>
        </styled.div>
      </Flex>
      <DropdownLineSeparator bg={'darken.03'} /> */}
      {isClaimable && (
        <Link
          passHref
          legacyBehavior
          href={{
            pathname: '/hash/next-chapter/[address]',
            query: {
              address: wallet?.address!,
            },
          }}
        >
          <DropdownItemA
            flx={'flexRowEnds'}
            fontWeight={'display.superBold'}
            // bg={{
            //   base: 'gray.100',
            //   _hover: 'gray.200',
            // }}
          >
            Claim Hiraeth
            <GiftIcon
              size={14}
              className={css({
                display: 'block',
                color: 'gray.400',
              })}
            />
          </DropdownItemA>
        </Link>
      )}
      <Link
        passHref
        legacyBehavior
        href={{
          pathname: '/address/[address]',
          query: {
            address: wallet?.address!,
          },
        }}
      >
        <DropdownItemA flx={'flexRowEnds'}>
          Your Notable History
          <Map
            size={14}
            className={css({
              display: 'block',
              color: 'gray.400',
            })}
          />
        </DropdownItemA>
      </Link>
      <Link
        passHref
        legacyBehavior
        href={{
          pathname: '/address/[address]',
          query: {
            address: wallet?.address!,
            tab: 'collection',
          },
        }}
      >
        <DropdownItemA flx={'flexRowEnds'}>
          Collection
          <BookImage
            size={14}
            className={css({
              display: 'block',
              color: 'gray.400',
            })}
          />
        </DropdownItemA>
      </Link>
    </DropdownItemGroup>
  );
};

const WalletLogInState: FC = () => {
  const { open } = usePrivyDialogs();
  const { close: closeHeader } = useHeader();
  return (
    <DropdownItemGroup bg={'white'}>
      <DropdownItemButton
        flx={'flexRowEnds'}
        onClick={() => {
          open();
          closeHeader();
        }}
      >
        <InheritSpan>Link Wallet</InheritSpan>
        <Wallet
          className={css({
            width: 'icon.fourteen',
            height: 'icon.fourteen',
            display: 'block',
          })}
        />
      </DropdownItemButton>
    </DropdownItemGroup>
  );
};
