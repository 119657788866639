import React, { ReactNode, useMemo } from 'react';
import { useSticky } from '../../hooks/ui/useSticky';

export interface StickyContext {
  isSticky: boolean;
  ref: any;
}

export type StickyState = StickyContext;

const initialAppState: StickyState = {
  ref: undefined,
  isSticky: false,
};

const StickyContext = React.createContext<StickyState>(initialAppState);

const StickyProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [subNavRef, sticky] = useSticky();

  const stateObj = useMemo(() => {
    return {
      ref: subNavRef,
      isSticky: sticky,
    };
  }, [subNavRef, sticky]);

  return (
    <StickyContext.Provider value={stateObj}>{children}</StickyContext.Provider>
  );
};

const useStickyContext = (): StickyState => {
  return React.useContext(StickyContext);
};

const useStickyRef = () => {
  const { ref } = useStickyContext();
  return ref;
};

const useIsSticky = () => {
  const { isSticky } = useStickyContext();
  return isSticky;
};

export { StickyProvider, useIsSticky, useStickyContext, useStickyRef };
