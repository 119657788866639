import { PROJECT_METADATA } from '../../../meta/project/metadata';
import { ProjectMetadata } from '../../../types/project/metadata';

export type HiraethCollectionSlug =
  keyof (typeof PROJECT_METADATA)['hiraeth']['collections'];

export const HIRAETH_PROJECT_METADATA: ProjectMetadata<HiraethCollectionSlug> =
  PROJECT_METADATA['hiraeth']!;

export const HIRAETH_QUOTE = '"Do not go gentle into that good night."';
