import { hiraethStepMintingAuthorityAbi } from '@hiraeth/protocol';
import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { NULL_ADDRESS } from '@pob/shared';
import { AvailableChainId } from '@protocol/chains';
import { ProjectId, type EvmDeployment } from '@protocol/deployments';
import type { ValueOf } from 'type-fest';
import type { TransactionReceipt } from 'viem';
import { base, baseSepolia, mainnet, lukso } from 'viem/chains';
import { decodeEventLog, decodeFunctionData, pad } from 'viem/utils';
import type { CheckoutReceiptItemStateProps } from '~src/shared-apps/receipt/types/state';
import { serializeId } from '~src/utils/strings/serializeId';
import type {
  BaseCheckoutItem,
  Call3ValueStruct,
} from '../shared-apps/checkout/types';
import type { CheckoutItemMintedProps } from '../shared-apps/checkout/types/state';
import { HIRAETH_CONFIG } from './projects/hiraeth';
import { isSupportedChainForProjectForCheckout } from './utils/isSupportedChainForProjectForCheckout';
import { isEqAddress } from '~src/utils/bytes/isEqAddress';
import { toHex } from 'viem/utils';
import { padHexString } from '~src/utils/bytes/shorten';

export type CheckoutProjectMetadata = {
  getBaseCheckoutItemFromCall: (
    chainId: AvailableChainId,
    deployment: EvmDeployment,
    call: Call3ValueStruct,
  ) => BaseCheckoutItem[] | undefined;
  getCheckoutStatesFromEvents: (
    chainId: AvailableChainId,
    logs: TransactionReceipt['logs'],
  ) => {
    [id: string]: CheckoutReceiptItemStateProps;
  };
};

export const CHECKOUT_PROJECTS_SUPPORTED = {
  hiraeth: true,
} as const;

export type SupportedCheckoutProjectId =
  keyof typeof CHECKOUT_PROJECTS_SUPPORTED;

export const CHECKOUT_PROJECT_METADATA = {
  hiraeth: {
    getBaseCheckoutItemFromCall: (
      chainId: AvailableChainId,
      d: EvmDeployment,
      call: Call3ValueStruct,
    ): BaseCheckoutItem[] | undefined => {
      console.log('call', call);
      if (
        !isEqAddress(call.target, d.projects.hiraeth?.steppedMintingAuthority) &&
        !isEqAddress(call.target, d.projects.hiraeth?.claimMintingAuthority)
      ) {
        return [];
      }
      const decodedCallParameters = decodeFunctionData({
        abi: hiraethStepMintingAuthorityAbi,
        data: call.callData,
      });

      console.log('decodedCallParameters', decodedCallParameters);

      if (decodedCallParameters.functionName !== 'mint') {
        return [];
      }
      const items: BaseCheckoutItem[] = [];

      for (const txnHash of decodedCallParameters.args[1]) {
        items.push({
          objId: [
            'hiraeth',
            chainId as SupportedBlockverseChainId<'hiraeth'>,
            txnHash as Evm.TxnHash,
          ],
        });
      }
      return items;
    },
    getCheckoutStatesFromEvents: (
      chainId: AvailableChainId,
      logs: TransactionReceipt['logs'],
    ) => {
      if (!isSupportedChainForProjectForCheckout('hiraeth', chainId)) {
        throw new Error('Unsupported chain id');
      }

      const objIdStateMap: Record<string, CheckoutReceiptItemStateProps> = {};

      const tokenIdToTxnHashMap: Record<string, string> = {};

      for (const log of logs) {
        try {
          const typedEvent = decodeEventLog({
            abi: HIRAETH_CONFIG[chainId].tokenAbi,
            data: log.data,
            topics: log.topics,
          });

          if (
            typedEvent === undefined ||
            typedEvent.eventName !== 'HiraethClaimed'
          ) {
            continue;
          }

          const txnHash = typedEvent.args.txnHash;
          const tokenId = typedEvent.args.tokenId;
          const paddedTokenId = pad(toHex(tokenId));

          tokenIdToTxnHashMap[paddedTokenId] = txnHash;
          const objId = ['hiraeth', chainId, txnHash];
          const serializedObjId = serializeId(objId);
          if (tokenId === 0n) {
            objIdStateMap[serializedObjId] = {
              type: 'did-not-mint',
            };
          } else {
            objIdStateMap[serializedObjId] = {
              type: 'minted',
              tokenId: tokenId,
              owner: NULL_ADDRESS,
            };
          }
        } catch (e) {}
      }

      for (const log of logs) {
        try {
          const typedEvent = decodeEventLog({
            abi: HIRAETH_CONFIG[chainId].tokenAbi,
            data: log.data,
            topics: log.topics,
          });

          console.log('typedEvent', typedEvent);

          if (typedEvent === undefined || typedEvent.eventName !== 'Transfer') {
            continue;
          }
          if (!isEqAddress(typedEvent.args.from, NULL_ADDRESS)) {
            continue;
          }
          const txnHash =
            tokenIdToTxnHashMap[typedEvent.args.tokenId.toString()];
          if (txnHash === undefined) {
            continue;
          }

          const objId = ['hiraeth', chainId, txnHash];
          const serializedObjId = serializeId(objId);

          if (objIdStateMap[serializedObjId]?.type !== 'minted') {
            continue;
          }

          (objIdStateMap[serializedObjId] as CheckoutItemMintedProps).owner =
            typedEvent.args.to as Evm.Address;
        } catch (e) {}
      }

      return objIdStateMap;
    },
  },
} as const satisfies {
  [project in ProjectId]?: CheckoutProjectMetadata;
};

export const PROJECT_SUPPORTED_CHECKOUT_CHAINS = {
  hiraeth: {
    [baseSepolia.id]: baseSepolia.id,
    [base.id]: base.id,
    [mainnet.id]: mainnet.id,
    // [lukso.id]: lukso.id,
  },
} as const satisfies Record<
  SupportedCheckoutProjectId,
  null | Record<number, AvailableChainId>
>;

export type SupportedCheckoutChainId<
  TProjectId extends SupportedCheckoutProjectId,
> = ValueOf<(typeof PROJECT_SUPPORTED_CHECKOUT_CHAINS)[TProjectId]>;
