import { fetchWeb3 } from '@pob/web3-fetch';
import {
  QueryKey,
  QueryObserverResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { persister } from '../../clients/query';
import { QueryStateOptions } from '../../types/query';

export interface UseGetFetchOptions<
  QueryParams,
  ReturnType,
  QueryReturnType = ReturnType,
> extends QueryStateOptions {
  queryParams?: QueryParams;
  queryKey?: QueryKey;
  initialData?: QueryReturnType;
  select?: (data: QueryReturnType) => ReturnType;
  preferPublicIpfsGateway?: boolean;
  headers?: Record<string, string>;
}

const IDENTITY_FN = (v: unknown) => v;

export function useGetFetch<
  QueryParams,
  ReturnType,
  QueryReturnType = ReturnType,
>(
  uri?: string,
  options?: UseGetFetchOptions<QueryParams, ReturnType, QueryReturnType>,
): QueryObserverResult<ReturnType> {
  const queryOptions = useMemo(
    () => makeGetFetchQueryOptions(uri, options),
    [uri, options],
  );

  const queryResult = useQuery(queryOptions);

  return queryResult;
}

export function makeGetFetchQueryOptions<
  QueryParams,
  ReturnType,
  QueryReturnType = ReturnType,
>(
  uri?: string,
  options?: UseGetFetchOptions<QueryParams, ReturnType, QueryReturnType>,
): UseQueryOptions<QueryReturnType, Error, ReturnType> {
  const {
    enabled = true,
    queryParams,
    staleTime,
    select,
    refetchInterval,
    initialData,
    retry,
    retryDelay,
    preferPublicIpfsGateway = false,
    shouldUsePersister = true,
    queryKey: defaultQueryKey,
    headers,
  } = options || {};

  const queryKey =
    defaultQueryKey ?? makeGetFetchQueryKey<QueryParams>(uri, queryParams);

  const queryFn = async (): Promise<QueryReturnType> => {
    if (!uri) {
      throw new Error('uri is required');
    }
    const finalQueryParams = queryParams || {};
    // console.log('getFetch', uri.slice(0, 100), finalQueryParams, queryKey);
    try {
      const result = await fetchWeb3<QueryReturnType>(uri, {
        queryObject: finalQueryParams,
        preferPublicIpfsGateway,
        headers,
      });
      if (!result) {
        throw new Error('no result, malformed request');
      }
      return result;
    } catch (e) {
      console.log('queryFn:err', e);
      throw e;
    }
  };

  const queryOptions: UseQueryOptions<QueryReturnType, Error, ReturnType> = {
    queryKey,
    queryFn,
    refetchInterval,
    select: select || (IDENTITY_FN as (v: QueryReturnType) => ReturnType),
    staleTime,
    initialData,
    enabled: !!uri && enabled,
    persister: shouldUsePersister ? persister : undefined,
    retry,
    retryDelay,
  };

  return queryOptions;
}

export function makeGetFetchQueryKey<T>(
  uri?: string,
  queryParams?: T,
): [
  string,
  string | undefined,
  {
    queryParams?: T;
  },
] {
  return ['getFetch', uri, { queryParams }];
}
