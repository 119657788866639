import { type FC, type ReactNode } from 'react';
import { match } from 'ts-pattern';
import { DisplayP } from '~src/components/text';
import {
  BaseBlockverseItemRowDetails,
  type BaseBlockverseItemRowDetailsProps,
} from '~src/shared-apps/blockverse/components/items/details';
import { BaseCheckoutRowContainer } from '~src/shared-apps/checkout/components/item/base';
import { CheckoutRowStatusBadge } from '~src/shared-apps/checkout/components/item/common';
import { css } from '~src/styled-system/css';
import { statefulColorsVariant } from '~src/theme/variants/stateful';
import type { CheckoutReceiptItemStateProps } from '../../types/state';

export type BaseCheckoutReceiptItemRowProps =
  BaseBlockverseItemRowDetailsProps & {
    // price: CheckoutItemPriceProps;
    handleState?: (state: CheckoutReceiptItemStateProps) => ReactNode;
    state: CheckoutReceiptItemStateProps;
  };

export const BaseCheckoutReceiptItemRow: FC<
  BaseCheckoutReceiptItemRowProps
> = ({ name, visual, project, chainId, state, handleState }) => {
  return (
    <BaseCheckoutRowContainer>
      <BaseBlockverseItemRowDetails
        name={name}
        visual={visual}
        project={project}
        chainId={chainId}
      />
      {match(state)
        .with({ type: 'minted' }, () => {
          return (
            <CheckoutRowStatusBadge
              css={css.raw(statefulColorsVariant.success)}
            >
              <DisplayP.Caption>Minted</DisplayP.Caption>
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'loading' }, () => {
          return null;
          // return <Spinner size={10} color={colors['gray.400']} />;
        })
        .with({ type: 'pending' }, () => {
          // doesn't make sense to show a badge for pending
          return null;
        })
        .with({ type: 'did-not-mint' }, () => {
          return (
            <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.300'}
              color={'gray.400'}
            >
              <DisplayP.Caption>Not minted</DisplayP.Caption>
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'failed' }, () => {
          return (
            <CheckoutRowStatusBadge css={css.raw(statefulColorsVariant.error)}>
              <DisplayP.Caption>Failed</DisplayP.Caption>
            </CheckoutRowStatusBadge>
          );
        })
        .exhaustive()}
      {handleState?.(state) ?? null}
    </BaseCheckoutRowContainer>
  );
};
