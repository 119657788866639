import { HARDHAT_CHAIN_ID, bitcoin } from '@protocol/chains';
import {
  MAX_SUPPLY_FOR_MINTING_GROUP,
  convertMintingGroupForProtocol,
  getIntervalFromActiveFromToIndefinite,
  getMintingGroupConfig,
} from '@protocol/erc721c';
import { parseEther } from 'viem';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import { HiraethProtocolParametersByChain } from './types';

export * from './types';

export const hiraethProtocolParametersByChain: HiraethProtocolParametersByChain =
  {
    [mainnet.id]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2024-07-18T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('0.018'),
          priceStep: parseEther('0.0001'),
          priceStepSize: 1000n,
        },
      },
      claimMintingGroupId: 1,
      claimMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            7000n,
            getIntervalFromActiveFromToIndefinite(
              new Date('2024-07-18T00:00:00.000-07:00'),
            ),
            false,
            false,
          ),
        ),
      },
    },
    [base.id]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2024-06-07T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('0.009'),
          priceStep: parseEther('0.0001'),
          priceStepSize: 1000n,
        },
      },
    },
    [sepolia.id]: null,
    [baseSepolia.id]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('0.009'),
          priceStep: parseEther('0.0001'),
          priceStepSize: 3n,
        },
      },
    },
    [optimism.id]: null,
    [optimismSepolia.id]: null,
    [lukso.id]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('5'),
          priceStep: parseEther('5'),
          priceStepSize: 100n,
        },
      },
      adminMintingGroupId: 1,
      adminMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            1000n,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
      },
    },
    [luksoTestnet.id]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('0.001'),
          priceStep: parseEther('0.0001'),
          priceStepSize: 3n,
        },
      },
      adminMintingGroupId: 1,
      adminMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            1000n,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
      },
    },
    [HARDHAT_CHAIN_ID]: {
      defaultMintingGroupId: 0,
      defaultMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            MAX_SUPPLY_FOR_MINTING_GROUP,
            getIntervalFromActiveFromToIndefinite(
              new Date('2023-08-16T00:00:00.000-07:00'),
            ),
            true,
            false,
          ),
        ),
        mintingParameters: {
          priceStepStart: parseEther('0.009'),
          priceStep: parseEther('0.0001'),
          priceStepSize: 3n,
        },
      },
      claimMintingGroupId: 1,
      claimMintingGroup: {
        ...convertMintingGroupForProtocol(
          getMintingGroupConfig(
            7000n,
            getIntervalFromActiveFromToIndefinite(
              new Date('2024-07-18T00:00:00.000-07:00'),
            ),
            false,
            false,
          ),
        ),
      },
    },
    [bitcoin.id]: null,
  };
