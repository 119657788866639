import type { FC } from 'react';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { P } from '~src/components/text';
import { shimmerTextLighten } from '~src/components/text/effects/shimmer';
import { useBlockverseChainIds } from '~src/shared-apps/blockverse/hooks/useBlockverseChainIds';
import { HStack, styled } from '~src/styled-system/jsx';

export const AvailableChains: FC = () => {
  const searchChainIds = useBlockverseChainIds();
  return (
    <>
      {' '}
      <P
        textStyle={'p.display.caption'}
        hideBelow={'sm'}
        color={'black/50'}
        className={shimmerTextLighten}
      >
        Available on {searchChainIds.length} chains
      </P>{' '}
      <HStack gap={'zero'}>
        {searchChainIds.map((chainId, i) => (
          <ChainImgContainer
            key={`available-chains-${chainId}`}
            mr={
              i === searchChainIds.length - 1
                ? 'zero'
                : '-threeQuartersIncrement'
            }
            style={{
              zIndex: searchChainIds.length - i,
            }}
          >
            {ICONS_BY_CHAIN_IDS[chainId]}
          </ChainImgContainer>
        ))}
      </HStack>
    </>
  );
};

const ChainImgContainer = styled('div', {
  base: {
    height: 'icon.sixteen',
    width: 'icon.sixteen',
    rounded: 'pill',
    overflow: 'hidden',
    pos: 'relative',
  },
});
