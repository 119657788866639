import type { BlockverseProjectId } from '@pob/blockverse';
import type { AvailableChainId } from '@protocol/chains';
import { type FC, type ReactNode } from 'react';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { DisplayP } from '~src/components/text';
import { Box, HStack, styled } from '~src/styled-system/jsx';
import type { SystemStyleObject } from '~src/styled-system/types';
import { capitalize } from '~src/utils/lodash';

export type BaseBlockverseItemRowDetailsProps = {
  name: string;
  visual: ReactNode;
  project: BlockverseProjectId;
  caption?: ReactNode;
  chainId: AvailableChainId;
  slots?: {
    visualContainer?: SystemStyleObject;
    name?: SystemStyleObject;
    caption?: SystemStyleObject;
    chainIcon?: SystemStyleObject;
    container?: SystemStyleObject;
  };
};

export const BaseBlockverseItemRowDetails: FC<
  BaseBlockverseItemRowDetailsProps
> = ({ name, visual, caption, chainId, project, slots }) => {
  return (
    <HStack
      minWidth={'zero'}
      alignItems={'center'}
      gap={'increment'}
      css={slots?.container}
    >
      <Box position={'relative'} flexShrink={0}>
        <BlockverseItemIllustration css={slots?.visualContainer}>
          {visual}
        </BlockverseItemIllustration>
        <BlockverseVisualChainIconContainer css={slots?.chainIcon}>
          {ICONS_BY_CHAIN_IDS[chainId]}
        </BlockverseVisualChainIconContainer>
      </Box>
      <Box minWidth={'zero'} flexGrow={1}>
        <DisplayP.Default
          fontSize={'p.display.reading'}
          lineHeight={'singleLine'}
          cutoff={'oneLineInFlex'}
          fontWeight={'display.bold'}
          css={slots?.name}
        >
          {name}
        </DisplayP.Default>
        <DisplayP.Caption
          mt={'quarterIncrement'}
          color={'gray.600'}
          css={slots?.caption}
        >
          {caption ?? capitalize(project)}
        </DisplayP.Caption>
      </Box>
    </HStack>
  );
};

export const BlockverseItemIllustration = styled('div', {
  base: {
    aspectRatio: '1:1',
    height: 'icon.largeBadge',
    bg: 'darken',
    rounded: 'innerOfSquaredButton',
    overflow: 'hidden',
    position: 'relative',
  },
});

export const BlockverseVisualChainIconContainer = styled(
  'div',
  {
    base: {
      position: 'absolute',
      bottom: '-halfIncrement',
      right: '-halfIncrement',
      height: 'icon.sixteen',
      width: 'icon.sixteen',
      borderRadius: 'pill',
      overflow: 'hidden',
      shadow: 'default',
    },
  },
  {},
);
