export interface StepMintingParameters {
  priceStepSize: bigint;
  priceStep: bigint;
  priceStepStart: bigint;
}

export const getPriceStepFactory = (params: StepMintingParameters) => {
  return (nthMint: bigint) => {
    return nthMint / params.priceStepSize;
  };
};

export const getSteppedPriceByStepFactory = (params: StepMintingParameters) => {
  return (step: bigint) => {
    return params.priceStepStart + step * params.priceStep;
  };
};

export const getSteppedPriceFactory = (params: StepMintingParameters) => {
  const getSteppedPriceByStep = getSteppedPriceByStepFactory(params);
  const getPriceStep = getPriceStepFactory(params);
  return (nthMint: bigint) => {
    return getSteppedPriceByStep(getPriceStep(nthMint));
  };
};

export const getNumMintsLeftForStepFactory = (
  params: StepMintingParameters,
) => {
  return (nthMint: bigint) => {
    return params.priceStepSize - (nthMint % params.priceStepSize);
  };
};

export const getTotalPriceFactory = (params: StepMintingParameters) => {
  const getSteppedPrice = getSteppedPriceFactory(params);
  return (numMinted: bigint, numMints: bigint) => {
    let totalPrice = 0n;
    for (let i = 0n; i < numMints; i++) {
      totalPrice += getSteppedPrice(numMinted + i);
    }
    return totalPrice;
  };
};
