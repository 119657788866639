import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { FC } from 'react';
import { BaseLoadingImg } from '~src/components/img';
import { HIRAETH_PROJECT_METADATA } from '~src/pages/hiraeth/constants';
import type { BaseBlockverseItemRowDetailsProps } from '~src/shared-apps/blockverse/components/items/details';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type { CheckoutItemRowProps } from '~src/shared-apps/checkout/components/item';
import { BaseCheckoutItemRow } from '~src/shared-apps/checkout/components/item/base';
import type { CheckoutReceiptItemRowProps } from '~src/shared-apps/receipt/components/item';
import { BaseCheckoutReceiptItemRow } from '~src/shared-apps/receipt/components/item/base';
import { css } from '~src/styled-system/css';
import { getBase64PlaceholderImage } from '~src/utils/image/placeholder';
import { useHiraethArtImageUrlByChainAndTxnHash } from '../../../../../pages/hiraeth/txn/hooks/useHiraethArtImageUrl';
import { useHiraethBestTitle } from '../../../../../pages/hiraeth/txn/hooks/useHiraethBestTitle';

export const useHiraethCheckoutRowItemDetailProps = (item: {
  objId: BlockverseObjId<'hiraeth'>;
}): BaseBlockverseItemRowDetailsProps => {
  const [projectId, chainId, txnHash] = item.objId;
  const name = useHiraethBestTitle(txnHash);
  const artUrl = useHiraethArtImageUrlByChainAndTxnHash(
    chainId as SupportedBlockverseChainId<'hiraeth'>,
    txnHash,
  );

  return {
    name,
    project: 'hiraeth',
    chainId,
    slots: {
      visualContainer: css.raw({
        aspectRatio: '[2]',
      }),
    },
    visual: !!artUrl ? (
      <BaseLoadingImg
        src={artUrl}
        alt={`${HIRAETH_PROJECT_METADATA.name} art of ${item.objId}`}
        unoptimized
        onError={(e) => {
          e.currentTarget.src = getBase64PlaceholderImage(1, 1, 'transparent');
        }}
        width={100}
        height={100}
        // sizes={getSizesForImage({
        //   upToExtraLarge: undefined,
        //   upToLarge: undefined,
        //   upToMedium: undefined,
        //   upToSmall: undefined,
        //   upToExtraSmall: 10,
        //   searchModal: undefined,
        // })}
        className={css({
          imageRendering: 'pixelated',
          objectFit: 'cover',
          display: 'block',
          objectPosition: 'center',
          position: 'absolute',
          top: '[-50%]',
        })}
      />
    ) : null,
  };
};

const HiraethCheckoutItemRow: FC<CheckoutItemRowProps> = ({ item }) => {
  const rowItemDetailProps = useHiraethCheckoutRowItemDetailProps(item);
  return (
    <BaseCheckoutItemRow
      {...rowItemDetailProps}
      priceProps={item.priceProps}
      state={item.state}
    />
  );
};

const HiraethCheckoutReceiptItemRow: FC<CheckoutReceiptItemRowProps> = ({
  item,
}) => {
  const rowItemDetailProps = useHiraethCheckoutRowItemDetailProps(item);
  return (
    <BaseCheckoutReceiptItemRow {...rowItemDetailProps} state={item.state} />
  );
};

export const HiraethCheckoutItem = {
  Row: HiraethCheckoutItemRow,
  ReceiptRow: HiraethCheckoutReceiptItemRow,
};
