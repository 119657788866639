import { getWeb2UrlForWeb3Uri } from '@pob/web3-fetch';
import type { AvailableChainId } from '@protocol/chains';
import { z } from 'zod';
import {
  AttributeMetadata,
  AttributeMetadataRaw,
  attributeMetadataRawZod,
  NftMetadata,
  nftMetadataRawZod,
  PrettyNftMetadata,
  tokenMetadataZod,
  type NftMetadataRaw,
  type TokenMetadata,
} from '../types';

export const toNftAttribute = (unknownData: unknown): AttributeMetadata => {
  return attributeMetadataRawZod
    .transform((data) => {
      return {
        traitType: data.trait_type ?? undefined,
        value: data.value ?? undefined,
        displayType: data.display_type ?? undefined,
        maxValue: data.max_value ?? undefined,
        name: data.name ?? undefined,
      } satisfies AttributeMetadata;
    })
    .parse(unknownData);
};

export const toRawNftAttribute = (
  attributeMetadata: AttributeMetadata,
): AttributeMetadataRaw => {
  return {
    trait_type: attributeMetadata.traitType,
    value: attributeMetadata.value,
    display_type: attributeMetadata.displayType,
    max_value: attributeMetadata.maxValue,
    name: attributeMetadata.name,
  } satisfies AttributeMetadataRaw;
};

export const toNftAttributes = (
  rawNftAttributes: unknown,
): AttributeMetadata[] => {
  const attemptToParseAsArrayResults = z
    .array(attributeMetadataRawZod)
    .safeParse(rawNftAttributes);
  if (attemptToParseAsArrayResults.success) {
    return attemptToParseAsArrayResults.data.map(toNftAttribute);
  }

  const attemptToParseAsObjectResults = z
    .record(z.string(), attributeMetadataRawZod)
    .safeParse(rawNftAttributes);

  if (attemptToParseAsObjectResults.success) {
    return Object.entries(attemptToParseAsObjectResults.data).map(
      ([traitType, rawMetadataAttribute]) => {
        return toNftAttribute({
          ...rawMetadataAttribute,
          trait_type: traitType,
        });
      },
    );
  }

  return [];
};

export const toNftMetadata = (unknownData: unknown): NftMetadata => {
  return nftMetadataRawZod
    .transform((data) => {
      return {
        name: data.name ?? undefined,
        description: data.description ?? undefined,
        image: data.image ?? undefined,
        animationUrl: data.animation_url ?? undefined,
        externalUrl: data.external_url ?? undefined,
        attributes: toNftAttributes(
          data.attributes ?? data.properties ?? data.features ?? data.traits,
        ),
      } satisfies NftMetadata;
    })
    .parse(unknownData);
};

export const toRawNftMetadata = (nftMetadata: NftMetadata): NftMetadataRaw => {
  return {
    name: nftMetadata.name,
    description: nftMetadata.description,
    image: nftMetadata.image,
    animation_url: nftMetadata.animationUrl,
    external_url: nftMetadata.externalUrl,
    attributes: nftMetadata.attributes?.map(toRawNftAttribute),
  } satisfies NftMetadataRaw;
};

export const getPrettyNftMetadataFromNftMetadata = (
  nftMetadata: NftMetadata,
  tokenMetadata: TokenMetadata,
): PrettyNftMetadata => {
  return {
    ...nftMetadata,
    bestName: nftMetadata.name ?? `#${tokenMetadata.tokenId}`,
    bestImage: !!nftMetadata.image
      ? getWeb2UrlForWeb3Uri(nftMetadata.image, {})
      : undefined,
    token: tokenMetadata,
  };
};

// export const mergePrettyNftMetadata = (
//   prettyNftMetadata: PrettyNftMetadata,
//   newPrettyNftMetadata: PrettyNftMetadata,
// ): PrettyNftMetadata => {
//   return {
//     ...prettyNftMetadata,
//     ...newPrettyNftMetadata,
//     bestName: newPrettyNftMetadata.bestName ?? prettyNftMetadata.bestName,
//     bestImage: newPrettyNftMetadata.bestImage ?? prettyNftMetadata.bestImage,
//   };
// };

// const NUM_ATTRIBUTE_VALUE_DECIMALS = 3;
// const ATTRIBUTE_VALUE_DIVIDER = 10 ** NUM_ATTRIBUTE_VALUE_DECIMALS;

// export const prettifyAttributeValue = (
//   value: string | number | null | undefined,
// ) => {
//   if (typeof value === 'number') {
//     return (
//       Math.round(value * ATTRIBUTE_VALUE_DIVIDER) / ATTRIBUTE_VALUE_DIVIDER
//     ).toString();
//   }
//   if (typeof value === 'string') {
//     if (value.startsWith('0x') && value.length > 10) {
//       return getShortenedTxn(value);
//     }
//     return value;
//   }
//   if (typeof value === 'object') {
//     return JSON.stringify(value);
//   }
//   return '-';
// };

export const getAttributeByTraitType = (
  nftMetadata: NftMetadata,
  traitType: string,
) => {
  return nftMetadata.attributes?.find((a) => a.traitType === traitType);
};

export const getPrettyNftMetadataFromAlchemy = (
  chainId: AvailableChainId<'evm'>,
  data: any,
): PrettyNftMetadata => {
  const nftMetadata = toNftMetadata(data.raw.metadata);
  const tokenMetadata = tokenMetadataZod.parse({
    tokenId: data.tokenId,
    collectionAddress: data.contract.address,
    chainId,
  });

  const prettyNftMetadata = getPrettyNftMetadataFromNftMetadata(
    nftMetadata,
    tokenMetadata,
  );

  // console.log(
  //   'prettyNftMetadata',
  //   data.image.cachedUrl,
  //   data.image.pngUrl,
  //   prettyNftMetadata.bestImage,
  // );

  prettyNftMetadata.bestImage =
    // data.image.cachedUrl ??
    data.image.pngUrl ?? prettyNftMetadata.bestImage;

  return prettyNftMetadata;
};

// export const sanitizePrettyNftMetadata = (metadata: PrettyNftMetadata) => {
//   let newMetadata = Object.assign({}, metadata);
//   newMetadata = sanitizeObject(newMetadata);
//   if (!!newMetadata.attributes) {
//     newMetadata.attributes = newMetadata.attributes.map((a) =>
//       sanitizeObject(a),
//     );
//   }
//   return newMetadata as PrettyNftMetadata;
// };
