import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { useMemo } from 'react';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';
import { getChainNetwork } from '~src/utils/chain';
import { route } from '~src/utils/routes';
import { useHiraeth } from '../providers/Hiraeth';

export const useHiraethArtImageUrl = () => {
  const { txnHash } = useHiraeth();
  return useHiraethArtImageUrlByChainAndTxnHash(
    usePreferredChainId<SupportedBlockverseChainId<'hiraeth'>>(),
    txnHash,
  );
};

export const useHiraethArtImageUrlByChainAndTxnHash = (
  chainId?: SupportedBlockverseChainId<'hiraeth'>,
  txnHash?: Evm.TxnHash,
) => {
  return useMemo(() => {
    if (!txnHash) {
      return undefined;
    }
    if (!chainId) {
      return undefined;
    }
    return route({
      pathname: '/api/hiraeth/[chainNetwork]/[txnHash]/image',
      query: {
        chainNetwork: getChainNetwork(chainId),
        txnHash,
      },
    });
  }, [txnHash, chainId]);
};
