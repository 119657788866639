import { NftMetadata } from 'alchemy-sdk';
import React, { createContext, useContext, useMemo, type FC } from 'react';
import { withQuery } from 'ufo';
import { CONSTANT_QUERY_LIVENESS } from '~src/constants/query';
import { useGetFetch } from '~src/hooks/fetch/useGetFetch';

import type { Evm } from '@pob/shared';
import { NULL_BYTES } from '@pob/shared';
import { mainnet } from 'viem/chains';
import { useHashOwnerAndTokenId } from '~src/pages/hash/hooks/useHashOwnerAndTokenId';
import {
  usePreferredAddress,
  usePreferredChain,
} from '~src/providers/PreferredNetwork';
import { useAddSingleBlockverseContext } from '~src/shared-apps/context/hooks/useAddSingleBlockverseContext';
import { HashLegacyBlockverseContext } from '~src/shared-apps/context/types/context';
import {
  getPrettyNftMetadataFromNftMetadata,
  toNftMetadata,
} from '~src/shared-apps/nft/utils';
import { useHiraethObjId } from '../hooks/useHiraethObjId';
import { HashNftMetadata } from '../types';
import { isChainLegacyWithHash } from '../utils/isChainLegacyWithHash';
import { tokenMetadataZod } from '~src/shared-apps/nft/types';

const HASH_OPTIMISTIC_API_URL = `https://hash.pob.studio/api/token-metadata/optimistic`;

type HiraethHashContextType = {
  txnHash: Evm.TxnHash;
  tokenId: bigint | undefined;
  isHashLegacyChain: boolean;
  owner: Evm.Address | null | undefined;
};

export const HiraethHashContext = createContext<HiraethHashContextType>({
  txnHash: NULL_BYTES as Evm.TxnHash,
  owner: undefined,
  isHashLegacyChain: false,
  tokenId: undefined,
});

export type HiraethHashProviderProps = {
  txnHash: Evm.TxnHash;
};

export const useHiraethHash = () => {
  const context = useContext(HiraethHashContext);
  if (context.txnHash === NULL_BYTES) {
    return undefined;
  }
  return context;
};

export const EmptyHiraethHashProvider: FC<
  HiraethHashProviderProps & { children: React.ReactNode }
> = ({ children }) => {
  return <>{children}</>;
};

export const HiraethHashProvider = ({
  txnHash,
  children,
  // metadata: providedMetadata,
  // shouldDisableMetadataRefresh,
  // contexts,
}: HiraethHashProviderProps & { children: React.ReactNode }) => {
  const chain = usePreferredChain();
  const isHashLegacyChain = useMemo(
    () => isChainLegacyWithHash(chain.id),
    [chain],
  );

  const hash = usePreferredAddress((d) => d.projects.hash?.nft);

  const hashOwnerAndTokenIdRes = useHashOwnerAndTokenId(
    txnHash,
    undefined,
    CONSTANT_QUERY_LIVENESS,
  );

  const hashMetadataRes = useGetFetch<{ txHash: string }, HashNftMetadata, any>(
    HASH_OPTIMISTIC_API_URL,
    {
      ...CONSTANT_QUERY_LIVENESS,
      queryParams: {
        txHash: txnHash!,
      },
      shouldUsePersister: false,
      enabled: !!hashOwnerAndTokenIdRes.data,
      select: (res) => {
        const metadata = toNftMetadata(res);
        const correctedMetadata = {
          ...metadata,
          externalUrl: res.external_link,
        } satisfies NftMetadata;

        const tokenMetadata = tokenMetadataZod.parse({
          tokenId: hashOwnerAndTokenIdRes.data?.id,
          collectionAddress: hash,
          chainId: mainnet.id,
        });

        const prettyNftMetadata = getPrettyNftMetadataFromNftMetadata(
          correctedMetadata,
          tokenMetadata,
        );

        return {
          ...prettyNftMetadata,
          isMinted: res.isMinted,
        };
      },
    },
  );

  useAddSingleBlockverseContext(
    useHiraethObjId(mainnet.id, txnHash),
    useMemo((): HashLegacyBlockverseContext | undefined => {
      if (!hashMetadataRes.data) return undefined;

      return {
        type: 'hash-legacy',
        // txnHash,
        description: hashMetadataRes.data.bestName,
        source: null,
        temporal: null,
      };
    }, [hashMetadataRes.data]),
  );

  const state = useMemo(() => {
    return {
      txnHash,
      owner: isHashLegacyChain ? hashOwnerAndTokenIdRes.data?.owner : undefined,
      isHashLegacyChain,
      tokenId: hashOwnerAndTokenIdRes.data?.id,
    };
  }, [txnHash, isHashLegacyChain, hashOwnerAndTokenIdRes.data]);

  return (
    <HiraethHashContext.Provider value={state}>
      {children}
    </HiraethHashContext.Provider>
  );
};
