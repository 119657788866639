import { defineTokens } from '@pandacss/dev';
import { toTokens } from '~src/utils/panda/toTokens';

export const nudgePadding = {
  'increment': 8,
  'threeQuartersIncrement': 6,
  'halfIncrement': 4,
  'quarterIncrement': 2,
  'halfThinGap': 1,
  'nudge': 1,
  'thinGap': 2,
  'zero': 0,

  '8': 8, // for convenience
  '6': 6,
  '4': 4,
  '2': 2,
  '1': 1,
  '0': 0,
} as const;

export type NudgePadding = keyof typeof nudgePadding;

export const padding = {
  ...nudgePadding,
  'fourExLargestBox': 128,
  'threeExLargestBox': 96,
  'twoExLargestBox': 64,
  'onePointFiveExLargestBox': 48,
  'largestBox': 32,
  'largeBox': 24,
  'mediumBox': 20,
  'box': 16,
  'innerBox': 12,
  'boxGap': 12,
  'ten': 10,

  '10': 10, // for convenience
} as const;

export type Padding = keyof typeof padding;

export const spacing = defineTokens.spacing(toTokens(padding, (v) => `${v}px`));
