
import type { ExtAccount } from '@pob/shared';
import { NULL_ADDRESS } from '@pob/shared';
import { getWeb2UrlForWeb3Uri } from '@pob/web3-fetch';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import type { ExtUserSocialStats } from '~src/shared-apps/social/types/user';

export const useUserSocialStatsFromExtAccount = (extAccount?: ExtAccount): ExtUserSocialStats | undefined | null => {
  // const farcasterUserMetadataRes = trpc.user.farcaster.byFid.useQuery(
  //   {
  //     fid: extAccount?.userType === 'farcaster' ? extAccount.fid : 0,
  //   },
  //   {
  //     ...QUERY_LIVENESS.constant,
  //     enabled: extAccount?.userType === 'farcaster',
  //   },
  // );

  // const universalUserMetadataRes = trpc.user.universal.byAddress.useQuery(
  //   {
  //     address:
  //       extAccount?.userType === 'universal-profile'
  //         ? extAccount.address
  //         : NULL_ADDRESS,
  //   },
  //   {
  //     ...QUERY_LIVENESS.constant,
  //     enabled: extAccount?.userType === 'universal-profile',
  //   },
  // );

  const efpSocialStatsRes = trpc.user.ens.followerProtocol.useQuery(
    {
      address: extAccount?.userType === 'wallet' ? extAccount.address : NULL_ADDRESS,
    },
    {
      ...QUERY_LIVENESS.constant,
      enabled: extAccount?.userType === 'wallet',
    },
  );

  return useMemo(() => {
    return match(extAccount?.userType)
      .with('universal-profile', () => {
        return null;
      })
      .with('farcaster', () => {
        return null;
      })
      .with('wallet', () => {
        return efpSocialStatsRes.data;
      })
      .with(P.nullish, () => undefined)
      .exhaustive();
  }, [
    extAccount,
    efpSocialStatsRes.data,
  ]);
};
