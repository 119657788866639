import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type { SupportedCheckoutProjectId } from '../../../config/checkout';
import { getTransientCheckoutItemStateQueryKey } from '../query';
import type { CheckoutItemStateProps } from '../types/state';

export const useBatchMostUpToDateCheckoutItemStateMap = <
  P extends SupportedCheckoutProjectId,
>(
  objIds: BlockverseObjId<P>[],
  calculatedCheckoutItemState: Record<string, CheckoutItemStateProps>,
  // dateCalculated: number | undefined,
): Record<string, CheckoutItemStateProps> => {
  const transientCheckoutItemStateMapRes = useQueries<
    ((CheckoutItemStateProps & { id: string }) | null)[]
  >({
    queries: useMemo(() => {
      return objIds.map((objId) => {
        const queryKey = getTransientCheckoutItemStateQueryKey(objId);
        return {
          queryKey,
          queryFn: () => null,
          select: (d) => (!!d ? { ...d, id: objId } : null),
        } as const;
      });
    }, [objIds]),
  });

  return useMemo(() => {
    const stateMap = Object.fromEntries(
      Object.entries(calculatedCheckoutItemState).map(([id, state]) => {
        const query = transientCheckoutItemStateMapRes.find(
          (q) => (q.data as any)?.id === id,
        );
        if (!query) {
          return [id, state];
        }
        return [id, (query.data as CheckoutItemStateProps) ?? state];
      }),
    );

    return stateMap;
  }, [
    transientCheckoutItemStateMapRes?.[0]?.data,
    calculatedCheckoutItemState,
    // dateCalculated,
  ]);
};
