import { type FC, type ReactNode } from 'react';
import {
  BaseBlockverseItemRowDetails,
  type BaseBlockverseItemRowDetailsProps,
} from '~src/shared-apps/blockverse/components/items/details';
import { styled } from '~src/styled-system/jsx';
import type { CheckoutItemPriceProps } from '../../types';
import type { CheckoutItemStateProps } from '../../types/state';
import { CheckoutItemPriceState } from './priceState';

export type BaseCheckoutItemRowProps = BaseBlockverseItemRowDetailsProps & {
  priceProps: CheckoutItemPriceProps;
  state: CheckoutItemStateProps;
  handleState?: (state: CheckoutItemStateProps) => ReactNode;
};

export const BaseCheckoutItemRow: FC<BaseCheckoutItemRowProps> = ({
  name,
  visual,
  project,
  chainId,
  priceProps,
  state,
  handleState,
  slots,
}) => {
  return (
    <BaseCheckoutRowContainer>
      <BaseBlockverseItemRowDetails
        name={name}
        visual={visual}
        project={project}
        chainId={chainId}
        slots={slots}
      />
      <CheckoutItemPriceState
        checkoutItem={{
          priceProps,
          state,
        }}
      />
    </BaseCheckoutRowContainer>
  );
};

export const BaseCheckoutRowContainer = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 'increment',
    p: 'ten',
    bg: 'white',
    rounded: 'innerOfInnerBox',
  },
});
