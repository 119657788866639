import type { FC, ReactNode } from 'react';
import { Spinner } from 'react-activity';
import { P, match } from 'ts-pattern';
import { css } from '~src/styled-system/css';
import { colors } from '~src/theme/tokens/colors';
import { statefulColorsVariant } from '~src/theme/variants/stateful';
import { prettifyTokenValue } from '~src/utils/number/prettify';
import type { CheckoutItemPriceProps } from '../../types';
import type { CheckoutItemStateProps } from '../../types/state';
import {
  CheckoutPriceUnit,
  CheckoutRowStatusBadge,
  CheckoutRowValue,
} from './common';

export const CheckoutItemPriceState: FC<{
  checkoutItem?: {
    priceProps: CheckoutItemPriceProps;
    state: CheckoutItemStateProps;
  } | null;
  handleState?: (state: CheckoutItemStateProps) => ReactNode;
}> = ({ checkoutItem, handleState }) => {
  return (
    <>
      {match(checkoutItem?.state)
        .with(P.nullish, () => {
          return null;
        })
        .with({ type: 'minted' }, () => {
          return (
            <CheckoutRowStatusBadge
              css={css.raw(statefulColorsVariant.warning)}
            >
              Minted
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'coming-soon' }, () => {
          return (
            <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.300'}
              color={'gray.400'}
            >
              Coming Soon
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'pending' }, () => {
          return (
            <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.300'}
              color={'gray.400'}
            >
              Minting...
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'loading' }, () => {
          return <Spinner size={10} color={colors['gray.400']} />;
        })
        .with(
          {
            type: 'mintable',
          },
          () => {
            return (
              <CheckoutRowStatusBadge
                border={'1px solid'}
                borderColor={'gray.100'}
                color={'gray.700'}
              >
                <CheckoutRowValue>
                  {prettifyTokenValue(checkoutItem?.priceProps.price, 4)}{' '}
                  <CheckoutPriceUnit>
                    {checkoutItem?.priceProps.currency}
                  </CheckoutPriceUnit>
                </CheckoutRowValue>
              </CheckoutRowStatusBadge>
            );
          },
        )
        .with({ type: 'claim' }, () => {
          throw new Error('Claimed state not implemented');
          return <></>;
        })
        .exhaustive()}
      {!!checkoutItem?.state && (handleState?.(checkoutItem.state) ?? null)}
    </>
  );
};
