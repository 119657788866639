import type { Evm } from '@pob/shared';
import Link from 'next/link';
import { type FC, type ReactNode } from 'react';
import { A } from '~src/components/anchor';
import { DropdownItemA } from '~src/components/dropdown';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import {
  BaseBlockverseItemRowDetails,
  type BaseBlockverseItemRowDetailsProps,
} from '~src/shared-apps/blockverse/components/items/details';
import type { CartItem } from '~src/shared-apps/cart/stores';
import { CheckoutRowStatusBadge } from '~src/shared-apps/checkout/components/item/common';
import { CheckoutItemPriceState } from '~src/shared-apps/checkout/components/item/priceState';
import type { CheckoutItem } from '~src/shared-apps/checkout/types';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import { css } from '~src/styled-system/css';
import { Box, VStack, styled } from '~src/styled-system/jsx';
import { CartItemRowActions } from './actions';

export type BaseCartItemRowProps = BaseBlockverseItemRowDetailsProps & {
  handleState?: (state: CheckoutItemStateProps) => ReactNode;
  href?: string;
  cartItem: CartItem;
  checkoutItem: CheckoutItem | null;
};

export type DropdownBaseCartItemRowProps = Omit<
  BaseCartItemRowProps,
  'cartItem' | 'checkoutItem'
>;

export const DropdownBaseCartItemRow: FC<DropdownBaseCartItemRowProps> = ({
  name,
  visual,
  project,
  chainId,
  slots,
  href,
}) => {
  return (
    <Link href={href ?? ('#' as any)} passHref legacyBehavior>
      <DropdownItemA target="_blank">
        <BaseDropdownRowContainer>
          <BaseBlockverseItemRowDetails
            name={name}
            visual={visual}
            project={project}
            chainId={chainId}
            slots={{
              ...slots,
              name: css.raw(
                {
                  fontSize: 'p.display.caption',
                  fontWeight: 'display.bold',
                },
                slots?.name,
              ),
              caption: css.raw(
                {
                  fontSize: 'p.display.caption',
                  color: 'gray.400',
                },
                slots?.caption,
              ),
              visualContainer: css.raw(
                {
                  height: 'icon.badge',
                },
                slots?.visualContainer,
              ),
            }}
          />
          {/* {match(state)
        .with({ type: 'minted' }, () => {
          return (
            <CheckoutRowStatusBadge
              css={css.raw(statefulColorsVariant.warning)}
            >
             Minted 
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'coming-soon' }, () => {
          return (
            <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.300'}
              color={'gray.400'}
            >
              Coming Soon
            </CheckoutRowStatusBadge>
          );
        })
      .with({ type: 'pending' }, () => {
          return (
            <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.300'}
              color={'gray.400'}
            >
              Minting...
            </CheckoutRowStatusBadge>
          );
        })
        .with({ type: 'loading' }, () => {
          return <Spinner size={10} color={colors['gray.400']} />;
        })
        .with(
          {
            type: 'mintable',
          },
          () => {
            return (
              <CheckoutRowStatusBadge
              border={'1px solid'}
              borderColor={'gray.100'}
              color={'gray.700'}
              >

              <CheckoutRowValue>
                {prettifyTokenValue(price.price, 4)}{' '}
                <CheckoutPriceUnit>{price.currency}</CheckoutPriceUnit>
              </CheckoutRowValue>
              </CheckoutRowStatusBadge>
            );
          },
        )
        .exhaustive()}
      {handleState?.(state) ?? null} */}
        </BaseDropdownRowContainer>
      </DropdownItemA>
    </Link>
  );
};

export const BaseCartItemRow: FC<BaseCartItemRowProps> = ({
  name,
  visual,
  project,
  chainId,
  // price,
  // state,
  // handleState,
  slots,
  href,
  cartItem,
  checkoutItem,
}) => {
  // TODO: added by should be a fair bit more generic and handled better
  const bestAddedByName = useBestDisplayName(
    cartItem.addedBy as unknown as Evm.Address,
  );

  return (
    <BaseCartItemRowContainer>
      <Link href={href ?? ('#' as any)} passHref legacyBehavior>
        <A display={'block'} textDecoration={'none'} target="_blank">
          <BaseBlockverseItemRowDetails
            name={name}
            visual={visual}
            project={project}
            caption={
              cartItem.addedBy ? (
                <>Added By {bestAddedByName}</>
              ) : (
                <>Added by anon</>
              )
            }
            chainId={chainId}
            slots={{
              ...slots,
              container: css.raw(
                {
                  alignItems: 'flex-start',
                  gap: 'innerBox',
                },
                slots?.container,
              ),
              name: css.raw(
                {
                  fontSize: 'h4-5',
                  fontWeight: 'display.normal',
                },
                slots?.name,
              ),
              caption: css.raw(
                {
                  fontSize: 'p.display.caption',
                  color: 'gray.400',
                  mt: 'halfIncrement',
                },
                slots?.caption,
              ),
              visualContainer: css.raw(
                {
                  height: '[6rem]',
                },
                slots?.visualContainer,
                {
                  aspectRatio: '1:1',
                },
              ),
              chainIcon: css.raw(
                {
                  height: 'icon.eighteen',
                  width: 'icon.eighteen',
                },
                slots?.chainIcon,
              ),
            }}
          />
        </A>
      </Link>
      <VStack justifyContent={'space-between'} alignItems={'flex-end'}>
        <Box>
          <CheckoutItemPriceState checkoutItem={checkoutItem} />
        </Box>
        <CartItemRowActions
          objId={cartItem.objId}
          isSavedForLater={cartItem.isSavedForLater}
        />
      </VStack>
    </BaseCartItemRowContainer>
  );
};

export const BaseDropdownRowContainer = styled('div', {
  base: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 'increment',
    p: 'zero',
    // bg: 'white',
  },
});

export const BaseCartItemRowContainer = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    minWidth: 'zero',
    bg: 'white',
    p: 'box',
    rounded: 'innerBox',
    gap: {
      base: 'box',
      sm: 'largestBox',
    },
  },
});

const CartRowStatusBadge = CheckoutRowStatusBadge;

// export const CheckoutRowStatusBadge = styled('div', {
//   base: {
//     borderRadius: 'innerOfSquaredButton',
//     padding: 'threeQuartersIncrement',
//     width: 'fit-content',
//     textAlign: 'right',
//     fontSize: 'p.display.caption',
//   },
// });

// const CheckoutRowValue = styled('p', {
//   base: {
//     fontSize: 'p.display',
//     lineHeight: 'singleLine',
//     fontWeight: 'display.bold',
//   },
// });
