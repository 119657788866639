import type { SupportedBlockverseChainId } from '@pob/blockverse';
import { useCallback, useMemo } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { getChainNetwork } from '~src/utils/chain';
import { route } from '~src/utils/routes';
import { useBlockverseChainIds } from '../../blockverse/hooks/useBlockverseChainIds';

export const useLucky = () => {
  const searchChainIds = useBlockverseChainIds();
  const randomHiraethRes = trpc.useQueries((t) => {
    return searchChainIds.map((chainId) => {
      return t.hiraeth.global.random({
        // HACKY
        chainId: chainId as SupportedBlockverseChainId<'hiraeth'>,
      });
    });
  });

  const randomHiraethHrefs = useMemo(() => {
    const hrefs: string[] = [];
    for (const res of randomHiraethRes) {
      if (!res) {
        continue;
      }
      if (!res.data) {
        continue;
      }
      const { recentTxns, threeMonthsAgoTxns, oneYearAgoTxns, chainId } =
        res.data;
      hrefs.push(
        ...[...recentTxns, ...threeMonthsAgoTxns, ...oneYearAgoTxns].map(
          (txnHash) =>
            route({
              pathname: '/hiraeth/[chainNetwork]/[txnHash]',
              query: {
                chainNetwork: getChainNetwork(chainId),
                txnHash,
              },
            }),
        ),
      );
    }
    return hrefs;
  }, [randomHiraethRes]);

  return useCallback(() => {
    return randomHiraethHrefs[
      Math.floor(Math.random() * randomHiraethHrefs.length)
    ];
  }, [randomHiraethHrefs]);
};
