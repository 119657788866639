import Link from 'next/link';
import { FC } from 'react';
import { css } from '~src/styled-system/css';
import { Flex, MobileHide, styled } from '~src/styled-system/jsx';
import {
  DISCORD_LINK,
  GITHUB_LINK,
  NEWSLETTER_LINK,
  THREADS_LINK,
  TWITTER_LINK,
  WARPCAST_LINK,
  YOUTUBE_LINK,
} from '../../constants';
import { baseNavAnchorCss } from '../anchor';
import { LogoIcon } from '../icon/logo';
import { WidthContainer } from '../layout';
import { LightenSpan, WeaklyLightenSpan } from '../text/spans';
export const Footer: FC = () => {
  return (
    <BlockContainer>
      <WidthContainer width="xl" padMobile>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <div>
            <LogoFooterAnchor>
              <LogoIcon className={logoFooterAnchorSvg} />
              Pob
              <WeaklyLightenSpan style={{ paddingLeft: 6 }}>
                Studio
              </WeaklyLightenSpan>
            </LogoFooterAnchor>
          </div>
          <MobileHide
            // hideBelow={'xs'}
            breakPt={'sm'}
          >
            <LogoFooterAnchor>
              <LightenSpan>aka Proof of Beauty Studio</LightenSpan>
            </LogoFooterAnchor>
          </MobileHide>
        </Flex>
        <FooterLinksGrid>
          <FooterLinksColumn>
            <FooterLinksTitle>Studio</FooterLinksTitle>
            <Link href={'/about'} passHref legacyBehavior>
              <FooterAnchor>About Us</FooterAnchor>
            </Link>
            <Link href={'/vision'} passHref legacyBehavior>
              <FooterAnchor>Vision</FooterAnchor>
            </Link>
            <Link href={'/work'} passHref legacyBehavior>
              <FooterAnchor>Our Work</FooterAnchor>
            </Link>
            <FooterAnchor href={'/assets/press_kit.zip'}>
              Press Kit
            </FooterAnchor>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksTitle>For Collectors</FooterLinksTitle>
            <Link href={'/preserve'} passHref legacyBehavior>
              <FooterAnchor>Preserve Initiative</FooterAnchor>
            </Link>
            <Link href={'/collector-std'} passHref legacyBehavior>
              <FooterAnchor>Collector Std.</FooterAnchor>
            </Link>
            <Link href={'/license'} passHref legacyBehavior>
              <FooterAnchor>NFT license</FooterAnchor>
            </Link>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksTitle>For Developers</FooterLinksTitle>
            <FooterAnchor href={GITHUB_LINK} target={'_blank'}>
              Github
            </FooterAnchor>
            <Link href={'/contracts'} passHref legacyBehavior>
              <FooterAnchor>Contracts</FooterAnchor>
            </Link>
          </FooterLinksColumn>
          <FooterLinksColumn>
            <FooterLinksTitle>Social</FooterLinksTitle>
            <FooterAnchor href={NEWSLETTER_LINK} target={'_blank'}>
              Newsletter
            </FooterAnchor>
            <FooterAnchor href={TWITTER_LINK} target={'_blank'}>
              Twitter
            </FooterAnchor>
            <FooterAnchor href={WARPCAST_LINK} target={'_blank'}>
              Warpcast
            </FooterAnchor>
            <FooterAnchor href={THREADS_LINK} target={'_blank'}>
              Threads
            </FooterAnchor>
            <FooterAnchor href={DISCORD_LINK} target={'_blank'}>
              Discord
            </FooterAnchor>
            <FooterAnchor href={YOUTUBE_LINK} target={'_blank'}>
              Youtube
            </FooterAnchor>
            {/* <FooterAnchor>Newsletter</FooterAnchor> */}
          </FooterLinksColumn>
        </FooterLinksGrid>
        <BottomRow>
          <BottomAnchorRow>
            <Link href={'/terms-of-use'} passHref legacyBehavior>
              <BottomFooterAnchor>Terms of use</BottomFooterAnchor>
            </Link>
            <Link href={'/privacy'} passHref legacyBehavior>
              <BottomFooterAnchor>Privacy Policy</BottomFooterAnchor>
            </Link>
          </BottomAnchorRow>
          <BottomFooterText>© 2024 Proof of Beauty, LLC.</BottomFooterText>
        </BottomRow>
      </WidthContainer>
    </BlockContainer>
  );
};

const FooterLinksGrid = styled('div', {
  base: {
    display: 'grid',
    paddingTop: 'largestBox',
    gridTemplateColumns: '2',
    paddingBottom: 'largestBox',
    gridRowGap: 'largestBox',
    gridColumnGap: 'box',
    xs: {
      paddingBottom: 'twoExLargestBox',
      gridRowGap: 'box',
      gridTemplateColumns: '4',
    },
    md: {
      gridTemplateColumns: '6',
    },
  },
});

const FooterLinksColumn = styled('div', {
  base: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'increment',
  },
});

const FooterLinksTitle = styled('p', {
  base: {
    color: 'gray.400',
    fontFamily: 'display',
    fontSize: '[13px]',
    fontWeight: 'display.bold',
  },
});

const FooterAnchor = styled('a', {
  base: css.raw(baseNavAnchorCss, {
    fontSize: '[13px]',
    display: 'block',
    fontWeight: 'display.bold',
    color: 'gray.600',
    transitionType: 'default',
    transitionProperty: 'color',
    _hover: {
      color: 'gray.400',
    },
  }),
});

const LogoFooterAnchor = styled('a', {
  base: css.raw(baseNavAnchorCss, {
    display: 'flex',
    alignItems: 'center',
    fontSize: '[20px]',
    fontWeight: 'display.superBold',
    color: 'gray.400',
  }),
});

const logoFooterAnchorSvg = css({
  marginRight: 'increment',
  width: '[32px]',
  height: '[32px]',
  display: 'block',
  fill: 'gray.400',
});

const BlockContainer = styled('div', {
  base: {
    position: 'relative',
    background: 'gray.200',
    paddingTop: {
      base: 'largestBox',
      xs: 'onePointFiveExLargestBox',
    },
  },
});

const BottomFooterAnchor = styled('a', {
  base: css.raw(baseNavAnchorCss, {
    fontSize: 'legacyLabel',
    tran: 'default',
    transitionProperty: '[color]',
    color: 'gray.600',
    _hover: {
      color: 'gray.400',
    },
  }),
});

const BottomFooterText = styled('p', {
  base: {
    fontFamily: 'display',
    fontSize: 'legacyLabel',
    color: 'gray.400',
    fontWeight: 'display.bold',
  },
});

const BottomRow = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 'largestBox',
    borderTop: '1px solid',
    borderColor: 'darken',
  },
});

const BottomAnchorRow = styled('div', {
  base: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 'box',
  },
});
