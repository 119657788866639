import { HIRAETH_ART_RATIO } from '@hiraeth/art';
import { CHAIN_ID_TO_NETWORK_LOOKUP } from '@protocol/chains';
import { EvmDeployment, ProjectId } from '@protocol/deployments';
import { format } from 'date-fns/format';
import { PrettyNftMetadata } from '../../shared-apps/nft/types';
import { ProjectMetadata } from '../../types/project/metadata';

export const PROJECT_METADATA = {
  ['hiraeth']: {
    name: 'Hiraeth',
    href: '/hiraeth',
    id: 'hiraeth',
    index: 1,
    state: 'alpha',
    timeline: `${format(new Date(2024, 5, 5), 'LLL yyyy')} - Current`,
    collections: {
      ['terrene-hiraeth']: {
        name: 'Hiraeth',
        symbol: 'hiraeth',
        openseaName: 'terrene-hiraeth',
        id: 'hiraeth',
        // isOpenseaSlugChainSpecific: true,
        getEvmCollectionAddress: (d: EvmDeployment) => d.projects.hiraeth?.nft,
        getHref: (metadata: PrettyNftMetadata) => {
          const { token, attributes } = metadata;
          if (!attributes) return undefined;
          if (!token?.chainId) return undefined;

          const txnAttribute = attributes.find(
            (a) => a.traitType === 'Txn Hash',
          );

          if (!txnAttribute) return undefined;

          return `/hiraeth/${CHAIN_ID_TO_NETWORK_LOOKUP[token.chainId]}/${txnAttribute.value as string}`;
        },
        visualAspectRatio: HIRAETH_ART_RATIO,
        isErc1155: false,
        isMetadataStatic: false,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: null,
      },
    },
  },
  ['dead']: {
    name: 'Eth Is Dead',
    timeline: 'Future',
    state: 'pre-alpha',
    href: '/dead',
    id: 'dead',
    index: undefined,
    collections: {},
  },
  ['evenOdd']: {
    name: 'Even v. Odd',
    href: '/even-odd',
    id: 'evenOdd',
    state: 'finished',
    index: undefined,
    timeline: format(new Date(2023, 7, 5), 'LLL yyyy'),
    collections: {
      ['even-side']: {
        name: 'Even',
        symbol: 'EVEN',
        openseaName: 'even-side',
        id: 'even-side',
        getEvmCollectionAddress: (d: EvmDeployment) =>
          d.projects.evenOdd?.evenNft,
        getHref: null,
        visualAspectRatio: 1,
        isErc1155: false,
        isMetadataStatic: true,
        tokenURI: 'on-chain-optimized',
        alchemyOptions: {
          shouldNotFetchWithMetadata: true,
        },
        attributeDescriptors: null,
      },
      ['odd-side']: {
        name: 'Odd',
        symbol: 'ODD',
        openseaName: 'odd-side',
        id: 'odd-side',
        getEvmCollectionAddress: (d: EvmDeployment) =>
          d.projects.evenOdd?.oddNft,
        getHref: null,
        visualAspectRatio: 1,
        isErc1155: false,
        isMetadataStatic: true,
        tokenURI: null,
        alchemyOptions: {
        },
        attributeDescriptors: null,
      },
    },
  },
  ['merge']: {
    name: 'Merge',
    href: '/merge',
    state: 'finished',
    id: 'merge',
    index: undefined,
    timeline: `${format(new Date(2022, 7, 5), 'LLL yyyy')} - ${format(
      new Date(2022, 8, 5),
      'LLL yyyy',
    )}`,
    collections: {
      ['merge']: {
        name: 'Merge',
        symbol: 'MERGE',
        openseaName: 'epics-merge',
        id: 'merge',
        getEvmCollectionAddress: (d: EvmDeployment) => d.projects.merge?.nft,
        getHref: null,
        visualAspectRatio: 1,
        isErc1155: false,
        isMetadataStatic: false,
        tokenURI: 'on-chain-optimized',
        alchemyOptions: {
          shouldNotFetchWithMetadata: true,
        },
        attributeDescriptors: {
          Background: {
            description:
              '**Background** defines the landscape that the statue is found in.',
          },
          Pedestal: {
            description: `**Pedestal** defines the structure that the statue sits on.`,
          },
          ['Statue Material']: {
            description: `**Statue Material** defines the statue's material which reflects the color and sheen of the statue.`,
          },
          ['Inherent Mana']: {
            description: `**Inherent Mana** defines the base mana contained in this statue.`,
          },
          ['Boosted Mana']: {
            description: `**Boosted Mana** defines the additional mana added to the statue by the *Mana Boost Ritual*.`,
          },
          ['Channeled Mana']: {
            description: `**Channeled Mana** defines any additional mana received from other statues channeling mana to this statue.`,
          },
          ['Usable Mana']: {
            description: `**Usable Mana** defines all the mana available to use for rituals.`,
          },
          ['Channelabe Mana']: {
            description: `**Channelabe Mana** defines all the mana available to be permanently transferred to another statue.`,
          },
          ['Transmutation style']: {
            description: `**Transmutation style** defines the modifier applied by the *transmutation ritual*.`,
          },
          ['Spell Strength']: {
            description: `**Spell Strength** defines the strength of **Transmutation style** applied by the *transmutation ritual*.`,
          },
        },
      },
    },
  },
  ['piano']: {
    name: 'Public Piano',
    href: '/piano',
    id: 'piano',
    state: 'finished',
    index: undefined,
    timeline: format(new Date(2021, 9, 5), 'LLL yyyy'),
    collections: {
      ['public-piano']: {
        name: 'Public Piano',
        symbol: 'PIANO',
        openseaName: 'public-piano',
        id: 'public-piano',
        getEvmCollectionAddress: (d: EvmDeployment) => d.projects.piano?.nft,
        getHref: null,
        visualAspectRatio: null,
        isErc1155: false,
        isMetadataStatic: false,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: null,
      },
    },
  },
  ['london']: {
    name: 'London',
    href: '/london',
    id: 'london',
    index: undefined,
    state: 'finished',
    timeline: `${format(new Date(2021, 6, 5), 'LLL yyyy')} - ${format(
      new Date(2022, 11, 5),
      'LLL yyyy',
    )}`,
    collections: {
      gift: {
        getHref: null,
        name: 'London Gift',
        symbol: 'LONDON GIFT',
        openseaName: 'london-gift-v2',
        id: 'london-gift',
        getEvmCollectionAddress: (d: EvmDeployment) =>
          d.projects.london?.gift?.nft,
        visualAspectRatio: 1,
        isErc1155: false,
        isMetadataStatic: true,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: {
          seed: {
            description:
              '**Seed** is the random source for generating this London Gift artwork.',
          },
          tileSet: {
            description: `**Tile Sets** are the building blocks of the London Gift artwork. There are 13 different **tile sets**.`,
          },
          framed: {
            description: `**Framed** defines if a solid border of color encapsulates the artwork.`,
          },
          composition: {
            description: `**Composition** defines how the tiling generative art algo is applied.`,
          },
          rarity: {
            description: `**Rarity** dictates the general rarity of this London Gift. **Rarity** is used to select which **Tile Set** can be used in this artwork.`,
          },
          complexity: {
            description: `**Complexity** defines how densely packed the London Gift is with tiles.`,
          },
          name: {
            description: `**Name** defines the length of the name of the NFT.`,
          },
        },
      },
      embers: {
        getHref: null,
        name: 'London Embers',
        symbol: 'LONDON EMBERS',
        openseaName: 'london-embers',
        id: 'london-embers',
        getEvmCollectionAddress: (d: EvmDeployment) =>
          d.projects.london?.embers?.nft,
        visualAspectRatio: 1,
        isErc1155: false,
        isMetadataStatic: true,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: {
          gridSize: {
            description:
              '**Grid Size** defines how densely packed the generative art is.',
          },
          animationDuration: {
            description: `**Animation Duration** defines the length of the animation sequence.`,
          },
          animationStyle: {
            description: `**Animation Style** defines the animation 'velocity' of the artwork (How much happens in a single frame).`,
          },
          provenance: {
            description: `**Provenance** defines how this London Embers was minted.`,
          },
        },
      },
    },
  },
  ['hash']: {
    name: 'Hash',
    href: '/hash',
    id: 'hash',
    state: 'finished',
    index: undefined,
    timeline: `${format(new Date(2021, 0, 5), 'LLL yyyy')} - ${format(
      new Date(2024, 5, 5),
      'LLL yyyy',
    )}`,
    collections: {
      'hash': {
        name: 'Hash',
        symbol: 'HASH',
        openseaName: 'hash-by-pob',
        id: 'hash',
        getEvmCollectionAddress: (d: EvmDeployment) => d.projects.hash?.nft,
        visualAspectRatio: 5 / 8,
        isErc1155: false,
        isMetadataStatic: false,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: {
          season: {
            description:
              'The season of the **HASH piece**. Seasons can be identified by the unique generative art style of each season.\n\n**Genesis** is the very first season of hash. **Saga** and **Hunt** are second and third seasons, respectively.',
          },
          tx: {
            description:
              'This is the transaction visualized in the **HASH piece** and only TIED to this **HASH piece**. No txn can be represented by two **HASH pieces**.',
          },
        },
        getHref: (metadata: PrettyNftMetadata) => {
          const { token, attributes } = metadata;
          if (!attributes) return undefined;
          if (!token?.chainId) return undefined;

          const txnAttribute = attributes.find((a) => a.traitType === 'Tx');

          if (!txnAttribute) return undefined;

          return `/hash/${txnAttribute.value as string}`;
        },

        // shouldUseSource: (tokenId: string) => {
        //   const season = getSeasonFromTokenId(
        //     '0x' + BigInt(tokenId).toString(16),
        //   );
        //   return season === 'hunt';
        // },
      },
      'hash-legacy': {
        name: 'Hash Legacy',
        symbol: 'HASH LEGACY',
        openseaName: 'proof-of-beauty',
        id: 'hash-legacy',
        getEvmCollectionAddress: (d: EvmDeployment) =>
          d.projects.hash?.legacyNft,
        visualAspectRatio: 5 / 8,
        attributeDescriptors: {
          season: {
            description:
              'The season of the **HASH piece**. Seasons can be identified by the unique generative art style of each season.\n\n**Genesis** is the very first season of hash. **Saga** and **Hunt** are second and third seasons, respectively.',
          },
          tx: {
            description:
              'This is the transaction visualized in the **HASH piece** and only TIED to this **HASH piece**. No txn can be represented by two **HASH pieces**.',
          },
        },
        isErc1155: true,
        isMetadataStatic: true,
        tokenURI: null,
        alchemyOptions: {},
        getHref: (metadata: PrettyNftMetadata) => {
          const { token, attributes } = metadata;
          if (!attributes) return undefined;
          if (!token?.chainId) return undefined;

          const txnAttribute = attributes.find((a) => a.traitType === 'Tx');

          if (!txnAttribute) return undefined;

          return `/hash/${txnAttribute.value as string}`;
        },
      },
    },
  },
  ['exeo']: {
    name: 'Exeo',
    href: '/exeo',
    id: 'exeo',
    state: 'soon',
    index: undefined,
    timeline: `${format(new Date(2023, 6, 5), 'LLL yyyy')} - Current`,
    collections: {
      ['exeo']: {
        getHref: null,
        name: 'Exeo',
        symbol: 'exeo',
        openseaName: 'terrene-exeo',
        id: 'exeo',
        getEvmCollectionAddress: (d: EvmDeployment) => d.projects.exeo?.nft,
        visualAspectRatio: 3086 / 1342,
        isErc1155: false,
        isMetadataStatic: false,
        tokenURI: null,
        alchemyOptions: {},
        attributeDescriptors: null,
      },
    },
  },
  ['example']: undefined,
  ['exampleWithIpfs']: undefined,
} as const satisfies Record<ProjectId, ProjectMetadata<string> | undefined>;
