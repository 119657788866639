import { motion } from 'framer-motion';
import { styled } from '~src/styled-system/jsx';

export const CrestImage = styled(motion.img, {
  base: {
    height: 'crestImg',
    imageRendering: '[pixelated]',
    aspectRatio: 'crest',
    display: 'block',
  },
});
