import type { ExtAccountType } from '@pob/shared';
import { FC } from 'react';
import { ColoredFarcasterIcon } from '~src/components/icon/farcaster';
import { UniversalProfileIcon } from '~src/components/icon/universal-profiles';

export const SOCIAL_PlATFORM_ICONS = {
  'farcaster': (props: { className?: string }) => (
    <ColoredFarcasterIcon {...props} />
  ),
  'universal-profile': (props: { className?: string }) => (
    <UniversalProfileIcon {...props} />
  ),
  'wallet': null,
  // <WalletIcon {...props} />
} satisfies Record<ExtAccountType, FC<{ className?: string }> | null>;
