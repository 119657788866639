import { useMemo } from 'react';
import { QUERY_LIVENESS } from '~src/constants/query';
import { type BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { serializeBlockverseObjId } from '~src/shared-apps/blockverse/utils/serialize';
import type { QueryStateOptions } from '~src/types/query';
import { useBatchHiraethItemCheckoutState } from './useBatchHiraethItemCheckoutState';

export const useHiraethItemCheckoutState = (
  blockverseObjId: BlockverseObjId<'hiraeth'> | undefined,
  queryOptions: QueryStateOptions = QUERY_LIVENESS['current-page'],
) => {
  const checkoutStateMap = useBatchHiraethItemCheckoutState(
    blockverseObjId ? [blockverseObjId] : [],
    {
      ...queryOptions,
      enabled: !!blockverseObjId && (queryOptions.enabled ?? true),
    },
  );
  return useMemo(() => {
    if (!blockverseObjId) {
      return undefined;
    }
    const serializedObjId = serializeBlockverseObjId(blockverseObjId);
    return checkoutStateMap[serializedObjId];
  }, [checkoutStateMap, blockverseObjId]);
};
