import { motion, useAnimationControls } from 'framer-motion';
import Link from 'next/link';
import pluralize from 'pluralize';
import { FC, useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
import { baseButtonCss } from '~src/components/button';
import {
  DropdownItemA,
  DropdownItemGroup,
  DropdownItemGroupGrid,
  DropdownLabel,
} from '~src/components/dropdown';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import BasketShopping from '~src/components/icon/basket-shopping';
import { Popover } from '~src/components/popover';
import { Portal } from '~src/components/portal';
import { InheritSpan } from '~src/components/text/spans';
import { useMounted } from '~src/hooks/ui/useMounted';
import { useCart } from '~src/shared-apps/cart/stores';
import { css } from '~src/styled-system/css';
import { Box, styled } from '~src/styled-system/jsx';
import { styledMotionOpts } from '~src/utils/panda/motion';
import { DropdownCartItemRow } from '../item';

export const HeaderCartStatus: FC = () => {
  const cart = useCart((s) => {
    return s.cart;
  });
  const numInCart = useMemo(() => {
    return Object.values(cart).length;
  }, [cart]);

  const previousNumInCart = usePrevious(numInCart);

  const indicatorControls = useAnimationControls();

  useEffect(() => {
    if (!numInCart) {
      return;
    }
    if (previousNumInCart !== undefined && previousNumInCart < numInCart) {
      const animate = async () => {
        await indicatorControls.start({
          scale: 1.1,
          transition: { duration: 0.2 },
        });
        await indicatorControls.start({
          scale: 1.0,
          transition: { duration: 0.15 },
        });
      };
      animate();
    }
  }, [previousNumInCart, numInCart]);

  const isMounted = useMounted();

  return (
    <Popover.Root portalled positioning={{ placement: 'bottom-end' }}>
      <Popover.Trigger asChild>
        <styled.div position={'relative'}>
          <CartStatusButton>
            <BasketShopping
              className={css({
                width: '[17px]',
                height: '[17px]',
                display: 'block',
              })}
            />
          </CartStatusButton>
          {isMounted && !!numInCart && (
            <CartItemIndicator animate={indicatorControls}>
              {numInCart}
            </CartItemIndicator>
          )}
        </styled.div>
      </Popover.Trigger>
      <Portal>
        <Popover.Positioner>
          <Popover.Content style={{ width: '250px' }}>
            <CartStatusDropdownContent />
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  );
};

const CartStatusDropdownContent: FC = () => {
  const projectCart = useCart((s) => {
    return s.cart;
  });
  const cartItems = useMemo(() => {
    return Object.values(projectCart);
  }, [projectCart]);

  const previewCartItems = useMemo(() => {
    return cartItems.slice(0, 3);
  }, [cartItems]);

  const numInCart = useMemo(() => {
    return cartItems.length;
  }, [cartItems]);

  return (
    <DropdownItemGroupGrid>
      <DropdownItemGroup minWidth={'zero'}>
        <DropdownLabel
          fontSize={'p.display'}
          color={'gray.700'}
          pb={'increment'}
          flx={'flexRowEnds'}
        >
          Cart
          <InheritSpan
            fontSize={'p.display.caption'}
            fontWeight={'display.normal'}
            color={'gray.400'}
          >
            {numInCart} {pluralize('item', numInCart)}
          </InheritSpan>
        </DropdownLabel>
        {numInCart === 0 && (
          <Box
            flx={'flexCenter'}
            color={'gray.300'}
            py={'largestBox'}
            fontSize={'p.display.caption'}
          >
            No items in cart
          </Box>
        )}
        {previewCartItems.map((item) => {
          return (
            <DropdownCartItemRow
              key={`cart-item-row-${item.objId}`}
              item={item}
              checkoutItem={null}
            />
          );
        })}
        <Link href={'/cart'} passHref legacyBehavior>
          <DropdownItemA
            mt={'halfIncrement'}
            textAlign={'center'}
            fontWeight={'display.bold'}
            bg={'darken.05'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            View full cart
            <UpRightArrowIcon
              className={css({
                width: 'icon.fourteen',
                height: 'icon.fourteen',
                display: 'block',
              })}
            />
          </DropdownItemA>
        </Link>
      </DropdownItemGroup>
    </DropdownItemGroupGrid>
  );
};

const CartStatusButton = styled('button', {
  base: css.raw(baseButtonCss, {
    flx: 'flexCenter',
    hoverOverlay: 'darken',
    height: 'navIsland',
    width: 'navIsland',
    rounded: 'squaredButton',
    color: 'gray.600',
    bg: 'gray.100',
  }),
});

const CartItemIndicator = styled(
  motion.div,
  {
    base: {
      flx: 'flexCenter',
      position: 'absolute',
      bottom: 'zero',
      left: 'zero',
      minWidth: '[18px]',
      height: '[18px]',
      rounded: 'pill',
      bg: 'gray.600',
      fontSize: '[10px]',
      color: 'white',
      fontWeight: 'display.normal',
      pointerEvents: 'none',
      boxShadow: '[0 0 18px 6px rgba(0,0,0,0.2)]',
    },
  },
  styledMotionOpts,
);
