import { MoreHorizontal } from 'lucide-react';
import type { FC } from 'react';
import { SquareIconButton } from '~src/components/button';
import {
  DropdownItemButton,
  DropdownItemGroup,
  DropdownItemGroupGrid,
} from '~src/components/dropdown';
import { Popover } from '~src/components/popover';
import type { SupportedCheckoutProjectId } from '~src/config/checkout';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { useCart } from '~src/shared-apps/cart/stores';

export const CartItemRowActions: FC<{
  objId: BlockverseObjId<SupportedCheckoutProjectId>;
  isSavedForLater?: boolean;
}> = ({ objId, isSavedForLater }) => {
  const removeFromCart = useCart((s) => s.removeFromCart);
  const updateCartState = useCart((s) => s.updateCartState);

  const handleRemove = () => {
    removeFromCart(objId);
  };
  const handleSaveForLater = () => {
    updateCartState(objId, {
      isSavedForLater: !isSavedForLater,
    });
  };
  return (
    <Popover.Root positioning={{ placement: 'bottom-end' }}>
      <Popover.Trigger asChild>
        <SquareIconButton>
          <MoreHorizontal />
        </SquareIconButton>
      </Popover.Trigger>
      <Popover.Positioner>
        <Popover.Content>
          <DropdownItemGroupGrid>
            <DropdownItemGroup>
              <DropdownItemButton onClick={handleSaveForLater}>
                {isSavedForLater ? 'Add To Cart' : 'Save For Later'}
              </DropdownItemButton>
              <DropdownItemButton color={'error.strong'} onClick={handleRemove}>
                Remove
              </DropdownItemButton>
            </DropdownItemGroup>
          </DropdownItemGroupGrid>
        </Popover.Content>
      </Popover.Positioner>
    </Popover.Root>
  );
};
