import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { useMemo } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';

export const useHiraethObjId = (
  chainId: SupportedBlockverseChainId<'hiraeth'>,
  txnHash: Evm.TxnHash | undefined,
) => {
  return useMemo(
    () =>
      txnHash
        ? (['hiraeth', chainId, txnHash] as BlockverseObjId<'hiraeth'>)
        : undefined,
    [chainId, txnHash],
  );
};
