import { styled } from '~src/styled-system/jsx';

export const Increment = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.increment} * 0.5)]',
          xs: 'increment',
        },
      },
      false: {
        height: 'increment',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const BoxGap = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.boxGap} * 0.5)]',
          xs: 'boxGap',
        },
      },
      false: {
        height: 'boxGap',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const HalfIncrement = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.halfIncrement} * 0.5)]',
          xs: 'halfIncrement',
        },
      },
      false: {
        height: 'halfIncrement',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const QuarterIncrement = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.quarterIncrement} * 0.5)]',
          xs: 'quarterIncrement',
        },
      },
      false: {
        height: 'quarterIncrement',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const Ten = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.ten} * 0.5)]',
          xs: 'ten',
        },
      },
      false: {
        height: 'ten',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const InnerBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.innerBox} * 0.5)]',
          xs: 'innerBox',
        },
      },
      false: {
        height: 'innerBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const Box = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.box} * 0.5)]',
          xs: 'box',
        },
      },
      false: {
        height: 'box',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const MediumBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.mediumBox} * 0.5)]',
          xs: 'mediumBox',
        },
      },
      false: {
        height: 'mediumBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const LargeBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.largeBox} * 0.5)]',
          xs: 'largeBox',
        },
      },
      false: {
        height: 'largeBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const LargestBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.largestBox} * 0.5)]',
          xs: 'largestBox',
        },
      },
      false: {
        height: 'largestBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const OnePointFiveExLargestBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.onePointFiveExLargestBox} * 0.5)]',
          xs: 'onePointFiveExLargestBox',
        },
      },
      false: {
        height: 'onePointFiveExLargestBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const TwoExLargestBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.twoExLargestBox} * 0.5)]',
          xs: 'twoExLargestBox',
        },
      },
      false: {
        height: 'twoExLargestBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const ThreeExLargestBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.threeExLargestBox} * 0.5)]',
          xs: 'threeExLargestBox',
        },
      },
      false: {
        height: 'threeExLargestBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const FourExLargestBox = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.fourExLargestBox} * 0.5)]',
          xs: 'fourExLargestBox',
        },
      },
      false: {
        height: 'fourExLargestBox',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});

export const ThinGap = styled('div', {
  base: {},
  variants: {
    isMobile: {
      true: {
        height: {
          base: '[calc({sizes.thinGap} * 0.5)]',
          xs: 'thinGap',
        },
      },
      false: {
        height: 'thinGap',
      },
    },
  },
  defaultVariants: {
    isMobile: false,
  },
});
