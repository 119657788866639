import { evmAddressZod, type Evm } from '@pob/shared';
import { AvailableChainId, availableChainIdByRuntimeZod } from '@protocol/chains';
import { z } from 'zod';

export const attributeValueZod = z.number().or(z.string()).or(z.array(z.number()).or(z.array(z.string())));

export const attributeMetadataRawZod = z.object({
  display_type: z.string().nullable().optional(),
  max_value: z.number().or(z.string()).nullable().optional(),
  trait_type: z.string().nullable().optional(),
  value: attributeValueZod.nullable().optional(),
  name: z.string().nullable().optional(),
});

export type AttributeMetadataRaw = z.infer<typeof attributeMetadataRawZod>;

export const attributeMetadataZod = z.object({
  displayType: z.string().optional(),
  maxValue: z.number().or(z.string()).optional(),
  traitType: z.string().optional(),
  value: attributeValueZod.optional(),
  name: z.string().optional(),
});

export type AttributeMetadata = z.infer<typeof attributeMetadataZod>;

const attributeMapOrArrayZod = z.array(attributeMetadataRawZod).or(
  z.record(z.string(), attributeMetadataRawZod),
);

export const nftMetadataRawZod = z.object({
  name: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
  animation_url: z.string().nullable().optional(),
  external_url: z.string().nullable().optional(),
  attributes: attributeMapOrArrayZod.nullable().optional(),
  properties: attributeMapOrArrayZod.nullable().optional(),
  features: attributeMapOrArrayZod.nullable().optional(),
  traits: attributeMapOrArrayZod.nullable().optional(),
});

export type NftMetadataRaw = z.infer<typeof nftMetadataRawZod>;

export const nftMetadataZod = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  image: z.string().optional(),
  animationUrl: z.string().optional(),
  externalUrl: z.string().optional(),

  attributes: z.array(attributeMetadataZod).optional(),

  // tokenId: z.string(),
  // collectionAddress: z.string(),
  // chainId: evmAddressZod,
});

export type NftMetadata = z.infer<typeof nftMetadataZod>;

export const tokenMetadataZod = z.object({
  tokenId: z.string(),
  collectionAddress: evmAddressZod,
  chainId: availableChainIdByRuntimeZod.evm,
});

export type TokenMetadata = z.infer<typeof tokenMetadataZod>;

export const prettyNftMetadataZod = nftMetadataZod.extend({
  bestName: z.string(),
  bestImage: z.string().optional(),
  token: tokenMetadataZod.nullable(),
});

export type PrettyNftMetadata = z.infer<typeof prettyNftMetadataZod>;

// export interface ExternalCollectionMetadata {
//   bannerImageUrl: string;
//   externalUrl: string | null;
//   name: string;
//   symbol: string;
//   description: string;
//   collectionAddress: Evm.Address;
//   tokenType: 'erc1155' | 'erc721' | null;
// }
